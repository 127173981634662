import { Component, EventEmitter, OnDestroy } from "@angular/core";
import { JiraService } from "../services/jira.service";
import { take, takeUntil, tap } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";
import { IJiraIssue } from "../jira-issue.interface";
import * as moment from "moment";
import { TimerService } from "../services/timer.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "nx-sprint",
  templateUrl: "./sprint.component.html",
  styleUrls: ["./sprint.component.scss", "../../timer-style.scss"],
})
export class SprintComponent implements OnDestroy {
  private _destroyEmitter: EventEmitter<void> = new EventEmitter<void>();
  public sprintList$: BehaviorSubject<IJiraIssue[]> = new BehaviorSubject<IJiraIssue[]>([]);
  public environment = environment;

  constructor(private _jiraService: JiraService, private _timerService: TimerService) {
    this._jiraService.getSprintIssues().pipe(
      takeUntil(this._destroyEmitter),
      tap((issues) => this.sprintList$.next(issues)),
    ).subscribe();
  }

  public ngOnDestroy(): void {
    this._destroyEmitter.next();
  }

  public startTimerFromSprintList(issue: IJiraIssue): void {
    if (issue.fields.status.name === environment.jiraIssueStatusPlanned || issue.fields.status.name === environment.jiraIssueStatusOpen) {
      this._jiraService.transformStatus("Start Progress", issue)
        .pipe(
          take(1),
          tap(() => {
            this._timerService.addTimer(issue.key, moment.utc(), "Development");
          }),
        ).subscribe();
    } else {
      this._timerService.addTimer(issue.key, moment.utc(), "Development");
    }
  }
}
