import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, filter, mapTo, switchMap, tap } from "rxjs/operators";
import { environment } from "../../environments/environment";

export interface IJiraAccess {
  cloudId?: string;
  accessToken?: string;
  refreshToken?: string | null;
  expiresIn?: number;
  tokenDate?: Date;
}

export interface IToken {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  scope: string;
  token_type: string;
}

export interface IScope {
  avatarUrl: string;
  id: string;
  name: string;
  scopes: string[];
  url: string;
}

@Injectable({
  providedIn: "root",
})
export class OAuthService {
  public jiraAuthenticationData = {
    jira_authentication_uri: `${environment.jiraOAuthUrl}/authorize`,
    audience: "api.atlassian.com",
    client_id: environment.jiraOAuthClientId,
    client_secret: environment.jiraOAuthSecret,
    // don't delete 'offline_access' even though it is not present in dev console scopes - needed to refresh token in your initial authorization flow
    scope: "offline_access read:jira-user read:jira-work write:jira-work",
    redirect_uri: `${environment.jiraOAuthRedirectUrl}/authorizeJira`,
    response_type: "code",
    prompt: "consent",
  };
  public jiraAccessData$: BehaviorSubject<IJiraAccess> = new BehaviorSubject<IJiraAccess>(null);
  public jiraUrl$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private _httpClient: HttpClient,
    private _router: Router,
  ) {
    this.jiraAccessData$.pipe(
      filter((jiraAccess) => !!jiraAccess),
      tap((jiraAccess) => {
        localStorage.setItem("jiraAccess", JSON.stringify(jiraAccess));
        this.jiraUrl$.next(`${environment.jiraRESTAPIDomain}/${jiraAccess.cloudId}/rest/api/2`);
      }),
    ).subscribe();
  }

  public getAuthorizationCode(): void {
    window.location.href = `${this.jiraAuthenticationData.jira_authentication_uri}`
      + `?audience=${this.jiraAuthenticationData.audience}`
      + `&client_id=${this.jiraAuthenticationData.client_id}`
      + `&scope=${encodeURIComponent(this.jiraAuthenticationData.scope)}`
      + `&redirect_uri=${encodeURIComponent(this.jiraAuthenticationData.redirect_uri)}`
      + `&response_type=${this.jiraAuthenticationData.response_type}`
      + `&prompt=${this.jiraAuthenticationData.prompt}`;
  }

  public exchangeAccessCode(code: string): Observable<void> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });

    const body: string = JSON.stringify({
      grant_type: "authorization_code",
      client_id: this.jiraAuthenticationData.client_id,
      client_secret: this.jiraAuthenticationData.client_secret,
      code: code,
      redirect_uri: `${environment.jiraOAuthRedirectUrl}/authorizeJira`,
    });

    return this._httpClient.post<IToken>(`${environment.jiraOAuthUrl}/oauth/token`, body, { headers }).pipe(
      catchError((error) => {
        console.error("Get token failed: ", error);
        return throwError(error);
      }),
      switchMap((token: IToken): Observable<void> => this.getScope(token, true)),
    );
  }

  public getScope(accessToken: IToken, navigateToMainRoute: boolean = false): Observable<void> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${accessToken.access_token}`,
      Accept: "application/json",
    });

    return this._httpClient.get<IScope[]>(`${environment.jiraAPIDomain}/oauth/token/accessible-resources`, { headers }).pipe(
      tap((scopes: IScope[]) => {
        const newCloudId: string = scopes.find((scope) => scope.name === 'enthus').id;

        this.jiraAccessData$.next({
          accessToken: accessToken.access_token,
          refreshToken: accessToken.refresh_token || this.jiraAccessData$.value?.refreshToken,
          expiresIn: accessToken.expires_in,
          cloudId: newCloudId,
          tokenDate: new Date(),
        });

        this.jiraUrl$.next(`${environment.jiraRESTAPIDomain}/${newCloudId}/rest/api/2`);

        if (navigateToMainRoute) {
          this._router.navigate(["/today"]);
        }
      }),
      mapTo(void 0),
    );
  }

  public getJiraAccessHeader(): HttpHeaders {
    return new HttpHeaders({
      Authorization: `Bearer ${this.jiraAccessData$?.value?.accessToken}`,
      Accept: "application/json",
    });
  }

  public refreshToken(refreshToken?: string): Observable<void> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });

    const body: string = JSON.stringify({
      grant_type: "refresh_token",
      client_id: this.jiraAuthenticationData.client_id,
      client_secret: this.jiraAuthenticationData.client_secret,
      refresh_token: this.jiraAccessData$?.value?.refreshToken || refreshToken,
      redirect_uri: `${environment.jiraOAuthRedirectUrl}/authorizeJira`,
    });

    return this._httpClient.post<IToken>(`${environment.jiraOAuthUrl}/oauth/token`, body, { headers }).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status !== 403) {
          console.error("Failed to get refreshToken:", error);
        }
        return throwError(error);
      }),
      switchMap((token: IToken): Observable<void> => this.getScope(token)),
      mapTo(void 0),
    );
  }
}
