import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSliderModule } from "@angular/material/slider";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule, Routes } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { environment } from "../environments/environment";
import { AddDurationDialogComponent } from "./add-duration-dialog/add-duration-dialog.component";
import { AddStandardTypeDialogComponent } from "./add-standard-type-dialog/add-standard-type-dialog.component";

import { AppComponent } from "./app.component";
import { ConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";
import { CreateAlarmDialogComponent } from "./create-alarm-dialog/create-alarm-dialog.component";
import { CreateTimerDialogComponent } from "./create-timer-dialog/create-timer-dialog.component";
import { DialogComponent } from "./dialog/dialog.component";
import { DurationsListComponent } from "./durations-list/durations-list.component";
import { EditAlarmDialogComponent } from "./edit-alarm-dialog/edit-alarm-dialog.component";
import { EditTimerDurationsDialogComponent } from "./edit-timer-durations-dialog/edit-timer-durations-dialog.component";
import { EditTimerNameDialogComponent } from "./edit-timer-name-dialog/edit-timer-name-dialog.component";
import {
  EditTimerSingleDurationDialogComponent,
} from "./edit-timer-single-duration-dialog/edit-timer-single-duration-dialog.component";
import { EditableDurationsListComponent } from "./editable-durations-list/editable-durations-list.component";
import {
  ExtendableInformationPanelComponent,
} from "./extendable-information-panel/extendable-information-panel.component";
import {authenticationGuard} from "./guards/authentication.guard";
import {jiraAuthorizationGuard} from "./guards/jira-authorization.guard";
import { MessageComponent } from "./message/message.component";
import { NavigationComponent } from "./navigation/navigation.component";
import { PinnedTimersComponent } from "./pinned-timers/pinned-timers.component";
import { ReportsComponent } from "./reports/reports.component";
import { SearchComponent } from "./search/search.component";
import { OAuthService } from "./services/oauth.service";
import { SettingsComponent } from "./settings/settings.component";
import { StartTimerDialogComponent } from "./start-timer-dialog/start-timer-dialog.component";
import { TimerComponent } from "./timer/timer.component";
import { TimersListComponent } from "./timers-list/timers-list.component";
import { TodayComponent } from "./today/today.component";
import { MenuAndNavigationComponent } from "./menu-and-navigation/menu-and-navigation.component";
import { CriticalTimeComponent } from "./critical-time/critical-time.component";
import { SprintTimeComponent } from "./sprint-time/sprint-time.component";
import { MatBadgeModule } from "@angular/material/badge";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { SprintComponent } from "./sprint/sprint.component";
import { TaskComponent } from "./task/task.component";

const routes: Routes = [
  {
    path: "",
    canActivate: [authenticationGuard()],
    component: MenuAndNavigationComponent,
    children: [
      { path: "", redirectTo: "today", pathMatch: "full" },
      { path: "today", component: TodayComponent },
      { path: "sprint", component: SprintComponent },
      { path: "reports", component: ReportsComponent },
      { path: "settings", component: SettingsComponent },
      { path: "search", component: SearchComponent },
      { path: "criticaltime", component: CriticalTimeComponent },
      { path: "sprinttime", component: SprintTimeComponent },
      { path: "search/:query", component: SearchComponent },
    ],
  },
  {
    path: "authorizeJira",
    canActivate: [jiraAuthorizationGuard()],
    children: [],
  },
];

@Injectable()
class NoopInterceptor implements HttpInterceptor {
  constructor(private _oAuthService: OAuthService) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this._oAuthService.jiraUrl$.value && request.url.includes(this._oAuthService.jiraUrl$.value)) {
      request = request.clone({ headers: this._oAuthService.getJiraAccessHeader() });
    }

    return next.handle(request).pipe(
      catchError((error) => {
        if (error.status === 401 && request.url.includes(environment.jiraAPIDomain)) {
          console.info("401 in jira request, refreshing token");

          return this._oAuthService.refreshToken().pipe(
            switchMap(() => {
              return next.handle(request.clone({
                headers: this._oAuthService.getJiraAccessHeader(),
              }));
            }),
          );
        } else if (error.status === 403 && request.url.endsWith(`/oauth/token`)) {
          console.info("403 in jira request, reauthorize user");

          this._oAuthService.getAuthorizationCode();
        } else {
          return throwError(error);
        }
      }),
    );
  }
}

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    TimerComponent,
    TodayComponent,
    TimersListComponent,
    ReportsComponent,
    ConfirmDialogComponent,
    DurationsListComponent,
    CreateTimerDialogComponent,
    SettingsComponent,
    EditTimerDurationsDialogComponent,
    EditableDurationsListComponent,
    CreateAlarmDialogComponent,
    MessageComponent,
    DialogComponent,
    EditTimerNameDialogComponent,
    EditAlarmDialogComponent,
    SearchComponent,
    StartTimerDialogComponent,
    EditTimerSingleDurationDialogComponent,
    AddDurationDialogComponent,
    PinnedTimersComponent,
    AddStandardTypeDialogComponent,
    ExtendableInformationPanelComponent,
    MenuAndNavigationComponent,
    CriticalTimeComponent,
    SprintTimeComponent,
    SprintComponent,
    TaskComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes),
    MatListModule,
    MatMenuModule,
    MatDialogModule,
    MatExpansionModule,
    MatTableModule,
    MatAutocompleteModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatSliderModule,
    MatCheckboxModule,
    MatTabsModule,
    MatTooltipModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatRadioModule,
    HttpClientModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    MatPaginatorModule,
    MatBadgeModule,
    MatProgressBarModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: "de-DE" },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NoopInterceptor,
      multi: true,
    },
  ],
})
export class AppModule {
}
