import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import * as moment from "moment";
import { Moment } from "moment";
import { CustomValidators } from "../custom-validators";
import { IDurationData } from "../duration-data.interface";
import { EditDurationsService } from "../services/edit-durations.service";
import { PRISTINE_ERROR_STATE_MATCHER } from "../immediate-error-state-matcher";
import { TimerService } from "../services/timer.service";
import { ITimer } from "../timer/timer.interface";
import { EditableDurationsListService } from "./editable-durations-list.service";
import { IEditableTimerTable } from "./editable-timer-table";
import { takeUntil, tap } from "rxjs/operators";

export enum Format {
  TIME = "HH:mm",
  DATETIME = "DD.MM.YYYY HH:mm",
}

export interface IEditableDurationsListChange {
  endTime: string;
  startTime: string;
  worklogId: string;
}

interface IDurationsForm {
  startTime: FormControl<string>;
  endTime: FormControl<string | null>;
  worklogId: FormControl<string>;
  activityType: FormControl<string>;
}

@Component({
  selector: "nx-editable-durations-list",
  templateUrl: "./editable-durations-list.component.html",
  styleUrls: ["./editable-durations-list.component.scss"],
})
export class EditableDurationsListComponent implements OnInit, OnDestroy {
  @Input() public dialogData;
  public displayedColumns: string[] = ["position", "startTime", "endTime"];
  public durationsForm: FormGroup;
  public durationsFormErrorStateMatcher = PRISTINE_ERROR_STATE_MATCHER;
  @Output() public durationsFormValid: EventEmitter<boolean>;
  @Input() public highlightPosition: number;
  public maxDate: Moment;
  public timer: ITimer;
  public timerEditData: IEditableTimerTable[] = [];
  private _destroyEmitter = new EventEmitter<void>();

  constructor(
    public timerService: TimerService,
    public editDurationsService: EditDurationsService,
    public editableDurationsListService: EditableDurationsListService,
  ) {
    this.durationsFormValid = new EventEmitter();

    this.maxDate = moment.utc();
  }

  public durationsFormValidation(format: string): ValidatorFn {
    return (control: FormGroup): null | ValidationErrors => {
      let validationError = null;

      for (const index of Object.keys(control.controls)) {
        const endTimeControl = (control.controls[index] as FormGroup).controls["endTime"];
        const startTimeControl = (control.controls[index] as FormGroup).controls["startTime"];
        const previousEndTimeControl = parseInt(index, 10) - 1 >= 0 ? (control.controls[parseInt(
          index,
          10,
        ) - 1] as FormGroup).controls["endTime"] : null;

        const endTimestamp = moment(endTimeControl.value, format).unix();
        const startTimestamp = moment(startTimeControl.value, format).unix();
        const previousEndTimestamp = parseInt(index, 10) - 1 >= 0 ? moment(previousEndTimeControl.value, format).unix() : null;

        if (
          endTimeControl.value
          && endTimestamp <= startTimestamp
        ) {
          validationError = {
            "startTimeGreaterThanEndTime": true,
          };

          endTimeControl.setErrors({ ...endTimeControl.errors, "startTimeGreaterThanEndTime": true });
          startTimeControl.setErrors({ ...startTimeControl.errors, "startTimeGreaterThanEndTime": true });
        } else {
          if (endTimeControl.hasError("startTimeGreaterThanEndTime")) {
            delete endTimeControl.errors["startTimeGreaterThanEndTime"];

            if (Object.keys(endTimeControl.errors).length <= 0) {
              endTimeControl.setErrors(null);
            }
          }

          if (startTimeControl.hasError("startTimeGreaterThanEndTime")) {
            delete startTimeControl.errors["startTimeGreaterThanEndTime"];

            if (Object.keys(startTimeControl.errors).length <= 0) {
              startTimeControl.setErrors(null);
            }
          }
        }

        if (
          parseInt(index, 10) - 1 >= 0
          && previousEndTimestamp
          && startTimestamp < previousEndTimestamp
        ) {
          validationError = {
            "previousEndTimeGreaterThanStartTime": true,
          };

          previousEndTimeControl.setErrors({
            ...previousEndTimeControl.errors,
            "previousEndTimeGreaterThanStartTime": true,
          });
        } else {
          if (
            previousEndTimeControl
            && previousEndTimeControl.hasError("previousEndTimeGreaterThanStartTime")
          ) {
            delete previousEndTimeControl.errors["previousEndTimeGreaterThanStartTime"];

            if (Object.keys(previousEndTimeControl.errors).length <= 0) {
              previousEndTimeControl.setErrors(null);
            }
          }
        }

        if (
          moment(startTimeControl.value, format).startOf("day").valueOf()
          !== moment(endTimeControl.value, format).startOf("day").valueOf()
        ) {
          validationError = { "dateOutOfRange": true };
        }

        if (
          moment(startTimeControl.value, format).valueOf() > moment().valueOf()
          || moment(endTimeControl.value, format).valueOf() > moment().valueOf()
        ) {
          validationError = { "dateIsInFuture": true };
        }
      }

      return validationError;
    };
  }

  ngOnInit() {
    this.timer = this.dialogData["timer"];
    this.editDurationsService.timer = this.timer;

    let timerDurationData: IDurationData[];
    if (this.editableDurationsListService.dateFrom) {
      timerDurationData = this.timerService.getTimerDurationDataByDateFromTo(this.timer, this.editableDurationsListService.dateFrom);
    } else {
      timerDurationData = this.timerService.getAllTimerDurationData(this.timer);
    }

    for (const [index, durationData] of timerDurationData.entries()) {
      this.timerEditData.unshift(
        {
          index: index,
          position: index + 1,
          startTime: moment(durationData.startTime),
          endTime: moment(durationData.startTime).add(durationData.duration),
          worklogId: durationData.worklogId ? durationData.worklogId : undefined,
          activityType: durationData.activityType ? durationData.activityType : undefined,
        },
      );
    }

    this.durationsForm = new FormGroup({});

    this.timerEditData.forEach((timeRow) => {
      this.durationsForm
        .addControl(
          [timeRow.index].toString(),
          new FormGroup<IDurationsForm>({
            startTime: new FormControl(
              moment(timeRow.startTime).format(this.editableDurationsListService.format),
              this.editableDurationsListService.format === "HH:mm"
                ? CustomValidators.durationsFormControlTimeValidator
                : CustomValidators.durationsFormControlDateTimeValidator,
            ),
            endTime: new FormControl(
              timeRow.endTime ? moment(timeRow.endTime).format(this.editableDurationsListService.format) : null,
              this.editableDurationsListService.format === "HH:mm"
                ? CustomValidators.durationsFormControlTimeValidator
                : CustomValidators.durationsFormControlDateTimeValidator,
            ),
            worklogId: new FormControl(timeRow.worklogId),
            activityType: new FormControl(timeRow.activityType),
          }),
        );
    });

    this.durationsForm.setValidators(this.durationsFormValidation(this.editableDurationsListService.format));
    this.durationsForm.updateValueAndValidity();

    this.durationsForm.valueChanges
      .pipe(
        takeUntil(this._destroyEmitter),
        tap((changes: IEditableDurationsListChange[]) => {
          this.durationsFormValid.emit(this.durationsForm.valid);
          this.editDurationsService.prepareDurationsChanges(changes, this.editableDurationsListService.format);
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroyEmitter.next();
  }
}
