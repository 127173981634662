<mat-toolbar color="primary">
  <mat-toolbar-row>
    <span class="title">Timer App</span>
    <span class="spacer"></span>
    <div class="description" *ngIf="timerService.activeTimer.value">
      <mat-icon class="watch-icon">timer</mat-icon>
      <div class="timer-name" [matTooltip]="timerService.activeTimer.value?.name">
        {{ timerService.activeTimer.value?.name }}
      </div>
      {{ timerService.activeTimerDuration | async }}
      <div *ngIf="timerService.activeTimer.value.alarm">
        <mat-icon>alarm</mat-icon>
        {{ timerService.activeTimerAlarmDuration | async }}
      </div>
      <button mat-mini-fab color="accent" (click)="pauseTimer()">
        <mat-icon>pause</mat-icon>
      </button>
    </div>
    <button
      mat-icon-button
      color="accent"
      (click)="toggleExtendableInformationPanel()"
      matTooltip="Information Panel anzeigen"
    >
      <mat-icon>info</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<mat-sidenav-container class="example-container">
  <mat-sidenav mode="side" opened [disableClose]="true">
    <mat-nav-list class="sidebar">
      <mat-list-item
        *ngIf="settingsService.settings$.value['navigation']['showChips']"
        [disableRipple]="true"
        class="chip-item"
      >
        <mat-chip-listbox>
          <mat-chip-option selected color="accent" [disableRipple]="true">
            Heute geloggt: {{ timerService.totalTodayDurations | async }}
          </mat-chip-option>
        </mat-chip-listbox>
      </mat-list-item>
      <mat-list-item
        *ngIf="timerService.beginningOfDay && settingsService.settings$.value['navigation']['showChips']"
        [disableRipple]="true"
        class="chip-item"
      >
        <mat-chip-listbox>
          <mat-chip-option selected color="accent" [disableRipple]="true">
            Anfang des Tages: {{ timerService.beginningOfDay }}
          </mat-chip-option>
        </mat-chip-listbox>
      </mat-list-item>
      <mat-list-item [routerLink]="['/today']">
        <mat-icon matListItemIcon>timer</mat-icon>
        <p matListItemTitle>Heute</p>
      </mat-list-item>
      <mat-list-item [routerLink]="['/sprint']">
        <mat-icon matListItemIcon>directions_run</mat-icon>
        <p matListItemTitle>Sprint</p>
      </mat-list-item>
      <mat-list-item [routerLink]="['/search']">
        <mat-icon matListItemIcon>search</mat-icon>
        <p matListItemTitle>Suchen</p>
      </mat-list-item>
      <mat-list-item [routerLink]="['/reports']">
        <mat-icon matListItemIcon>book</mat-icon>
        <p matListItemTitle>Berichte</p>
      </mat-list-item>
      <mat-list-item [routerLink]="['/criticaltime']">
        <mat-icon matListItemIcon>timelapse</mat-icon>
        <div class="badge" matBadge="{{ (jiraService.issues$ | async).length }}" matBadgeOverlap="false">Zeitkritisch
        </div>
      </mat-list-item>
      <mat-list-item [routerLink]="['/sprinttime']">
        <mat-icon matListItemIcon>timeline</mat-icon>
        <p matListItemTitle>Sprintzeit</p>
      </mat-list-item>
      <mat-list-item [routerLink]="['/settings']">
        <mat-icon matListItemIcon>settings</mat-icon>
        <p matListItemTitle>Einstellungen</p>
      </mat-list-item>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
    <nx-extendable-information-panel
      [@openCollapse]="openExtendableInformationPanel ? 'opened' : 'collapsed'"></nx-extendable-information-panel>
  </mat-sidenav-content>
</mat-sidenav-container>
