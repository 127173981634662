import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, EMPTY, Observable, throwError } from "rxjs";
import { OAuthService } from "./oauth.service";
import { catchError, filter, map, switchMap, take, tap } from "rxjs/operators";
import { ConvertTimeService } from "./convert-time.service";
import { environment } from "../../environments/environment";
import { IJiraIssue, ISprint, ITransition } from "../jira-issue.interface";
import { IJiraResponse } from "../jira-response.interface";

enum JiraField {
  AggregateTimeEstimate = "aggregatetimeestimate",
  AggregateTimeOriginalEstimate = "aggregatetimeoriginalestimate",
  AggregateTimeSpent = "aggregatetimespent",
  Assignee = "assignee",
  Created = "created",
  Sprint = "customfield_10020",
  IssueType = "issuetype",
  Labels = "labels",
  Parent = "parent",
  ResolutionDate = "resolutiondate",
  RefinementTime = "refinementTime",
  Status = "status",
  SubTask = "subtasks",
  Summary = "summary",
  TimeOriginalEstimateString = "timeOriginalEstimateString",
  TimeSpent = "timespent",
  TimeLeftString = "timeLeftString",
  Worklog = "worklog",
  WorkRatio = "workratio",
}

interface IBody {
  comment: string;
  started: string;
  timeSpentSeconds: number;
}

interface IWorklogs {
  worklogs: IWorklog[];
}

interface IWorklog {
  id: string;
  timeSpentSeconds: number;
  comment: string;
}

interface IJiraSearchResult {
  expand: string;
  issues: IJiraIssue[];
  maxResults: number;
  startAt: number;
  total: number;
}

@Injectable({
  providedIn: "root",
})
export class JiraService {
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public jiraDomain: string = environment.jiraDomain;
  public issues$: BehaviorSubject<IJiraIssue[]> = new BehaviorSubject<IJiraIssue[]>([]);
  public headers: HttpHeaders = new HttpHeaders({ "Content-type": "application/json" });
  public requestOptions = {
    headers: this.headers,
    withCredentials: true,
  };
  // type Object because Angular has no type
  public requestOptionsWithObserve: {} = {
    headers: this.headers,
    withCredentials: true,
    observe: "response",
  };
  public activeSprint: ISprint;

  constructor(
    public httpClient: HttpClient,
    private _oAuthService: OAuthService,
    private _convertTimeService: ConvertTimeService,
  ) {
    this.getIssues();
  }

  public addIssueWorklog(issueKey: string, body: Object): Observable<Object> {
    return this.httpClient.post(
      `${this._oAuthService.jiraUrl$.value}/issue/${issueKey}/worklog`,
      body,
      this.requestOptionsWithObserve,
    );
  }

  public deleteIssueWorklog(issueKey: string, worklogId: string): Observable<Object> {
    return this.getIssueWorklog(issueKey, worklogId).pipe(
      map((worklog: IWorklog) => worklog.timeSpentSeconds),
      switchMap((timeSpent) => {
        return this.checkRemainingEstimate(issueKey, timeSpent, worklogId, "delete").pipe(
          switchMap((adjustEstimate) => {
            return this.httpClient.delete(
              `${this._oAuthService.jiraUrl$.value}/issue/${issueKey}/worklog/${worklogId}?adjustEstimate=${adjustEstimate}`,
              this.requestOptionsWithObserve,
            );
          }),
        );
      }),
    );
  }

  public getIssue(issueKey: string): Observable<IJiraIssue> {
    return this.httpClient.get<IJiraIssue>(
      `${this._oAuthService.jiraUrl$.value}/issue/${issueKey}`,
      this.requestOptions,
    );
  }

  public getIssueDescription(issueKey: string): Observable<IJiraIssue> {
    return this.httpClient.get<IJiraIssue>(
      `${this._oAuthService.jiraUrl$.value}/issue/${issueKey}?fields=description`,
      this.requestOptions,
    );
  }

  public getIssueWorklogs(issueKey: string): Observable<IWorklogs> {
    return this.httpClient.get<IWorklogs>(
      `${this._oAuthService.jiraUrl$.value}/issue/${issueKey}/worklog`,
      this.requestOptions,
    );
  }

  public getJiraUser(): Observable<Object> {
    return this.httpClient.get(
      `${this._oAuthService.jiraUrl$.value}/myself`,
      { withCredentials: true },
    );
  }

  public updateIssueWorklog(issueKey: string, worklogId: string, body: IBody): Observable<IJiraResponse> {
    return this.checkRemainingEstimate(issueKey, body.timeSpentSeconds, worklogId, "update").pipe(
      switchMap((adjustEstimate) => {
        return this.httpClient.put<IJiraResponse>(
          `${this._oAuthService.jiraUrl$.value}/issue/${issueKey}/worklog/${worklogId}?adjustEstimate=${adjustEstimate}`,
          body,
          this.requestOptionsWithObserve,
        );
      }),
    );
  }

  public getIssueWorklog(issueKey: string, worklogId: string): Observable<IWorklog> {
    return this.httpClient.get<IWorklog>(
      `${this._oAuthService.jiraUrl$.value}/issue/${issueKey}/worklog/${worklogId}`,
      this.requestOptions,
    );
  }

  public updateIssueDescription(issueKey: string, description: string): Observable<IJiraIssue> {
    return this.httpClient.put<IJiraIssue>(
      `${this._oAuthService.jiraUrl$.value}/issue/${issueKey}`,
      { "fields": { "description": description } },
      this.requestOptionsWithObserve,
    );
  }

  public getIssues(): void {
    this.searchIssuesByJQL(
      environment.jiraIssueFilter,
      100,
      [
        JiraField.AggregateTimeEstimate,
        JiraField.AggregateTimeOriginalEstimate,
        JiraField.AggregateTimeSpent,
        JiraField.Assignee,
        JiraField.Created,
        JiraField.IssueType,
        JiraField.Labels,
        JiraField.Parent,
        JiraField.ResolutionDate,
        JiraField.Sprint,
        JiraField.Status,
        JiraField.Summary,
        JiraField.TimeOriginalEstimateString,
        JiraField.TimeSpent,
        JiraField.TimeLeftString,
        JiraField.WorkRatio,
        JiraField.RefinementTime,
        JiraField.Worklog,
      ],
      0,
    ).pipe(
      take(1),
      tap((issues) => {
        issues.sort((a: IJiraIssue, b: IJiraIssue) => {
          if (a.fields.workratio < b.fields.workratio) {
            return 1;
          }

          if (a.fields.workratio > b.fields.workratio) {
            return -1;
          }

          return 0;
        })
          .forEach((issue) => {
            issue.fields.refinementTime = 0;

            issue.fields.worklog.worklogs.forEach((worklog) => {
              if (worklog.comment?.includes("Refinement")) {
                issue.fields.refinementTime += worklog.timeSpentSeconds;
              }
            });

            issue.fields.timeLeftString = this._convertTimeService.calculateTimeLeft(issue.fields.aggregatetimeoriginalestimate, issue.fields.aggregatetimeestimate, issue.fields.timespent - issue.fields.refinementTime);
            issue.fields.timeOriginalEstimateString = this._convertTimeService.convertSecondsToString(issue.fields.aggregatetimeoriginalestimate, false);
          });

        this.replaceBugIcon(issues);
        this.issues$.next(issues);
      }),
    ).subscribe();
  }

  public searchIssuesByJQL(jql: string, maxResults: number, fields: string[], startAt: number): Observable<IJiraIssue[]> {
    return this.httpClient
      .get<IJiraSearchResult>(`${this._oAuthService.jiraUrl$.value}/search?jql=${jql}&maxResults=${maxResults}&fields=${fields.join()}&startAt=${startAt}`)
      .pipe(
        catchError((error) => {
          console.error("Failed to get issues: ", error);

          return throwError(error);
        }),
        map((result: IJiraSearchResult) => result.issues as IJiraIssue[]),
      );
  }

  public getActiveSprint(): Observable<IJiraSearchResult> {
    return this.httpClient
      .get<IJiraSearchResult>(`${this._oAuthService.jiraUrl$.value}/search?jql=${environment.jiraActiveSprint}&maxResults=100`)
      .pipe(
        catchError((error) => {
          console.error("Failed to get sprint: ", error);

          return throwError(error);
        }),
        filter((sprint: IJiraSearchResult) => sprint.issues.length > 0 && !!sprint.issues[0].fields.customfield_10020),
        tap((sprint: IJiraSearchResult) => this.activeSprint = sprint.issues[0].fields.customfield_10020[0]),
      );
  }

  public checkRemainingEstimate(issueKey: string, timeSpent: number, worklogId: string, operation: string): Observable<string> {
    let loggedTime: number = 0;
    let timeEstimate: number = 0;
    let oldTime: number = 0;

    return this.getIssue(issueKey).pipe(
      tap((issue: IJiraIssue) => timeEstimate = issue.fields.aggregatetimeoriginalestimate),
      switchMap(() => {
        return this.getIssueWorklogs(issueKey).pipe(
          tap((worklogsResult: IWorklogs) => worklogsResult.worklogs.forEach((worklog: IWorklog) => {
            loggedTime += worklog.timeSpentSeconds;

            if (worklog.id === worklogId) {
              oldTime = worklog.timeSpentSeconds;
            }
          })),
        );
      }),
      map(() => {
        if (timeSpent > oldTime || (oldTime === timeSpent && operation === "update")) {
          return "auto";
        } else if (operation === "delete" && (timeEstimate - loggedTime + timeSpent) <= 0) {
          return "leave";
        } else if (operation === "delete" && (timeEstimate - loggedTime + timeSpent) > 0) {
          return `new&newEstimate=${(timeEstimate - loggedTime + timeSpent) / 60}m`;
        } else if ((timeEstimate - loggedTime + oldTime - timeSpent) <= 0) {
          return "leave";
        } else if ((timeEstimate - loggedTime + oldTime - timeSpent) > 0) {
          return `new&newEstimate=${(timeEstimate - loggedTime + oldTime - timeSpent) / 60}m`;
        }
      }),
    );
  }

  public transformStatus(transitionToPerform: string, issue: IJiraIssue): Observable<void> {
    return this.httpClient.get<ITransition>(`${this._oAuthService.jiraUrl$.value}/issue/${issue.key}/transitions`).pipe(
      map((status) => {
        if (status) {
          return status.transitions.find((transition) => transition.name === transitionToPerform);
        }
      }),
      switchMap((status) => {
        if (status) {
          return this.httpClient.post<void>(`${this._oAuthService.jiraUrl$.value}/issue/${issue.key}/transitions`, {
            transition: { id: status.id },
          });
        } else {
          return EMPTY;
        }
      }),
    );
  }

  public getSprintIssues(): Observable<IJiraIssue[]> {
    return this.searchIssuesByJQL(
      environment.jiraActiveSprint,
      100,
      [JiraField.Summary, JiraField.IssueType, JiraField.Assignee, JiraField.Status, JiraField.Parent, JiraField.SubTask],
      0,
    ).pipe(
      tap((issues) => this.replaceBugIcon(issues)),
      map((issues) => issues),
    );
  }

  public replaceBugIcon(issues: IJiraIssue[]): IJiraIssue[] {
    issues.forEach((issue) => {
      if (issue.fields.issuetype.name === "Bug") {
        issue.fields.issuetype.iconUrl = issue.fields.issuetype.iconUrl.replace("https://api.atlassian.com/ex/jira/a663f68e-6074-43bf-9344-146fd917b65f", "https://enthus.atlassian.net/");
      }
    });

    return issues;
  }
}
