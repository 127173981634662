import { Injectable } from "@angular/core";
import { Moment } from "moment";

@Injectable({
  providedIn: "root",
})
export class EditableDurationsListService {
  public dateFrom: Moment;
  public dateTo: Moment;
  public format: string;

  constructor() {
  }
}
