import { Component, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import * as moment from "moment";
import { Duration, Moment } from "moment";
import { map } from "rxjs/operators";
import { CreateTimerDialogComponent } from "../create-timer-dialog/create-timer-dialog.component";
import { CustomValidators } from "../custom-validators";
import { DateAndTimeUtilities } from "../date-and-time-utilities";
import { Format } from "../editable-durations-list/editable-durations-list.component";
import { EditableDurationsListService } from "../editable-durations-list/editable-durations-list.service";
import { IMMEDIATE_ERROR_STATE_MATCHER } from "../immediate-error-state-matcher";
import { SettingsService } from "../services/settings.service";
import { TimerService } from "../services/timer.service";

interface IAddTimerFrom {
  name: FormControl<string | null>;
  minusTime: FormControl<string>;
  startTime: FormControl<string>;
  activityType: FormControl<string>;
}

@Component({
  selector: "nx-timers",
  templateUrl: "./today.component.html",
  styleUrls: ["./today.component.scss"],
})
export class TodayComponent implements OnInit {
  public addTimerForm: FormGroup<IAddTimerFrom>;
  public immediateMatcher = IMMEDIATE_ERROR_STATE_MATCHER;

  constructor(
    public timerService: TimerService,
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    public editableDurationsListService: EditableDurationsListService,
    private _snackBar: MatSnackBar,
    public settingsService: SettingsService,
  ) {
    this.addTimerForm = this._formBuilder.group({
      "name": [null],
      "minusTime": ["", [CustomValidators.validateDuration, this.validateDurationMinusTime]],
      "startTime": ["", [Validators.required, CustomValidators.validateTime]],
      "activityType": this.timerService.activityTypes[0],
    });
    this.addTimerForm.controls.startTime.setValidators(this.validateStartTime);
    this.addTimerForm.updateValueAndValidity();

    this.timerService.filteredTimersForAutocomplete$ = this.addTimerForm.controls.name.valueChanges
      .pipe(
        map((typedName) => this.timerService.filterTimers(typedName)),
      );
  }

  public createTimer(name: HTMLInputElement) {
    name.value = name.value.replace(/http.+\/.*\W(\w*-\d+).*/, "$1").trim();

    const existingTimer = TimerService.getAllTimers().find((timer) => timer.name === name.value);
    let duration: Duration;
    const minusTime = this.addTimerForm.controls.minusTime.value;
    const startTime = this.addTimerForm.controls.startTime.value;

    if (this.settingsService.settings$.value["todayTimers"]["timeInput"] === "minusTime") {
      duration = DateAndTimeUtilities.getDuration(minusTime);
    } else {
      duration = moment.duration(moment().diff(moment(startTime, "HH:mm")));
    }

    if (
      existingTimer
      && this.timerService.isActiveTimer(existingTimer)
    ) {
      this._snackBar.open(
        `Timer ${name.value} ist schon aktiv!`,
        "Okay",
        {
          duration: 2000,
        },
      );
    } else {
      const date: Moment = moment().subtract(duration);

      this.timerService.addTimer(name.value, date, this.addTimerForm.controls.activityType.value);
    }

    this.addTimerForm.controls.name.reset(null);
    this.addTimerForm.controls.activityType.setValue(this.timerService.activityTypes[0]);
    this.clearTimeInputs();
  }

  ngOnInit() {
    this.timerService.initializeTimers();
    this.editableDurationsListService.dateFrom = moment();
    this.editableDurationsListService.format = Format.TIME;
  }

  public openCreateDialog() {
    const dialogRef = this.dialog.open(CreateTimerDialogComponent, {
      data: {
        title: "Fügen Sie einen Timer hinzu",
      },
    });
  }

  public setActivityType(event: MatAutocompleteSelectedEvent) {
    const timerWithStandardType = this.settingsService.getTimerWithStandardType(event.option.value);

    if (timerWithStandardType) {
      this.addTimerForm.controls.activityType.setValue(timerWithStandardType.type);
    }
  }

  public shortCutOpenCreateDialog(event) {
    if (event.ctrlKey && event.key === "y") {
      this.openCreateDialog();
    }
  }

  public validateDurationMinusTime(control: AbstractControl): null | ValidationErrors {
    const duration: Duration = DateAndTimeUtilities.getDuration(control.value);
    const todayDate: Moment = moment().subtract(duration);
    const yesterdayDate: Moment = moment().subtract(1, "day").endOf("day");

    if (todayDate.valueOf() <= yesterdayDate.valueOf()) {
      return { "durationInPast": true };
    }

    return null;
  }

  public validateStartTime(control: AbstractControl): null | ValidationErrors {
    let validationError = null;
    const momentFrom = moment(control.value, "HH:mm");

    if (momentFrom.valueOf() > moment().valueOf()) {
      control.setErrors({ ...control.errors, "dateInFuture": true });

      return validationError = { "dateInFuture": true };
    } else {
      if (
        control
        && control.errors
        && control.errors["dateInFuture"]
      ) {
        delete control.errors["dateInFuture"];
        control.updateValueAndValidity();
      }
    }

    return validationError;
  }

  public clearTimeInputs(): void {
    this.addTimerForm.controls.minusTime.setValue(null);
    this.addTimerForm.controls.startTime.setValue(null);
    this.addTimerForm.updateValueAndValidity();
  }
}
