<div class="date-buttons">
  <button mat-raised-button color="primary" (click)="search('year')">Jahr</button>
  <button mat-raised-button color="primary" (click)="search('month')">Monat</button>
  <button mat-raised-button color="primary" (click)="search('week')">Woche</button>
  <button mat-raised-button color="primary" (click)="search('day')">Tag</button>
</div>
<div>
  Von:
  <mat-form-field class="datepickerFrom">
    <input
      matInput
      [value]="datePickerValueFrom"
      [matDatepicker]="datepickerFrom"
      placeholder="Datum auswählen"
      (dateChange)="setDateFrom($event.value)"
    >
    <mat-datepicker-toggle matSuffix [for]="datepickerFrom"></mat-datepicker-toggle>
    <mat-datepicker #datepickerFrom></mat-datepicker>
  </mat-form-field>

  Bis:
  <mat-form-field class="datepickerTo">
    <input
      matInput
      [value]="datePickerValueTo"
      [matDatepicker]="datepickerTo"
      placeholder="Datum auswählen"
      (dateChange)="setDateTo($event.value)"
    >
    <mat-datepicker-toggle matSuffix [for]="datepickerTo"></mat-datepicker-toggle>
    <mat-datepicker #datepickerTo></mat-datepicker>
  </mat-form-field>

  <button mat-raised-button color="primary" (click)="search()">Suchen</button>
</div>
<div class="timers-list-container">
  <nx-timers-list [readonly]="true" [timers]="timers"></nx-timers-list>
</div>
