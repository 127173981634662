import {inject} from "@angular/core";
import { CanActivateFn, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { IJiraAccess, OAuthService } from "../services/oauth.service";

export function authenticationGuard(): CanActivateFn {
  return (route, state: RouterStateSnapshot): Observable<boolean | UrlTree> => {
    const oAuthService = inject(OAuthService);
    const jiraAccess: IJiraAccess = JSON.parse(localStorage.getItem("jiraAccess")) as IJiraAccess;

    return of(jiraAccess)
      .pipe(
        switchMap(() => {
          if (jiraAccess && jiraAccess.expiresIn && jiraAccess.tokenDate) {
            jiraAccess.tokenDate = new Date(jiraAccess.tokenDate);
            const expirationDate = new Date(jiraAccess.tokenDate);

            expirationDate.setSeconds(expirationDate.getSeconds() + jiraAccess.expiresIn);

            const isExpired = expirationDate <= new Date();

            if (isExpired) {
              let errorOccurred;

              return oAuthService.refreshToken(jiraAccess.refreshToken).pipe(
                catchError((error) => {
                  errorOccurred = error;

                  return of(void 0);
                }),
                map(() => !errorOccurred),
              );
            } else {
              oAuthService.jiraAccessData$.next(jiraAccess);

              return of(true);
            }
          } else {
            oAuthService.getAuthorizationCode();
            return of(false);
          }
        }),
      );
  }
}
