<mat-tab-group color="primary" (selectedIndexChange)="changeTabIndex($event)">
  <mat-tab label="Nach Namen suchen">
    <form class="name-form" [formGroup]="nameForm">
      <mat-form-field class="name-input">
        <input matInput formControlName="name" placeholder="Name eingeben" type="text" #name autocomplete="off"
          maxlength="100">
        <mat-hint align="end">{{name.value.length}} / 100</mat-hint>
      </mat-form-field>
      <div class="play-button">
        <button [disabled]="!name.value" mat-fab color="primary" (click)="searchNavigation(name.value)">
          <mat-icon>search</mat-icon>
        </button>
      </div>
    </form>
    <nx-timers-list [timers]="timersByName"></nx-timers-list>
  </mat-tab>

  <mat-tab label="Nach Datum suchen">
    <div class="search-outer-container">
      <mat-form-field class="date-input">
        <input
          matInput
          [matDatepicker]="picker"
          placeholder="Datum auswählen"
          (dateChange)="datepickerChange($event.value)"
          [max]="maxDate"
        >
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker color="primary" #picker></mat-datepicker>
      </mat-form-field>
      <div class="previous-timers-outer-container">
        <div class="previous-timers-inner-container">
          <nx-message *ngIf="previousTimers.length === 0" [type]="'error'">Keine Timer für dieses Datum vorhanden
          </nx-message>
          <nx-timers-list class="previous-timers" [timers]="previousTimers"></nx-timers-list>
        </div>
      </div>
      <button
        [disabled]="!datepickerValue"
        class="add-timer-button"
        mat-fab
        color="primary"
        (click)="openAddDurationDialog()"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </mat-tab>
</mat-tab-group>
