import { Component, EventEmitter, Input, OnChanges, OnDestroy, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatMenuTrigger } from "@angular/material/menu";
import { MatSnackBar } from "@angular/material/snack-bar";
import * as moment from "moment";
import { EMPTY, Observable, of, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { AddDurationDialogComponent } from "../add-duration-dialog/add-duration-dialog.component";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { CreateAlarmDialogComponent } from "../create-alarm-dialog/create-alarm-dialog.component";
import { IDurationData } from "../duration-data.interface";
import { EditAlarmDialogComponent } from "../edit-alarm-dialog/edit-alarm-dialog.component";
import {
  EditTimerDurationsDialogComponent,
} from "../edit-timer-durations-dialog/edit-timer-durations-dialog.component";
import { EditTimerNameDialogComponent } from "../edit-timer-name-dialog/edit-timer-name-dialog.component";
import { Format } from "../editable-durations-list/editable-durations-list.component";
import { EditableDurationsListService } from "../editable-durations-list/editable-durations-list.service";
import { SettingsService } from "../services/settings.service";
import { TimerService } from "../services/timer.service";
import { ITimer } from "./timer.interface";
import { catchError, filter, finalize, switchMap, take, takeUntil, tap } from "rxjs/operators";

@Component({
  selector: "nx-timer",
  templateUrl: "./timer.component.html",
  styleUrls: ["./timer.component.scss", "../../timer-style.scss"],
})
export class TimerComponent implements OnChanges, OnDestroy {
  public alarmDuration$: Observable<string>;
  @Input() public autoStopped = false;
  public contextMenuPosition = { x: "0px", y: "0px" };
  @Input() public expansionPanelDisabled = false;
  @ViewChild(MatMenuTrigger) matMenuTrigger: MatMenuTrigger;
  @Input() public pinned = false;
  @Input() public readonly = false;
  public showLoadingSpinner = false;
  public syncProgress = 0;
  @Input() timer: ITimer;
  public timerDuration: Observable<string>;
  private _syncedWorklogsCount = 0;
  public environment = environment;
  private _destroyEmitter = new EventEmitter<void>();

  constructor(
    public timerService: TimerService,
    public dialog: MatDialog,
    public editableDurationsListService: EditableDurationsListService,
    private _snackBar: MatSnackBar,
    public _settingsService: SettingsService,
  ) {
  }

  public calculateSyncProgressAndSyncedWorklogsCount(maxSyncProgress: number): void {
    if (!this.showLoadingSpinner) {
      this.showLoadingSpinner = true;
    }

    this._syncedWorklogsCount += 1;
    this.syncProgress = this._syncedWorklogsCount * (100 / maxSyncProgress);
  }

  ngOnChanges(changes): void {
    if (changes.hasOwnProperty("timer")) {
      if (this.timerService.isActiveTimer(this.timer)) {
        this.timerDuration = this.timerService.getIntervalObservable(
          this.timer,
          this.editableDurationsListService.dateFrom,
          this.editableDurationsListService.dateTo,
        );

        if (this.timer.alarm) {
          this.alarmDuration$ = this.timerService.getAlarmIntervalObservable(this.timer);
        }
      } else {
        if (this.timer.alarm) {
          this.alarmDuration$ = of(this.timerService.calculateAlarmTime(this.timer));
        }

        this.timerDuration = of(this.timerService.calculateRunningTime(
          this.timer,
          this.editableDurationsListService.dateFrom,
          this.editableDurationsListService.dateTo,
        ));
      }
    }
  }

  public openAddDurationDialog(): void {
    this.dialog.open(AddDurationDialogComponent, {
      data: {
        title: "Arbeitszeit buchen",
        timer: this.timer,
      },
    });
  }

  public openAlarmDialog(): void {
    this.dialog.open(CreateAlarmDialogComponent, {
      id: "alarmDialog",
      data: {
        timer: this.timer,
        title: "ALARM",
      },
    });
  }

  public openContextMenu(event: MouseEvent): void {
    if (!this.readonly && !this.pinned) {
      event.preventDefault();
      this.contextMenuPosition.x = event.clientX + "px";
      this.contextMenuPosition.y = event.clientY + "px";
      this.matMenuTrigger.openMenu();
    }
  }

  public openDeleteAlarmConfirmDialog(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      id: "deleteAlarmConfirmDialog",
      data: {
        title: "Sind Sie sicher?",
      },
    });

    dialogRef.afterClosed()
      .pipe(
        takeUntil(this._destroyEmitter),
        take(1),
        filter((result) => !!result),
        tap(() => this.timerService.stopAlarm$.next(this.timer)),
      )
      .subscribe();
  }

  public openDeleteTimerConfirmDialog(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      id: "deleteTimerConfirmDialog",
      data: {
        title: "Sind Sie sicher?",
        timer: this.timer,
      },
    });

    dialogRef.afterClosed()
      .pipe(
        takeUntil(this._destroyEmitter),
        take(1),
        filter((result) => !!result),
        tap(() => this.timerService.removeTimer(this.timer)),
      ).subscribe();
  }

  public openEditAlarmDialog(): void {
    this.dialog.open(EditAlarmDialogComponent, {
      id: "editAlarmDialog",
      data: {
        title: "Alarm ändern",
        timer: this.timer,
      },
    });
  }

  public openEditDialog(): void {
    if (this.autoStopped) {
      this.editableDurationsListService.dateFrom = undefined;
      this.editableDurationsListService.format = Format.DATETIME;
    }

    this.dialog.open(EditTimerDurationsDialogComponent, {
      id: "editDialog",
      data: {
        title: this.editableDurationsListService.dateFrom ? this.editableDurationsListService.dateFrom.format("DD.MM.YYYY") : null,
        timer: this.timer,
        format: this.editableDurationsListService.format,
      },
    });
  }

  public openEditNameDialog(): void {
    this.dialog.open(EditTimerNameDialogComponent, {
      id: "editTimerNameDialog",
      data: {
        title: "Name ändern",
        timer: this.timer,
      },
    });
  }

  public playPauseTimer(): void {
    const currentDate = moment();
    if (this.timerService.isActiveTimer(this.timer)) {
      this.timerService.stopActiveTimer(currentDate);

      this.timerDuration = of(this.timerService.calculateRunningTime(
        this.timer,
        this.editableDurationsListService.dateFrom,
        this.editableDurationsListService.dateTo,
      ));
    } else {
      if (!this.timer.durations[currentDate.year()]) {
        this.timer.durations[currentDate.year()] = {};
      }

      if (!this.timer.durations[currentDate.year()][currentDate.month()]) {
        this.timer.durations[currentDate.year()][currentDate.month()] = {};
      }

      if (!this.timer.durations[currentDate.year()][currentDate.month()][currentDate.date()]) {
        this.timer.durations[currentDate.year()][currentDate.month()][currentDate.date()] = [];
      }

      const timerWithStandardType = this._settingsService.getTimerWithStandardType(this.timer.name);

      this.timer.durations[currentDate.year()][currentDate.month()][currentDate.date()].push({
        startTime: currentDate,
        activityType: timerWithStandardType ? timerWithStandardType.type : this.timerService.activityTypes[0],
      });

      this.timerService.setActiveTimer(this.timer);

      this.timerDuration = this.timerService.getIntervalObservable(
        this.timer,
        this.editableDurationsListService.dateFrom,
        this.editableDurationsListService.dateTo,
      );
    }
  }

  public showErrorSnackBar(error): void {
    switch (error.status) {
      case 401:
        this._snackBar.open(
          "Melden Sie sich in Jira an!",
          "Okay",
          {
            duration: 5000,
          },
        );
        break;
      case 500:
        this._snackBar.open(
          "In Jira ist ein Fehler aufgetreten!",
          "Okay",
          {
            duration: 5000,
          },
        );
        break;
      default:
        this._snackBar.open(
          `Error: ${error.status}, Error Message: ${error.error.errorMessages[0]}`,
          "Okay",
        );
        break;
    }
  }

  public showSuccessSnackBar(): void {
    this.syncProgress = 100;
    this.showLoadingSpinner = false;

    this._snackBar.open(
      `${this._syncedWorklogsCount} ${this._syncedWorklogsCount > 1 ? "Vorgänge wurden" : "Vorgang wurde"} mit Jira synchronisiert`,
      "Okay",
      {
        duration: 5000,
      },
    );
    this._syncedWorklogsCount = 0;
  }

  public syncFailedDurationsWithJira(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Fehlgeschlagene Durations mit Jira synchronisieren?",
        content: `Wenn Jira Worklogs aktueller sind, wird Ihr Timer ${this.timer.name} damit überschrieben!`,
      },
    });

    dialogRef.afterClosed()
      .pipe(
        takeUntil(this._destroyEmitter),
        take(1),
        switchMap((confirmationResult) => {
          const allTimerDurationData: IDurationData[] = this.timerService.getAllTimerDurationData(this.timer);
          const failedDurationsData: IDurationData[] = allTimerDurationData.filter((durationData) => {
            return durationData.syncedWithJira !== undefined && !durationData.syncedWithJira;
          });
          const maxSyncProgress = failedDurationsData.length;

          if (confirmationResult) {
            if (failedDurationsData.length > 0) {
              let occurredError;

              return this.timerService.syncSelectedDurationsWithJira(this.timer, failedDurationsData)
                .pipe(
                  catchError((error) => {
                    occurredError = error;

                    this.showLoadingSpinner = false;

                    if (error.status) {
                      this.showErrorSnackBar(error);
                    }

                    return throwError(error);
                  }),
                  tap(() => {
                    this.calculateSyncProgressAndSyncedWorklogsCount(maxSyncProgress);
                  }),
                  finalize(() => {
                    if (!occurredError) {
                      this.showSuccessSnackBar();
                    }
                  }),
                );
            } else {
              this._snackBar.open(
                `${this.timer.name} hat keine fehlegeschlagene Durations`,
                "Okay",
                {
                  duration: 5000,
                },
              );

              return EMPTY;
            }
          } else {
            return EMPTY;
          }
        }),
      )
      .subscribe();
  }

  public syncTimerDurationsFromDate(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: `${this.editableDurationsListService.dateFrom ? "Durations von " + this.editableDurationsListService.dateFrom.format(
          "DD.MM.YYYY") : "Alle Timer Durations"} mit Jira synchronisieren?`,
        content: `Wenn Jira Worklogs aktueller sind, wird Ihr Timer ${this.timer.name} damit überschrieben!`,
      },
    });

    dialogRef.afterClosed()
      .pipe(
        takeUntil(this._destroyEmitter),
        take(1),
        switchMap((confirmationResult) => {
          const selectedDateFrom = this.editableDurationsListService.dateFrom;
          const allTimerDurationData: IDurationData[] = this.timerService.getAllTimerDurationData(this.timer);
          let filteredDurationData: IDurationData[];

          if (selectedDateFrom === undefined) {
            filteredDurationData = allTimerDurationData;
          } else {
            filteredDurationData = this.timer.durations[selectedDateFrom.year()][selectedDateFrom.month()][selectedDateFrom.date()];
          }

          const maxSyncProgress = filteredDurationData.length;

          if (confirmationResult) {
            let occurredError;

            return this.timerService.syncSelectedDurationsWithJira(this.timer, filteredDurationData)
              .pipe(
                takeUntil(this._destroyEmitter),
                catchError((error) => {
                  occurredError = error;

                  this.showLoadingSpinner = false;

                  if (error.status) {
                    this.showErrorSnackBar(error);
                  }

                  return throwError(error);
                }),
                tap(() => {
                  this.calculateSyncProgressAndSyncedWorklogsCount(maxSyncProgress);
                }),
                finalize(() => {
                  if (!occurredError) {
                    this.showSuccessSnackBar();
                  }
                }),
              );
          } else {
            return EMPTY;
          }
        }),
      )
      .subscribe();
  }

  public syncTimerWithJira(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Timer mit Jira synchronisieren?",
        content: `Wenn Jira Worklogs aktueller sind, wird Ihr Timer ${this.timer.name} damit überschrieben!`,
      },
    });

    dialogRef.afterClosed()
      .pipe(
        takeUntil(this._destroyEmitter),
        take(1),
        switchMap((confirmationResult) => {
          const allTimerDurationData: IDurationData[] = this.timerService.getAllTimerDurationData(this.timer);
          const maxSyncProgress = allTimerDurationData.length;

          this._syncedWorklogsCount = 0;

          let occurredError;

          if (confirmationResult) {
            return this.timerService.syncTimerWithJira(this.timer)
              .pipe(
                catchError((error) => {
                  occurredError = error;

                  this.showLoadingSpinner = false;

                  if (error.status) {
                    this.showErrorSnackBar(error);
                  }

                  return throwError(error);
                }),
                tap(() => {
                  this.calculateSyncProgressAndSyncedWorklogsCount(maxSyncProgress);
                }),
                finalize(() => {
                  if (!occurredError) {
                    this.showSuccessSnackBar();
                  }
                }),
              );
          } else {
            return EMPTY;
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroyEmitter.next();
  }
}
