import { Component, Inject } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import * as moment from "moment";
import { Duration, Moment } from "moment";
import { CustomValidators } from "../custom-validators";
import { DateAndTimeUtilities } from "../date-and-time-utilities";
import { IMMEDIATE_ERROR_STATE_MATCHER } from "../immediate-error-state-matcher";
import { TimerService } from "../services/timer.service";

interface ICreateTimerForm {
  name: FormControl<string>;
  time: FormControl<string>;
  duration: FormControl<string>;
  datepicker: FormControl<number>;
  activityType: FormControl<string>;
}

@Component({
  selector: "nx-create-dialog",
  templateUrl: "./create-timer-dialog.component.html",
  styleUrls: ["./create-timer-dialog.component.scss"],
})
export class CreateTimerDialogComponent {
  public createTimerForm: FormGroup<ICreateTimerForm>;
  public date: Moment;
  public dateNotToday: boolean = false;
  public immediateMatcher = IMMEDIATE_ERROR_STATE_MATCHER;
  public maxDate: Moment;
  public newActivityType: string = this.timerService.activityTypes[0];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public formBuilder: FormBuilder,
    public timerService: TimerService,
    public dialogRef: MatDialogRef<CreateTimerDialogComponent>,
  ) {
    this.createTimerForm = this.formBuilder.nonNullable.group({
      "name": ["", [Validators.required, CustomValidators.validateName]],
      "time": ["", [Validators.required, CustomValidators.validateTime]],
      "duration": ["", [CustomValidators.validateDuration]],
      "datepicker": [0, Validators.required],
      "activityType": "Development",
    });

    this.createTimerForm.setValidators(this.validateCreateTimerForm);
    this.createTimerForm.updateValueAndValidity();

    this.maxDate = moment.utc();
  }

  public changeDateValue(value: Moment) {
    this.date = moment(value).utc();

    this.dateNotToday = this.date.valueOf() < moment().startOf("day").valueOf(); // simplified if statement
  }

  public getTypeValue(event) {
    this.newActivityType = event.value;
  }

  public saveTimer() {
    let timerDuration: Duration = null;
    const time = moment(this.createTimerForm.get("time").value, "HH:mm");
    const name = this.createTimerForm.get("name").value;
    const duration = this.createTimerForm.get("duration").value;

    this.date.add(time.hours(), "h")
      .add(time.minutes(), "m");

    if (this.createTimerForm.valid) {
      if (duration) {
        timerDuration = DateAndTimeUtilities.getDuration(duration);
      }

      this.timerService.addTimer(name, this.date, this.newActivityType, timerDuration);

      this.dialogRef.close();
    }
  }

  public validateCreateTimerForm(form: FormGroup<ICreateTimerForm>): null | ValidationErrors {
    let validationError = null;
    const timeControl = form.controls.time;
    const durationControl = form.controls.duration;
    const datepickerControl = form.controls.datepicker;

    const hours = moment(timeControl.value, "HH:mm").hours();
    const minutes = moment(timeControl.value, "HH:mm").minutes();

    const duration = DateAndTimeUtilities.getDuration(durationControl.value);
    const datepickerWithTime = moment(form.controls.datepicker.value).add(hours, "hours").add(minutes, "minutes");

    if (duration) {
      const dateWithDuration = moment(datepickerWithTime).add(duration);

      if (dateWithDuration.valueOf() > moment().valueOf()) {
        durationControl.setErrors({ ...durationControl.errors, "dateInFuture": true });

        return validationError = { "dateInFuture": true };
      } else {
        if (
          durationControl
          && durationControl.errors
          && durationControl.errors["dateInFuture"]
        ) {
          delete durationControl.errors["dateInFuture"];
          durationControl.updateValueAndValidity();
        }
      }
    } else {
      if (
        datepickerControl.value.valueOf()
        && moment(timeControl.value, "HH:mm", true).isValid()
      ) {
        if (datepickerControl.value.valueOf() < moment().startOf("day").valueOf()) {
          durationControl.setErrors({ ...durationControl.errors, "dateInPastWithoutDuration": true });
        } else {
          if (
            durationControl
            && durationControl.errors
            && durationControl.errors["dateInPastWithoutDuration"]
          ) {
            delete durationControl.errors["dateInPastWithoutDuration"];
            durationControl.updateValueAndValidity();
          }
        }
      }
    }

    return validationError;
  }
}
