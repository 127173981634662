import { Component } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "nx-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  constructor(private _matIconRegistry: MatIconRegistry, private _domSanitizer: DomSanitizer) {
    this._matIconRegistry.addSvgIcon(
      "error",
      this._domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/error.svg"),
    );
    this._matIconRegistry.addSvgIcon(
      "check",
      this._domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/check.svg"),
    );
    this._matIconRegistry.addSvgIcon(
      "help",
      this._domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/help.svg"),
    );
    this._matIconRegistry.addSvgIcon(
      "star_blue",
      this._domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/star_blue.svg"),
    );
    this._matIconRegistry.addSvgIcon(
      "star_green",
      this._domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/star_green.svg"),
    );
    this._matIconRegistry.addSvgIcon(
      "star_red",
      this._domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/star_red.svg"),
    );
    this._matIconRegistry.addSvgIcon(
      "star_yellow",
      this._domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/star_yellow.svg"),
    );
    this._matIconRegistry.addSvgIcon(
      "thumbsdown",
      this._domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/thumbsdown.svg"),
    );
    this._matIconRegistry.addSvgIcon(
      "thumbsup",
      this._domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/thumbsup.svg"),
    );
    this._matIconRegistry.addSvgIcon(
      "checkbox",
      this._domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/checkbox_blank.svg"),
    );
  }
}
