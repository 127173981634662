import { Component, Inject } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CustomValidators } from "../custom-validators";
import { IMMEDIATE_ERROR_STATE_MATCHER } from "../immediate-error-state-matcher";
import { TimerService } from "../services/timer.service";

interface IEditAlarmForm {
  alarmDuration: FormControl<string>;
}

@Component({
  selector: "nx-edit-alarm-dialog",
  templateUrl: "./edit-alarm-dialog.component.html",
  styleUrls: ["./edit-alarm-dialog.component.scss"],
})
export class EditAlarmDialogComponent {
  public editAlarmForm: FormGroup<IEditAlarmForm>;
  public immediateMatcher = IMMEDIATE_ERROR_STATE_MATCHER;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public formBuilder: FormBuilder,
    public timerService: TimerService,
    public dialogRef: MatDialogRef<EditAlarmDialogComponent>,
  ) {
    this.editAlarmForm = this.formBuilder.nonNullable.group(
      {
        "alarmDuration": [
          "",
          [Validators.required, CustomValidators.validateDuration],
        ],
      },
    );
  }

  public setAlarm(duration: string) {
    this.timerService.setAlarm(duration, this.data["timer"]);

    this.dialogRef.close();
  }
}
