import { Component, EventEmitter, OnDestroy } from "@angular/core";
import { LocalStorageService } from "../services/local-storage.service";
import { SettingsService } from "../services/settings.service";
import { TimerService } from "../services/timer.service";
import { ITimer } from "../timer/timer.interface";
import { takeUntil, tap } from "rxjs/operators";

@Component({
  selector: "nx-extendable-information-panel",
  templateUrl: "./extendable-information-panel.component.html",
  styleUrls: ["./extendable-information-panel.component.scss"],
})
export class ExtendableInformationPanelComponent implements OnDestroy {
  public autoStoppedTimer: ITimer = LocalStorageService.getItem("autoStoppedTimer");
  public panelIsExpanded = LocalStorageService.getItem("settings")["pinnedTimers"]["panelExpanded"];
  private _destroyEmitter = new EventEmitter<void>();

  constructor(
    public timerService: TimerService,
    public settingsService: SettingsService,
    private _localStorageService: LocalStorageService,
  ) {
    this._localStorageService.localStorageChange$
      .pipe(
        takeUntil(this._destroyEmitter),
        tap(() => this.autoStoppedTimer = LocalStorageService.getItem("autoStoppedTimer")),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroyEmitter.next();
  }
}
