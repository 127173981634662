<nx-dialog [dialogData]="data">
  <form [formGroup]="alarmForm" class="dialog-content">
    <div class="container">
      <div class="toggle-container">
        <mat-button-toggle-group #alarmStyle="matButtonToggleGroup">
          <mat-button-toggle checked value="Zeit" (change)="changeValidator('Zeit')">
            <mat-icon>schedule</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="Prozent" (change)="changeValidator('Prozent')">
            <mat-icon>percent</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <mat-form-field appearance="outline">
        <mat-label>{{ alarmStyle.value ? alarmStyle.value : "Zeit" }}</mat-label>
        <input
          formControlName="alarmDuration"
          [placeholder]="alarmStyle.value === 'Zeit' ? '3h 4m' : '75'"
          matInput
          #alarm
          [errorStateMatcher]="immediateMatcher"
          autocomplete="off"
          maxlength="6"
        >
        <mat-error
          *ngIf="alarmStyle.value === 'Zeit' && alarmForm.controls.alarmDuration.hasError('durationFormatError')">
          Zeitformat: 1h30m/1h 30m/1h/1m
        </mat-error>
        <mat-error
          *ngIf="alarmStyle.value === 'Prozent' && alarmForm.controls.alarmDuration.hasError('durationFormatError')">
          Prozentformat: 1 / 17 / 100
        </mat-error>
      </mat-form-field>
    </div>
  </form>
  <button
    [disabled]="alarmForm.invalid"
    class="dialog-actions"
    mat-raised-button
    color="primary"
    (click)="setAlarm(alarm.value, alarmStyle.value)"
  >Hinzufügen
  </button>
  <button class="dialog-actions" mat-raised-button color="warn" mat-dialog-close="true">Abbrechen
  </button>
</nx-dialog>
