import { Component } from "@angular/core";
import { Chart, registerables } from "chart.js";
import { JiraService } from "../services/jira.service";
import { tap } from "rxjs/operators";
import * as moment from "moment";
import { Moment } from "moment";
import { BehaviorSubject } from "rxjs";
import { IJiraIssue, ISprint } from "../jira-issue.interface";

interface IDay {
  date: string;
  moment: Moment;
  workTime: number;
  issues: number;
  bugs: number;
  openTime?: number;
  doneTime?: number;
  doneIssues?: number;
  openIssues?: number;
}

@Component({
  selector: "nx-sprint-time",
  templateUrl: "./sprint-time.component.html",
  styleUrls: ["./sprint-time.component.scss"],
})
export class SprintTimeComponent {
  public myChart: Chart;
  private _hundredToZero: number[] = [];
  private _activeSprint: ISprint;
  private _startDate: Moment;
  private _doneIssues: IJiraIssue[] = [];
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    public jiraService: JiraService,
  ) {
    this.jiraService.getActiveSprint().pipe(tap((jiraSearchResult) => {
      this._activeSprint = this.jiraService.activeSprint;
      this._startDate = moment(this._activeSprint.startDate);

      jiraSearchResult.issues.forEach((issue) => {
        if (issue.fields.resolutiondate) {
          this._doneIssues.push(issue);
        }
      });

      this.createGraph(jiraSearchResult.issues);
    })).subscribe();
  }

  public createGraph(issues: IJiraIssue[]) {
    Chart.register(...registerables);

    const sprintDays: IDay[] = [];
    let date = moment(this._startDate);

    for (let i = 0; sprintDays.length < 15 || i === 22; i++) {
      if (date.day() !== 0 && date.day() !== 6) {
        sprintDays.push({ date: date.format("DD.MM."), moment: moment(date), workTime: 0, bugs: 0, issues: 0 });
      }

      date = date.add(1, "d");
    }

    let timeSpent: number = 0;
    let workDone: number = 0;
    let loggedDay: boolean = true;

    sprintDays.forEach((sprintDay) => {
      sprintDay.openIssues = 0;
      sprintDay.doneIssues = 0;

      if (loggedDay) {
        issues.forEach((issue) => {
          if (moment(issue.fields.created).startOf("day").isBefore(sprintDay.moment.endOf("day"))) {
            if (issue.fields.aggregatetimeoriginalestimate) {
              sprintDay.workTime += issue.fields.aggregatetimeoriginalestimate;
            } else if (issue.fields.aggregatetimeestimate) {
              sprintDay.workTime += issue.fields.aggregatetimeestimate;
            }

            if (issue.fields.issuetype.name === "Bug") {
              sprintDay.bugs++;
            } else {
              sprintDay.issues++;
            }

            if (moment(issue.fields.resolutiondate).isSameOrBefore(sprintDay.moment.endOf("day"))) {
              sprintDay.doneIssues++;
            } else {
              sprintDay.openIssues++;
            }
          }
        });

        this._doneIssues.forEach((issue) => {
          if (moment(issue.fields.resolutiondate).format("DD.MM.") === sprintDay.date) {
            timeSpent += issue.fields.aggregatetimespent;

            if (issue.fields.aggregatetimeoriginalestimate) {
              workDone += issue.fields.aggregatetimeoriginalestimate;
            } else if (issue.fields.aggregatetimeestimate) {
              workDone += issue.fields.aggregatetimeestimate;
            }
          }
        });

        sprintDay.doneTime = (Math.round(timeSpent / sprintDay.workTime * 100 * 100) / 100);
        sprintDay.openTime = (Math.round((sprintDay.workTime / 3600 - workDone / sprintDay.workTime * 100) * 100) / 100);
      }

      if (sprintDay.date === moment().format("DD.MM.")) {
        loggedDay = false;
      }
    });

    let totalSprintTime = (sprintDays[0].workTime - (sprintDays[0].workTime / 15)) / 3600;

    for (let i = 0; i < 14; i++) {
      this._hundredToZero[i] = Math.round(totalSprintTime * 100) / 100;
      totalSprintTime = totalSprintTime - (sprintDays[0].workTime / 3600 / 15);
    }

    this._hundredToZero[14] = 0;

    const data = {
      labels: sprintDays.map((day) => day.date),
      datasets: [{
        type: "line",
        label: "Ideal",
        data: this._hundredToZero,
        fill: false,
        borderColor: "rgba(201,203,207,0.55)",
        tension: 0.1,
      },
        {
          type: "line",
          label: "Open Work",
          data: sprintDays.map((day) => day.openTime),
          fill: false,
          borderColor: "rgb(255,99,99)",
          tension: 0.1,
        },
        {
          type: "line",
          label: "Done Work",
          data: sprintDays.map((day) => day.doneTime),
          fill: false,
          borderColor: "rgb(0,135,255)",
          tension: 0.1,
        },
        {
          type: "bar",
          label: "Issues",
          data: sprintDays.map((day) => day.issues),
          backgroundColor: "rgba(75, 192, 192, 0.2)",
          borderColor: "rgb(75, 192, 192)",
          borderWidth: 1,
          stack: "Stack 1",
          hidden: true,
        },
        {
          type: "bar",
          label: "Bugs",
          data: sprintDays.map((day) => day.bugs),
          backgroundColor: "rgba(255,64,64,0.2)",
          borderColor: "rgb(255,64,64)",
          borderWidth: 1,
          stack: "Stack 1",
          hidden: true,
        },
        {
          type: "bar",
          label: "Open Tickets",
          data: sprintDays.map((day) => day.openIssues),
          backgroundColor: "rgba(54, 162, 235, 0.2)",
          borderColor: "rgb(54, 162, 235)",
          borderWidth: 2,
          stack: "Stack 2",
          hidden: true,
        },
        {
          type: "bar",
          label: "Done Tickets",
          data: sprintDays.map((day) => day.doneIssues),
          backgroundColor: "rgba(153, 102, 255, 0.2)",
          borderColor: "rgb(153, 102, 255)",
          borderWidth: 2,
          stack: "Stack 2",
          hidden: true,
        }],
    };

    // @ts-ignore
    this.myChart = new Chart(document.getElementById("myChart"), {
      type: "mixed",
      data: data,
      options: {
        scales: {
          y: {
            title: {
              display: true,
            },
          },
          x: {
            title: {
              display: true,
            },
          },
        },
      },
    });

    this.isLoading$.next(false);
  }
}
