import { Component, EventEmitter, Inject, OnDestroy } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { EditDurationsService } from "../services/edit-durations.service";
import { finalize, takeUntil } from "rxjs/operators";
import { JiraService } from "../services/jira.service";

@Component({
  selector: "nx-edit-dialog",
  templateUrl: "./edit-timer-durations-dialog.component.html",
  styleUrls: ["./edit-timer-durations-dialog.component.scss"],
})
export class EditTimerDurationsDialogComponent implements OnDestroy {
  public durationsFormValid: boolean;
  private _destroyEmitter = new EventEmitter<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<EditTimerDurationsDialogComponent>,
    public editDurationsService: EditDurationsService,
    public jiraService: JiraService,
  ) {
  }

  public isDurationFormValid(event): void {
    this.durationsFormValid = event;
  }

  public saveChanges(): void {
    this.jiraService.isLoading$.next(true);
    this.editDurationsService.saveChanges()
      .pipe(
        takeUntil(this._destroyEmitter),
        finalize(() => {
          this.dialogRef.close();
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.jiraService.isLoading$.next(false);
    this._destroyEmitter.next();
  }
}
