import { Component } from "@angular/core";
import { TimerService } from "../services/timer.service";
import { ITimer } from "../timer/timer.interface";

@Component({
  selector: "nx-pinned-timers",
  templateUrl: "./pinned-timers.component.html",
  styleUrls: ["./pinned-timers.component.scss"],
})
export class PinnedTimersComponent {
  constructor(
    public timerService: TimerService,
  ) {
  }

  public trackByTimerName(_, item: ITimer) {
    return item.name;
  }
}
