import { HttpErrorResponse } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Title } from "@angular/platform-browser";
import * as moment from "moment";
import { Duration, Moment } from "moment";
import { BehaviorSubject, concat, EMPTY, forkJoin, from, iif, Observable, of, Subject, throwError } from "rxjs";
import {
  catchError,
  concatMap,
  filter,
  finalize,
  map,
  mapTo,
  skipWhile,
  startWith,
  switchMap,
  take,
  takeUntil,
  takeWhile,
  tap,
} from "rxjs/operators";
import { DateAndTimeUtilities } from "../date-and-time-utilities";
import { IDurationData } from "../duration-data.interface";
import { IEditableDurationsListChange } from "../editable-durations-list/editable-durations-list.component";
import { EditableDurationsListService } from "../editable-durations-list/editable-durations-list.service";
import { IntervalService } from "./interval.service";
import { IJiraIssue } from "../jira-issue.interface";
import { IJiraResponse } from "../jira-response.interface";
import { IJiraWorklog, IJiraWorklogResponse } from "../jira-worklogs.interface";
import { JiraService } from "./jira.service";
import { LocalStorageService } from "./local-storage.service";
import { NotificationService } from "./notification.service";
import { ITimer } from "../timer/timer.interface";
import { ConvertTimeService } from "./convert-time.service";

export interface ITimers {
  [key: string]: ITimer;
}

@Injectable({
  providedIn: "root",
})
export class TimerService {
  public activeTimer: BehaviorSubject<ITimer>;
  public activeTimerAlarmDuration: Observable<string>;
  public activeTimerDuration: Observable<string>;
  public activityTypes: string[] = [
    "Development", // Must be at index 0 because it is used as default value at this position
    "Codereview",
    "Refinement",
    "Consulting",
    "Creation",
    "Clearing",
    "Support",
    "Administration",
    "Undefined",
    "Planning",
    "Reporting",
  ];
  public beginningOfDay: string;
  public endOfCurrentDay = moment().endOf("day");
  public filteredTimersForAutocomplete$: Observable<ITimer[]>;
  public jiraAccountId: string;
  public pinnedTimers: ITimer[] = [];
  public timers: ITimer[] = [];
  public totalTodayDurations: BehaviorSubject<string> = new BehaviorSubject<string>("00:00:00");
  public stopAlarm$ = new EventEmitter<ITimer>();
  public descriptionOverwriteEmitter: Subject<string> = new Subject<string>();

  constructor(
    public localStorageService: LocalStorageService,
    public snackBar: MatSnackBar,
    public notificationService: NotificationService,
    public intervalService: IntervalService,
    public jiraService: JiraService,
    public editableDurationsListService: EditableDurationsListService,
    private _titleService: Title,
    private _convertTimeService: ConvertTimeService,
  ) {
    this.setBeginningOfDay();
    this.initializeTimers();

    this.localStorageService.localStorageChange$
      .pipe(
        tap(() => {
          this.pinnedTimers = this.getPinnedTimers();
          this.setBeginningOfDay();
          this.totalTodayDurations.next(this.calculateTotalRunningTimeTodayTimers(this.timers));
        }),
      )
      .subscribe();

    this.activeTimer = new BehaviorSubject<ITimer>(LocalStorageService.getItem("activeTimer"));

    this.activeTimer
      .pipe(
        tap((timer) => {
          if (timer) {
            this.activeTimerDuration = this.getIntervalObservable(timer, moment());

            if (timer.alarm) {
              this.activeTimerAlarmDuration = this.getAlarmIntervalObservable(timer);
            }
          } else {
            this._titleService.setTitle("TimerApp");
          }
        }),
      )
      .subscribe();

    this.jiraService.getJiraUser()
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status) {
            switch (error.status) {
              case 404:
                break;
              case 401:
                this.snackBar.open(
                  "Melden Sie sich in Jira an. User Name konnte nicht gespeichert werden.",
                  "Okay",
                );
                break;
              case 500:
                this.snackBar.open(
                  "In Jira ist ein Fehler aufgetreten! User Name konnte nicht gespeichert werden.",
                  "Okay",
                );
                break;
              default:
                this.snackBar.open(
                  `Error: ${error.status}. User Name konnte nicht gespeichert werden.`,
                  "Okay",
                );
                break;
            }
          }

          return throwError(error);
        }),
        tap((userResponse) => this.jiraAccountId = userResponse["accountId"]),
      )
      .subscribe();

    this.intervalService.interval$
      .pipe(
        tap(() => {
          if (moment().diff(this.endOfCurrentDay, "milliseconds") >= 0) {
            this.initializeTimers();
            this.endOfCurrentDay = moment().endOf("day");
            this.editableDurationsListService.dateFrom = moment();
            this.beginningOfDay = "";
            this.totalTodayDurations.next(this.calculateTotalRunningTimeTodayTimers(this.timers));
          }
        }),
      )
      .subscribe();

    this.stopAlarm$.pipe(
      tap((timer) => {
        timer.alarm = null;
        this.setTimer(timer);

        if (this.isActiveTimer(timer)) {
          this.activeTimer.next(timer);
        }
      }),
    ).subscribe();

    this.totalTodayDurations.next(this.calculateTotalRunningTimeTodayTimers(this.timers));
  }

  static getAllTimers(): ITimer[] {
    const localStorageTimers: ITimers = LocalStorageService.getItem("timers");
    let allTimers: ITimer[] = [];

    if (localStorageTimers) {
      allTimers = Object.values(localStorageTimers);
    }

    return allTimers;
  }

  public addDurationData(timer: ITimer, dateFrom: Moment, dateTo: Moment, activityType: string, date: Moment = moment()): void {
    if (timer.syncedWithJira !== undefined) {
      if (!timer.durations[date.year()]) {
        timer.durations[date.year()] = {};
      }

      if (!timer.durations[date.year()][date.month()]) {
        timer.durations[date.year()][date.month()] = {};
      }

      if (!timer.durations[date.year()][date.month()][date.date()]) {
        timer.durations[date.year()][date.month()][date.date()] = [];
      }

      timer.durations[date.year()][date.month()][date.date()].push({
        startTime: dateFrom,
        duration: moment.duration(moment(dateTo).diff(dateFrom)),
        updated: moment(),
        activityType: activityType,
      });

      timer.durations[date.year()][date.month()][date.date()] = this.sortDurationsData(
        timer.durations[date.year()][date.month()][date.date()],
      );

      if (this.isActiveTimer(timer)) {
        // active timer will be replaced with timer with new durations
        this.activeTimer.next(timer);
      }

      this.setTimer(timer);

      const index = timer.durations[date.year()][date.month()][date.date()].findIndex((durationData) => durationData.startTime === dateFrom);

      this.syncDurationWithJira(timer, dateFrom, moment.duration(moment(dateTo).diff(dateFrom)), index)
        .pipe(take(1))
        .subscribe();
    } else {
      if (!timer.durations[date.year()]) {
        timer.durations[date.year()] = {};
      }

      if (!timer.durations[date.year()][date.month()]) {
        timer.durations[date.year()][date.month()] = {};
      }

      if (!timer.durations[date.year()][date.month()][date.date()]) {
        timer.durations[date.year()][date.month()][date.date()] = [];
      }

      timer.durations[date.year()][date.month()][date.date()].push({
        startTime: dateFrom,
        duration: moment.duration(moment(dateTo).diff(dateFrom)),
        updated: moment(),
        savedLocal: true,
        activityType: activityType,
      });

      timer.durations[date.year()][date.month()][date.date()] = this.sortDurationsData(
        timer.durations[date.year()][date.month()][date.date()],
      );

      this.setTimer(timer);
    }
  }

  public addTimer(name: string, startedDate: Moment = moment.utc(), activityType: string, timerDuration?: Duration): void {
    const allTimers: ITimer[] = TimerService.getAllTimers();

    const existingTimer = allTimers.find((element) => {
      return element.name === name;
    });

    if (!timerDuration) {
      if (this.activeTimer.value) {
        this.stopActiveTimer(startedDate);
      }
    }

    if (existingTimer) {
      this.createTimer(name, startedDate, activityType, timerDuration);

      if (existingTimer.syncedWithJira !== undefined) {
        const timer = this.getTimerByName(name);
        const timerDurationData: IDurationData = timer.durations[startedDate.year()][startedDate.month()][startedDate.date()].find((data) => data.startTime.milliseconds === startedDate.milliseconds);

        this.syncDurationWithJira(timer, startedDate, timerDuration, null, null, timerDurationData)
          .pipe(
            take(1),
            catchError((error) => {
              if (error.status) {
                timer.syncedWithJira = false;
                this.setTimer(timer);

                switch (error.status) {
                  case 401:
                    this.snackBar.open(
                      "Timer wurde nicht mit Jira synchronisiert! Melden Sie sich in Jira an und" +
                      " synchronisieren Sie den Timer manuell.",
                      "Okay",
                    );
                    break;
                  case 500:
                    this.snackBar.open(
                      "In Jira ist ein Fehler aufgetreten! Synchronisieren Sie den Timer manuell.",
                      "Okay",
                    );
                    break;
                  default:
                    this.snackBar.open(
                      `Error: ${error.status}. Synchronisieren Sie den Timer manuell.`,
                      "Okay",
                    );
                    break;
                }
              }

              return throwError(error);
            }),
          )
          .subscribe();
      }
    } else {
      this.syncJiraWorklogs(name)
        .pipe(
          take(1),
          catchError((error) => {
            if (error instanceof HttpErrorResponse && error.status) {
              switch (error.status) {
                case 404:
                  this.createTimer(name, startedDate, activityType, timerDuration);
                  break;
                case 401:
                  this.createTimer(name, startedDate, activityType, timerDuration, false);
                  this.snackBar.open(
                    "Timer wurde nicht mit Jira synchronisiert! Melden Sie sich in Jira an und" +
                    " synchronisieren Sie den Timer manuell.",
                    "Okay",
                  );
                  break;
                case 500:
                  this.createTimer(name, startedDate, activityType, timerDuration, false);
                  this.snackBar.open(
                    "In Jira ist ein Fehler aufgetreten! Synchronisieren Sie den Timer manuell.",
                    "Okay",
                  );
                  break;
                default:
                  this.createTimer(name, startedDate, activityType, timerDuration, false);
                  this.snackBar.open(
                    `Error: ${error.status}. Synchronisieren Sie den Timer manuell.`,
                    "Okay",
                  );
                  break;
              }
            } else {
              console.error("An error occurred while syncing jira worklogs", error);
            }

            return EMPTY;
          }),
          switchMap(() => {
            this.createTimer(name, startedDate, activityType, timerDuration);
            const timer = this.getTimerByName(name);
            const timerDurationData: IDurationData = timer.durations[startedDate.year()][startedDate.month()][startedDate.date()].find((data) => data.startTime.milliseconds === startedDate.milliseconds);

            return this.syncDurationWithJira(timer, startedDate, timerDuration, null, null, timerDurationData).pipe(take(1));
          }),
        )
        .subscribe();
    }
  }

  public addTimerToLocalStorage(timer: ITimer): void {
    let localStorageTimers: ITimers = LocalStorageService.getItem("timers");

    if (localStorageTimers) {
      localStorageTimers[timer.name] = timer;
    } else {
      localStorageTimers = {
        [timer.name]: timer,
      };
    }

    this.localStorageService.setItem("timers", localStorageTimers);
  }

  public addTimerWorklogToJira(timer: ITimer, currentDate: Moment, timeInSeconds: number, autoStop?: boolean): void {
    const allDurationData: IDurationData[] = this.getAllTimerDurationData(timer);
    const lastDurationData = allDurationData[allDurationData.length - 1];

    if (timeInSeconds >= 60) {
      this.jiraService.addIssueWorklog(timer.name, {
        comment: this.getActivityTypeOrComment(lastDurationData.activityType, "comment"),
        timeSpentSeconds: timeInSeconds,
        started: lastDurationData.startTime.format("YYYY-MM-DDTHH:mm:ss.SSSZZ").toString(),
      })
        .pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.status || error.status === 0) {
              switch (error.status || error.status === 0) {
                case 401:
                  this.snackBar.open(
                    "Melden Sie sich in Jira an!",
                    "Okay",
                  );
                  break;
                case 500:
                  this.snackBar.open(
                    "In Jira ist ein Fehler aufgetreten!",
                    "Okay",
                  );
                  break;
                default:
                  this.snackBar.open(
                    `Error: ${error.status}, Error Message: ${error.error.errorMessages?.length ? error.error.errorMessages[0] : error.message}`,
                    "Okay",
                  );
                  break;
              }

              lastDurationData.syncedWithJira = false;
              timer.syncedWithJira = false;
              this.setTimer(timer);
            }

            return throwError(error);
          }),
          filter((response: IJiraResponse) => response.status === 201),
          tap((response: IJiraResponse) => {
            lastDurationData.worklogId = response.body.id;
            lastDurationData.syncedWithJira = true;
            lastDurationData.updated = moment(response.body.updated);

            this.setTimer(timer);

            if (autoStop) {
              this.localStorageService.setItem("autoStoppedTimer", timer);
            }

            this.snackBar.open(
              "Vorgang wurde mit Jira synchronisiert",
              "Okay",
              {
                duration: 5000,
              },
            );
          }),
        )
        .subscribe();
    }
  }

  public addWorklogToJiraOrDeleteWorklogLocal(
    timer: ITimer,
    jiraWorklogs: IJiraWorklog[],
    issueResponse: IJiraIssue,
  ): Observable<void> {
    const timerDurations = this.getAllTimerDurationData(timer);

    return from(timerDurations).pipe(
      concatMap((durationData) => {
        if (!durationData.worklogId) {
          return this.syncDurationWithJira(
            timer,
            durationData.startTime,
            durationData.duration,
            null,
            issueResponse,
            durationData,
          );
        } else if (
          durationData.worklogId
          && !jiraWorklogs.find((jiraWorklog) => durationData.worklogId === jiraWorklog.id)
        ) {
          return this.removeTimerDuration(durationData.startTime, durationData.duration, timer, true);
        } else {
          timer.description = issueResponse.fields.summary;
          this.setTimer(timer);

          return of(void 0);
        }
      }),
      catchError((error) => {
        return throwError(error);
      }),
    );
  }

  public calculateAlarmTime(timer: ITimer): string {
    const timerDuration: Duration = this.getTimerDuration(timer);

    return moment.utc(moment.duration(timer.alarm.endAlarmDuration).subtract(timerDuration).asMilliseconds()).format("HH:mm:ss");
  }

  public calculateRunningTime(timer: ITimer, dateFrom: Moment, dateTo: Moment = moment(dateFrom)): string {
    const duration: Duration = this.getTimerDuration(timer, dateFrom, dateTo);
    const formattedDuration: string = moment.utc(duration.asMilliseconds()).format("HH:mm:ss");

    if (this.isActiveTimer(timer)) {
      this._titleService.setTitle(timer.name + " " + formattedDuration);
    }

    return formattedDuration;
  }

  public calculateTotalRunningTimeTodayTimers(timers: ITimer[]): string {
    let totalDurationsSum: Duration = moment.duration();
    const durationsData = [];

    for (const timer of timers) {
      durationsData.push(...this.getTimerDurationDataByDateFromTo(timer, moment()));
    }

    totalDurationsSum = durationsData.reduce((accumulator, currentValue) => {
      let currentDuration = currentValue.duration;

      if (!currentDuration) {
        currentDuration = moment.duration(moment()
          .diff(currentValue.startTime));
      }

      return accumulator.add(currentDuration);
    }, totalDurationsSum);

    return moment.utc(totalDurationsSum.asMilliseconds()).format("HH:mm:ss");
  }

  public getActivityTypeOrComment(commentOrActivityType: string, getAsActivityTypeOrComment: "activityType" | "comment"): string {
    let activityType: string;

    if (commentOrActivityType?.includes("[type]: ")) { // Is comment
      const regexMatch = commentOrActivityType.match(/\[type]:\s(\w+)$/);

      activityType = regexMatch.length >= 2 ? regexMatch[1] : "Undefined";
    } else { // is activityType
      activityType = commentOrActivityType || "Undefined";
    }

    const isValidActivityType = this.activityTypes.includes(activityType);
    const commentPrefix = getAsActivityTypeOrComment === "comment" ? "[type]: " : "";

    return `${commentPrefix}${isValidActivityType ? activityType : "Undefined"}`;
  }

  public compareJiraWorklogsAndDurationData(jiraWorklogs: IJiraWorklog[], issueResponse: IJiraIssue, timer: ITimer): Observable<void> {
    const allTimerDurationData: IDurationData[] = this.getAllTimerDurationData(timer);

    return from(jiraWorklogs)
      .pipe(
        concatMap((jiraWorklog) => {
          const durationDataWithJiraId = allTimerDurationData.find((durationData) => durationData.worklogId === jiraWorklog.id);

          if (durationDataWithJiraId) {
            const updateIssueWorklogInJira = this.jiraService.updateIssueWorklog(
              timer.name,
              durationDataWithJiraId.worklogId,
              {
                comment: this.getActivityTypeOrComment(durationDataWithJiraId.activityType || jiraWorklog.comment, "comment"),
                timeSpentSeconds: durationDataWithJiraId.duration.asSeconds(),
                started: durationDataWithJiraId.startTime.format("YYYY-MM-DDTHH:mm:ss.SSSZZ").toString(),
              },
            );

            if (moment(jiraWorklog.updated).valueOf() > moment(durationDataWithJiraId.updated).valueOf()) {
              durationDataWithJiraId.startTime = moment(jiraWorklog.started);
              durationDataWithJiraId.duration = moment.duration(jiraWorklog.timeSpentSeconds, "seconds");
              durationDataWithJiraId.updated = moment(jiraWorklog.updated);
              durationDataWithJiraId.syncedWithJira = true;
              durationDataWithJiraId.activityType = this.getActivityTypeOrComment(jiraWorklog.comment, "activityType");
              timer.description = issueResponse.fields.summary;

              if (durationDataWithJiraId.savedLocal) {
                delete durationDataWithJiraId.savedLocal;
              }

              return of(void 0);
            } else if (moment(jiraWorklog.updated).valueOf() < moment(durationDataWithJiraId.updated).valueOf()) {
              return updateIssueWorklogInJira
                .pipe(
                  tap((response: IJiraResponse) => {
                    durationDataWithJiraId.syncedWithJira = true;
                    durationDataWithJiraId.updated = moment(response.body.updated);

                    if (durationDataWithJiraId.savedLocal) {
                      delete durationDataWithJiraId.savedLocal;
                    }
                  }),
                );
            } else {
              return updateIssueWorklogInJira
                .pipe(
                  tap((response: IJiraResponse) => {
                    durationDataWithJiraId.syncedWithJira = true;
                    durationDataWithJiraId.updated = moment(response.body.updated);
                    durationDataWithJiraId.activityType = this.getActivityTypeOrComment(response.body.comment, "activityType");

                    if (durationDataWithJiraId.savedLocal) {
                      delete durationDataWithJiraId.savedLocal;
                    }
                  }),
                );
            }
          } else {
            const jiraWorklogStartTime: Moment = moment(jiraWorklog.started);

            if (!timer.durations[jiraWorklogStartTime.year()]) {
              timer.durations[jiraWorklogStartTime.year()] = {};
            }

            if (!timer.durations[jiraWorklogStartTime.year()][jiraWorklogStartTime.month()]) {
              timer.durations[jiraWorklogStartTime.year()][jiraWorklogStartTime.month()] = {};
            }

            if (!timer.durations[jiraWorklogStartTime.year()][jiraWorklogStartTime.month()][jiraWorklogStartTime.date()]) {
              timer.durations[jiraWorklogStartTime.year()][jiraWorklogStartTime.month()][jiraWorklogStartTime.date()] = [];
            }

            timer.durations[jiraWorklogStartTime.year()][jiraWorklogStartTime.month()][jiraWorklogStartTime.date()]
              .push({
                startTime: jiraWorklogStartTime,
                duration: moment.duration(jiraWorklog.timeSpentSeconds, "seconds"),
                syncedWithJira: true,
                worklogId: jiraWorklog.id,
                updated: moment(jiraWorklog.updated),
                activityType: this.getActivityTypeOrComment(jiraWorklog.comment, "activityType"),
              });
            timer.syncedWithJira = true;
            timer.description = issueResponse.fields.summary;

            const year = jiraWorklogStartTime.year();
            const month = jiraWorklogStartTime.month();
            const date = jiraWorklogStartTime.date();

            timer.durations[year][month][date] = this.sortDurationsData(timer.durations[year][month][date]);

            return of({});
          }
        }),
        finalize(() => {
          this.isTimerSyncedWithJira(timer);
        }),
        mapTo(void 0),
      );
  }

  public createTimer(
    name: string,
    startedDate: Moment = moment.utc(),
    activityType: string,
    timerDuration?: Duration,
    syncedWithJira?: boolean,
    description?: string,
  ): void {
    const currentTime = moment();
    const allTimers: ITimer[] = TimerService.getAllTimers();

    const existingTimer = allTimers.find((element) => {
      return element.name === name;
    });

    if (existingTimer) {
      if (!existingTimer.durations[startedDate.year()]) {
        existingTimer.durations[startedDate.year()] = {};
      }

      if (!existingTimer.durations[startedDate.year()][startedDate.month()]) {
        existingTimer.durations[startedDate.year()][startedDate.month()] = {};
      }

      if (!existingTimer.durations[startedDate.year()][startedDate.month()][startedDate.date()]) {
        existingTimer.durations[startedDate.year()][startedDate.month()][startedDate.date()] = [];
      }

      if (timerDuration) {
        existingTimer.durations[startedDate.year()][startedDate.month()][startedDate.date()].push({
          startTime: startedDate,
          duration: timerDuration,
          updated: moment(),
          savedLocal: true,
          activityType: activityType,
        });
      } else {
        existingTimer.durations[startedDate.year()][startedDate.month()][startedDate.date()].push({
          startTime: startedDate,
          activityType: activityType,
        });

        this.setActiveTimer(existingTimer);
      }

      this.addTimerToLocalStorage(existingTimer);

      this.timers = this.getTimersByDateFromTo(currentTime);  // timers from today
    } else {
      if (timerDuration) {
        const timerWithDuration: ITimer = {
          name: name,
          durations: {
            [startedDate.year()]: {
              [startedDate.month()]: {
                [startedDate.date()]: [
                  {
                    startTime: startedDate,
                    duration: timerDuration,
                    updated: moment(),
                    savedLocal: true,
                    activityType: activityType,
                  },
                ],
              },
            },
          },
          syncedWithJira: syncedWithJira,
          pinned: false,
        };

        this.addTimerToLocalStorage(timerWithDuration);

        this.timers = this.getTimersByDateFromTo(currentTime);  // timers from today
      } else {
        const timer: ITimer = {
          name: name,
          durations: {
            [startedDate.year()]: {
              [startedDate.month()]: {
                [startedDate.date()]: [
                  {
                    startTime: startedDate,
                    activityType: activityType,
                  },
                ],
              },
            },
          },
          syncedWithJira: syncedWithJira,
          pinned: false,
        };

        this.addTimerToLocalStorage(timer);

        this.setActiveTimer(timer);

        this.timers = this.getTimersByDateFromTo(currentTime);
      }
    }
  }

  public filterTimers(filterValue: string): ITimer[] {
    if (typeof filterValue === "string" && filterValue.length > 0) {
      const timers = TimerService.getAllTimers();

      return timers.filter((filteredTimer) => {
        const matchedName = filteredTimer.name.toLowerCase().includes(filterValue.toLowerCase());
        const matchedDescription = filteredTimer.description ? filteredTimer.description.toLowerCase()
          .includes(filterValue.toLowerCase()) : null;

        if (matchedName) {
          return matchedName;
        } else if (matchedDescription) {
          return matchedDescription;
        }
      });
    } else {
      return [];
    }
  }

  public getAlarmIntervalObservable(timer: ITimer): Observable<string> {
    return this.intervalService.interval$
      .pipe(
        startWith(this.calculateAlarmTime(timer)),
        takeUntil(this.stopAlarm$),
        takeWhile(() => !!timer.alarm),
        map(() => {
          let alarmTime = null;

          if (timer.alarm && timer.alarm.endAlarmDuration) {
            if (this.getTimerDuration(timer).asMilliseconds() >= timer.alarm.endAlarmDuration.asMilliseconds()) {
              this.snackBar.open(
                `Dein Alarm für ${moment.duration(timer.alarm.endAlarmDuration)
                  .subtract(timer.alarm.startAlarmDuration)
                  .humanize()} ist abgelaufen`,
                "Okay",
              );

              this.notificationService.sendNotification(
                `Dein Alarm für ${moment.duration(timer.alarm.endAlarmDuration)
                  .subtract(timer.alarm.startAlarmDuration)
                  .humanize()} ist abgelaufen`,
                { requireInteraction: true },
              );

              this.stopAlarm$.next(timer);
            } else {
              alarmTime = this.calculateAlarmTime(timer);
            }
          }

          return alarmTime;
        }),
      );
  }

  public getAllTimerDurationData(timer: ITimer): IDurationData[] {
    let durations: IDurationData[] = [];

    for (const year in timer.durations) {
      for (const month in timer.durations[year]) {
        for (const date in timer.durations[year][month]) {
          durations = durations.concat(timer.durations[year][month][date]);
        }
      }
    }

    return durations;
  }

  public getIntervalObservable(timer: ITimer, dateFrom: Moment, dateTo: Moment = moment(dateFrom)): Observable<string> {
    return concat(
      of(this.calculateRunningTime(timer, dateFrom, dateTo)),
      this.intervalService.interval$
        .pipe(
          map(() => {
            const allDurationData = this.getAllTimerDurationData(timer);
            const currentDurationData = allDurationData.find((element) => !element.duration);
            const activeTimerEndOfDay = moment(currentDurationData.startTime).endOf("day");

            if (moment().diff(activeTimerEndOfDay, "milliseconds") >= 0) {
              this.stopActiveTimer(activeTimerEndOfDay, true);

              this.snackBar.open(`Dein Timer ${timer.name} wurde um 23:59:59 gestoppt`, "Okay");

              this.notificationService.sendNotification(
                `Dein Timer ${timer.name} wurde um 23:59:59 gestoppt`,
                { requireInteraction: true },
              );

              this.initializeTimers();
            }

            this.totalTodayDurations.next(this.calculateTotalRunningTimeTodayTimers(this.timers));

            return this.calculateRunningTime(timer, dateFrom, dateTo);
          }),
        ),
    );
  }

  public getPinnedTimers(): ITimer[] {
    const localStorageTimers: ITimer[] = Object.values(LocalStorageService.getItem("timers") || []);

    return localStorageTimers.length > 0 ? localStorageTimers.filter((timer: ITimer) => timer.pinned) : [];
  }

  public getTimerByName(name: string): ITimer {
    const allTimers: ITimer[] = TimerService.getAllTimers();

    return allTimers.find((timer) => {
      return timer.name === name;
    });
  }

  public getTimerDuration(timer: ITimer, dateFrom?: Moment, dateTo: Moment = moment(dateFrom)): Duration {
    let durationData: IDurationData[];
    let durationSum: Duration = moment.duration();

    if (dateFrom && dateFrom) {
      durationData = this.getTimerDurationDataByDateFromTo(timer, dateFrom, dateTo);
    } else {
      durationData = this.getAllTimerDurationData(timer);
    }

    durationSum = durationData.reduce((accumulator, currentValue) => {
      let currentDuration = currentValue.duration;

      if (!currentDuration) {
        currentDuration = moment.duration(moment()
          .diff(currentValue.startTime));
      }

      return accumulator.add(currentDuration);
    }, durationSum);

    return durationSum;
  }

  public getTimerDurationDataByDateFromTo(timer: ITimer, dateFrom: Moment, dateTo: Moment = moment(dateFrom)): IDurationData[] {
    const timerDurationData: IDurationData[] = [];
    dateFrom = moment(dateFrom);
    const to = moment(dateTo);
    const monthCount: number = to.diff(dateFrom, "month");

    if (monthCount >= 1) {
      for (let i = 0; i <= monthCount; i++) {
        const newFrom = moment(dateFrom).add(i, "months");

        if (
          timer.durations[newFrom.year()]
          && timer.durations[newFrom.year()][newFrom.month()]
        ) {
          for (const date in timer.durations[newFrom.year()][newFrom.month()]) {

            if (timer.durations[newFrom.year()][newFrom.month()][date]) {
              timerDurationData.push(...timer.durations[newFrom.year()][newFrom.month()][date]);
            }
          }
        }
      }
    } else {
      const daysCount: number = to.diff(dateFrom, "days");

      for (let i = 0; i <= daysCount; i++) {
        const newFrom = moment(dateFrom).add(i, "days");

        if (
          timer.durations
          && timer.durations[newFrom.year()]
          && timer.durations[newFrom.year()][newFrom.month()]
          && timer.durations[newFrom.year()][newFrom.month()][newFrom.date()]
        ) {
          timerDurationData.push(...timer.durations[newFrom.year()][newFrom.month()][newFrom.date()]);
        }
      }
    }

    return timerDurationData;
  }

  public getTimersByDateFromTo(dateFrom: Moment, dateTo: Moment = moment(dateFrom)): ITimer[] {
    const filteredTimers: ITimer[] = [];
    const allTimers = TimerService.getAllTimers();
    const to = moment(dateTo).endOf("day");
    const monthCount: number = to.diff(dateFrom, "month");
    dateFrom = moment(dateFrom).startOf("day");

    for (const timer of allTimers) {
      let pushed = false;

      // check months between start and end
      if (monthCount > 1) {
        for (let i = 1; i <= monthCount; i++) {
          const newFrom = moment(dateFrom)
            .add(i, "month");

          if (
            timer.durations[newFrom.year()]
            && timer.durations[newFrom.year()][newFrom.month()]
          ) {
            filteredTimers.push(timer);
            pushed = true;

            break;
          }
        }
      }

      if (!pushed) {
        for (const currentMoment of [dateFrom, to]) {
          const loopVariable = currentMoment.month() === dateFrom.month() ? dateFrom.date() : 1;
          let loopCondition = moment(dateFrom).endOf("month").date();

          if (dateFrom.month() === to.month() || currentMoment.month() === to.month()) {
            loopCondition = to.date();
          }

          for (let i = loopVariable; i <= loopCondition; i++) {
            if (
              timer.durations[currentMoment.year()]
              && timer.durations[currentMoment.year()][currentMoment.month()]
              && timer.durations[currentMoment.year()][currentMoment.month()][i]
            ) {
              filteredTimers.push(timer);
              pushed = true;

              break;
            }
          }

          if (pushed) {
            break;
          }
        }
      }
    }

    return filteredTimers;
  }

  public initializeTimers() {
    this.timers = this.getTimersByDateFromTo(moment());
  }

  public isActiveTimer(timer: ITimer): boolean {
    return this.activeTimer.value && timer.name === this.activeTimer.value.name;
  }

  public isTimerSyncedWithJira(timer: ITimer): void {
    const allTimerDurationData: IDurationData[] = this.getAllTimerDurationData(timer);

    if (timer.syncedWithJira !== undefined) {
      timer.syncedWithJira = !allTimerDurationData.some((durationData) => {
        return !durationData.syncedWithJira && !!durationData.duration;
      });
    }

    this.setTimer(timer);
  }

  public pinTimer(timer: ITimer) {
    timer.pinned = true;

    if (this.isActiveTimer(timer)) {
      this.localStorageService.setItem("activeTimer", timer);
      this.activeTimer.next(timer);
    }

    this.setTimer(timer);
  }

  public removeTimer(timer: ITimer) {
    const localStorageTimers: ITimers = LocalStorageService.getItem("timers");

    if (this.isActiveTimer(timer)) {
      this.stopActiveTimer();
    }
    this.unpinTimer(timer);

    delete localStorageTimers[timer.name];
    this.localStorageService.setItem("timers", localStorageTimers);

    this.timers = this.getTimersByDateFromTo(moment());
  }

  public removeTimerDuration(startTime: Moment, duration: Duration, timer: ITimer, skipSyncWithJira = false): Observable<void> {
    const index = timer.durations[startTime.year()][startTime.month()][startTime.date()].findIndex((element) => {
      return element.startTime.valueOf() === startTime.valueOf();
    });

    if (!duration.asMilliseconds()) {
      this.stopActiveTimer();
    }

    const deleteDuration = () => {
      timer.durations[startTime.year()][startTime.month()][startTime.date()].splice(index, 1);
      if (this.isActiveTimer(timer)) {
        this.setActiveTimer(timer);
      }
      const allTimerDuration: IDurationData[] = this.getAllTimerDurationData(timer);

      if (timer.durations[startTime.year()][startTime.month()][startTime.date()].length === 0) {
        delete timer.durations[startTime.year()][startTime.month()][startTime.date()];

        if (Object.keys(timer.durations[startTime.year()][startTime.month()]).length === 0) {
          delete timer.durations[startTime.year()][startTime.month()];

          if (Object.keys(timer.durations[startTime.year()]).length === 0) {
            delete timer.durations[startTime.year()];
          }
        }
      }

      if (allTimerDuration.length === 0) {
        this.unpinTimer(timer);
        this.removeTimer(timer);
      } else {
        this.setTimer(timer);
      }
    };

    if (!skipSyncWithJira && timer.durations[startTime.year()][startTime.month()][startTime.date()][index].syncedWithJira) {
      return this.jiraService.deleteIssueWorklog(
        timer.name,
        timer.durations[startTime.year()][startTime.month()][startTime.date()][index].worklogId,
      )
        .pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.status) {
              switch (error.status) {
                case 401:
                  this.snackBar.open(
                    "Melden Sie sich in Jira an!",
                    "Okay",
                    {
                      duration: 5000,
                    },
                  );
                  break;
                case 500:
                  this.snackBar.open(
                    "In Jira ist ein Fehler aufgetreten!",
                    "Okay",
                    {
                      duration: 5000,
                    },
                  );
                  break;
                default:
                  this.snackBar.open(
                    `Error: ${error.status}, Error Message: ${error.error.errorMessages[0]}`,
                    "Okay",
                  );
                  break;
              }
            }

            return throwError(error);
          }),
          filter((response: IJiraResponse) => response.status === 204),
          tap(() => {
            deleteDuration();

            this.snackBar.open(
              "Vorgang wurde gelöscht!",
              "Okay",
              {
                duration: 5000,
              },
            );
          }),
          mapTo(void 0),
        );
    } else {
      return of(void 0).pipe(tap(() => deleteDuration()));
    }
  }

  public renameTimer(timer: ITimer, newName: string): void {
    const newTimer = JSON.parse(JSON.stringify(timer));
    const localStorageTimers: ITimers = LocalStorageService.getItem("timers");
    const activeTimer: boolean = this.isActiveTimer(timer);

    newTimer.name = newName;

    localStorageTimers[newTimer.name] = newTimer;

    if (activeTimer) {
      this.stopActiveTimer();
    }

    delete localStorageTimers[timer.name];

    this.localStorageService.setItem("timers", localStorageTimers);

    if (activeTimer) {
      this.setActiveTimer(newTimer);
    }

    this.timers = this.getTimersByDateFromTo(moment());
  }

  public saveTimerDurations(timer: ITimer, changes: IEditableDurationsListChange[], date: Moment, format: string): Observable<void> {
    const newDurationData: IDurationData[] = [];
    let errorCount = 0;

    return from(changes)
      .pipe(
        tap((change) => {
          newDurationData.push({
            startTime: moment(change["startTime"], format),
            duration: moment.duration(moment(change.endTime, format).diff(moment(change.startTime, format))),
            worklogId: timer.syncedWithJira !== undefined ? change.worklogId : undefined,
            updated: moment(),
            savedLocal: timer.syncedWithJira === undefined ? true : undefined,
            activityType: change["activityType"],
          });
        }),
        skipWhile(() => timer.syncedWithJira === undefined),
        concatMap((change) => {

          return this.jiraService.updateIssueWorklog(
            timer.name,
            change.worklogId,
            {
              comment: this.getActivityTypeOrComment(change["activityType"], "comment"),
              timeSpentSeconds: moment.duration(moment(change.endTime, format).diff(moment(change.startTime, format)))
                .asSeconds(),
              started: moment(change.startTime, format).format("YYYY-MM-DDTHH:mm:ss.SSSZZ").toString(),
            },
          )
            .pipe(
              tap((response: IJiraResponse) => {
                newDurationData.find((durationData) => durationData.worklogId === change["worklogId"])["syncedWithJira"] = true;
                newDurationData.find((durationData) => durationData.worklogId === change["worklogId"])["updated"] = moment(
                  response.body.updated);
              }),
              mapTo(void 0),
              catchError((error) => {
                newDurationData.find((durationData) => durationData.worklogId === change["worklogId"])["syncedWithJira"] = false;
                timer.syncedWithJira = false;

                errorCount += 1;

                return EMPTY;
              }),
            );
        }),
        finalize(() => {
          if (date) {
            timer.durations[date.year()][date.month()][date.date()] = newDurationData;
          } else {
            const durations = {};

            for (const durationData of newDurationData) {
              if (!durations[durationData.startTime.year()]) {
                durations[durationData.startTime.year()] = {};
              }
              if (!durations[durationData.startTime.year()][durationData.startTime.month()]) {
                durations[durationData.startTime.year()][durationData.startTime.month()] = {};
              }
              if (!durations[durationData.startTime.year()][durationData.startTime.month()][durationData.startTime.date()]) {
                durations[durationData.startTime.year()][durationData.startTime.month()][durationData.startTime.date()] = [];
              }
              if (durations[durationData.startTime.year()][durationData.startTime.month()][durationData.startTime.date()]) {
                durations[durationData.startTime.year()][durationData.startTime.month()][durationData.startTime.date()]
                  .push(durationData);
              }
            }

            timer.durations = durations;
          }

          this.isTimerSyncedWithJira(timer);

          if (timer.syncedWithJira !== undefined) {
            if (errorCount > 0) {
              this.snackBar.open(
                `${errorCount} Vorgänge wurden nicht mit Jira synchronisiert`,
                "Okay",
                {
                  duration: 5000,
                },
              );
            } else {
              this.snackBar.open(
                `Vorgänge wurden mit Jira synchronisiert`,
                "Okay",
                {
                  duration: 5000,
                },
              );
            }
          }
        }),
      );
  }

  public searchTimersByName(name: string): ITimer[] {
    const allTimers: ITimer[] = TimerService.getAllTimers();

    return allTimers.filter((timer) => {
      return timer.name.toLowerCase().includes(name.toLowerCase())
        || (timer.description ? timer.description.toLowerCase().includes(name.toLowerCase()) : null);
    });
  }

  public setActiveTimer(timer: ITimer): void {
    this.stopActiveTimer();

    this.localStorageService.setItem("activeTimer", timer);

    this.setTimer(timer);

    this.activeTimer.next(timer);
  }

  public setAlarm(duration: string, timer: ITimer): void {
    const alarmDuration: Duration = DateAndTimeUtilities.getDuration(duration);

    timer.alarm = {
      startAlarmDuration: this.getTimerDuration(timer),
      endAlarmDuration: moment.duration(this.getTimerDuration(timer)).add(alarmDuration),
    };

    if (this.isActiveTimer(timer)) {
      this.activeTimer.next(timer);
    }

    this.setTimer(timer);
  }

  public setBeginningOfDay(): void {
    const todayTimers = this.getTimersByDateFromTo(moment());

    if (todayTimers.length > 0) {
      let min = moment();
      for (const timer of todayTimers) {
        const startTimes = [];
        const timerDurationDatas: IDurationData[] = this.getTimerDurationDataByDateFromTo(timer, moment());
        for (const timerDurationData of timerDurationDatas) {
          startTimes.push(timerDurationData.startTime);
        }
        min = moment.min(min, moment.min(startTimes));
      }
      this.beginningOfDay = min.format("HH:mm");
    } else {
      this.beginningOfDay = "";
    }
  }

  public setTimer(timer: ITimer): void {
    const localStorageTimers: ITimers = LocalStorageService.getItem("timers");

    localStorageTimers[timer.name] = timer;

    this.localStorageService.setItem("timers", localStorageTimers);

    this.timers = this.getTimersByDateFromTo(moment());
  }

  public sortDurationsData(durationsData: IDurationData[]): IDurationData[] {
    return durationsData.sort((durationDataA, durationDataB) => {
      return durationDataA.startTime.valueOf() - durationDataB.startTime.valueOf();
    });
  }

  public sortTodayTimers(sortDirection: string, timers: ITimer[]): ITimer[] {
    const currentDate = moment();
    const year = currentDate.year();
    const month = currentDate.month();
    const date = currentDate.date();

    return timers.sort((timerA, timerB) => {
      let sortResult: number;

      if (sortDirection === "descending") {
        sortResult = timerB.durations[year][month][date][0].startTime.valueOf() - timerA.durations[year][month][date][0].startTime.valueOf();
      } else {
        sortResult = timerA.durations[year][month][date][0].startTime.valueOf() - timerB.durations[year][month][date][0].startTime.valueOf();
      }

      return sortResult;
    });
  }

  public stopActiveTimer(stopTime?: Moment, autoStop?: boolean): void {
    const activeTimer = this.activeTimer.getValue();
    const currentDate = stopTime ? stopTime : moment.utc();

    if (activeTimer) {
      const activeTimerDurations = activeTimer.durations[currentDate.year()][currentDate.month()][currentDate.date()];

      const activeTimerDurationIndex = activeTimerDurations.findIndex((time) => {
        return !time.duration;
      });

      if (activeTimerDurationIndex !== -1) {
        let duration: Duration;
        const endTime = stopTime ? stopTime.utc() : moment.utc();

        duration = moment.duration(endTime.diff(activeTimerDurations[activeTimerDurationIndex].startTime));

        if (
          duration.minutes() > 0
          || duration.hours() > 0
        ) {
          const getDurationConfig = (setMinutesToOne = false) => {
            return {
              seconds: 0,
              minutes: setMinutesToOne ? duration.minutes() + 1 : duration.minutes(),
              hours: duration.hours(),
              days: duration.days(),
              weeks: duration.weeks(),
              months: duration.months(),
              years: duration.years(),
              milliseconds: 0,
            };
          };

          if (
            !stopTime
            || (stopTime.hours() !== 23 && stopTime.minutes() !== 59)
          ) {
            if (duration.seconds() <= 30) {
              duration = moment.duration({ ...getDurationConfig() });
            } else if (duration.seconds() >= 31) {
              duration = moment.duration({ ...getDurationConfig(true) });
            }
          } else {
            duration = moment.duration({ ...getDurationConfig() });
          }

          activeTimerDurations[activeTimerDurationIndex].duration = duration;
          activeTimerDurations[activeTimerDurationIndex].updated = endTime;
          activeTimerDurations[activeTimerDurationIndex].savedLocal = activeTimer.syncedWithJira === undefined ? true : undefined;

          this.setTimer(activeTimer);
          this.activeTimer.next(null);
          this.localStorageService.removeItem("activeTimer");
          this.activeTimerDuration = of("");

          const stoppedTimer: ITimer = LocalStorageService.getItem("timers")[activeTimer.name];

          if (stoppedTimer.syncedWithJira != null) {
            this.addTimerWorklogToJira(stoppedTimer, currentDate, duration.asSeconds(), autoStop);
          }
        } else {
          const allActiveTimerDurations: IDurationData[] = this.getAllTimerDurationData(activeTimer);

          if (allActiveTimerDurations.length <= 1) {
            this.activeTimer.next(null);
            this.localStorageService.removeItem("activeTimer");
            this.removeTimer(activeTimer);
            this.totalTodayDurations.next(this.calculateTotalRunningTimeTodayTimers(this.timers));
          } else {
            activeTimerDurations.splice(activeTimerDurationIndex, 1);

            if (activeTimer.durations[currentDate.year()][currentDate.month()][currentDate.date()].length === 0) {
              delete activeTimer.durations[currentDate.year()][currentDate.month()][currentDate.date()];

              if (Object.keys(activeTimer.durations[currentDate.year()][currentDate.month()]).length === 0) {
                delete activeTimer.durations[currentDate.year()][currentDate.month()];

                if (Object.keys(activeTimer.durations[currentDate.year()]).length === 0) {
                  delete activeTimer.durations[currentDate.year()];
                }
              }
            }
            this.setTimer(activeTimer);
            this.activeTimer.next(null);
            this.localStorageService.removeItem("activeTimer");
            this.activeTimerDuration = of("");
          }
        }
      }
    }
  }

  public syncDurationWithJira(
    timer: ITimer,
    startTime: Moment,
    duration: Duration,
    index: number = null,
    issueResponse?: IJiraIssue,
    durationData?: IDurationData,
    showSnackbar: boolean = true,
  ): Observable<void> {
    index = index !== null ? index : timer.durations[startTime.year()][startTime.month()][startTime.date()].findIndex(
      (durationDataWithIndex) => durationDataWithIndex === durationData,
    );

    const timerDurationData = timer.durations[startTime.year()][startTime.month()][startTime.date()][index];

    if (duration) {
      const updateIssueWorklog$: Observable<void> = this.jiraService.updateIssueWorklog(
        timer.name,
        durationData ? durationData.worklogId : timerDurationData.worklogId,
        {
          comment: this.getActivityTypeOrComment(durationData?.activityType || timerDurationData?.activityType, "comment"),
          timeSpentSeconds: duration.asSeconds(),
          started: startTime.format("YYYY-MM-DDTHH:mm:ss.SSSZZ").toString(),
        },
      )
        .pipe(
          map((response: IJiraResponse) => {
            timer.durations[startTime.year()][startTime.month()][startTime.date()][index].syncedWithJira = true;
            timer.durations[startTime.year()][startTime.month()][startTime.date()][index].updated = moment(response.body.updated);
            if (issueResponse) {
              timer.description = issueResponse.fields.summary;
            }

            this.isTimerSyncedWithJira(timer);

            if (showSnackbar) {
              this.snackBar.open(
                "Vorgang wurde mit Jira synchronisiert",
                "Okay",
                {
                  duration: 5000,
                },
              );
            }
          }),
          catchError((error) => {
            if (error.status) {
              switch (error.status) {
                case 401:
                  this.snackBar.open(
                    "Melden Sie sich in Jira an!",
                    "Okay",
                    {
                      duration: 5000,
                    },
                  );
                  break;
                case 500:
                  this.snackBar.open(
                    "In Jira ist ein Fehler aufgetreten!",
                    "Okay",
                    {
                      duration: 5000,
                    },
                  );
                  break;
                default:
                  this.snackBar.open(
                    `Error: ${error.status}, Error Message: ${error.error.errorMessages[0]}`,
                    "Okay",
                  );
                  break;
              }

              timer.durations[startTime.year()][startTime.month()][startTime.date()][index].syncedWithJira = false;
              timer.durations[startTime.year()][startTime.month()][startTime.date()][index].updated = moment();
              timer.syncedWithJira = false;
              this.setTimer(timer);
            }

            return of(null);
          }),
        );

      const addIssueWorklog$: Observable<void> = this.jiraService.addIssueWorklog(
        timer.name,
        {
          comment: this.getActivityTypeOrComment(durationData?.activityType || timerDurationData?.activityType, "comment"),
          timeSpentSeconds: duration.asSeconds(),
          started: startTime.format("YYYY-MM-DDTHH:mm:ss.SSSZZ").toString(),
        },
      )
        .pipe(
          map((response: IJiraResponse) => {
            if (response.status === 201) {
              timer.durations[startTime.year()][startTime.month()][startTime.date()][index].worklogId = response.body.id;
              timer.durations[startTime.year()][startTime.month()][startTime.date()][index].syncedWithJira = true;
              if (timer.durations[startTime.year()][startTime.month()][startTime.date()][index].savedLocal) {
                delete timer.durations[startTime.year()][startTime.month()][startTime.date()][index].savedLocal;
              }
              timer.issueId = response.body.issueId;
              if (issueResponse) {
                timer.description = issueResponse.fields.summary;
              }

              this.isTimerSyncedWithJira(timer);

              if (showSnackbar) {
                this.snackBar.open(
                  "Vorgang wurde mit Jira synchronisiert",
                  "Okay",
                  {
                    duration: 5000,
                  },
                );
              }
            }
          }),
          catchError((error) => {
            if (error.status) {
              switch (error.status) {
                case 401:
                  this.snackBar.open(
                    "Melden Sie sich in Jira an!",
                    "Okay",
                    {
                      duration: 5000,
                    },
                  );
                  break;
                case 500:
                  this.snackBar.open(
                    "In Jira ist ein Fehler aufgetreten!",
                    "Okay",
                    {
                      duration: 5000,
                    },
                  );
                  break;
                default:
                  this.snackBar.open(
                    `Error: ${error.status}, Error Message: ${error.error.errorMessages[0]}`,
                    "Okay",
                  );
                  break;
              }
            }

            timer.durations[startTime.year()][startTime.month()][startTime.date()][index].syncedWithJira = false;
            timer.durations[startTime.year()][startTime.month()][startTime.date()][index].updated = moment();
            timer.syncedWithJira = false;
            if (timer.durations[startTime.year()][startTime.month()][startTime.date()][index].savedLocal) {
              delete timer.durations[startTime.year()][startTime.month()][startTime.date()][index].savedLocal;
            }
            this.isTimerSyncedWithJira(timer);

            return of(null);
          }),
        );

      return iif(
        () => (
          !!(durationData && durationData.worklogId)
          || !!(timerDurationData && timerDurationData.worklogId)
        ),
        updateIssueWorklog$,
        addIssueWorklog$,
      );
    } else {
      return of(void 0);
    }
  }

  public syncJiraWorklogs(name: string): Observable<void> {
    return forkJoin([
      this.jiraService.getIssue(name),
      this.jiraService.getIssueWorklogs(name),
    ])
      .pipe(
        switchMap(([issueResponse, worklogResponse]: [IJiraIssue, IJiraWorklogResponse]) => {
          const worklogs = worklogResponse.worklogs.filter((worklog) => worklog.author.accountId === this.jiraAccountId);

          if (worklogs.length > 0) {
            const durations: IDurationData[] = [];

            for (const worklog of worklogs) {
              durations.push(
                {
                  startTime: moment(worklog.started),
                  duration: moment.duration(worklog.timeSpentSeconds, "seconds"),
                  worklogId: worklog.id,
                  syncedWithJira: true,
                  updated: moment(worklog.updated),
                  activityType: this.getActivityTypeOrComment(worklog.comment, "activityType"),
                },
              );
            }
            const durationData = {};

            for (const duration of durations) {
              if (!durationData[duration.startTime.year()]) {
                durationData[duration.startTime.year()] = {};
              }
              if (!durationData[duration.startTime.year()][duration.startTime.month()]) {
                durationData[duration.startTime.year()][duration.startTime.month()] = {};
              }
              if (!durationData[duration.startTime.year()][duration.startTime.month()][duration.startTime.date()]) {
                durationData[duration.startTime.year()][duration.startTime.month()][duration.startTime.date()] = [duration];
              } else {
                durationData[duration.startTime.year()][duration.startTime.month()][duration.startTime.date()].push(duration);
              }
            }

            const timer: ITimer = {
              name: name,
              durations: durationData,
              issueId: worklogs[0].issueId,
              syncedWithJira: true,
              description: issueResponse.fields.summary,
              pinned: false,
            };

            this.addTimerToLocalStorage(timer);

            return of(void 0);
          } else {
            const timer: ITimer = {
              name: name,
              durations: {},
              issueId: issueResponse.id,
              syncedWithJira: true,
              description: issueResponse.fields.summary,
              pinned: false,
            };

            this.addTimerToLocalStorage(timer);

            return of(void 0);
          }
        }),
        catchError((error) => throwError(error)),
      );
  }

  public syncSelectedDurationsWithJira(timer: ITimer, timerDurationDatas: IDurationData[]): Observable<void> {
    return from(timerDurationDatas)
      .pipe(
        concatMap((timerDurationData) => {
          return this.syncDurationWithJira(
            timer,
            timerDurationData.startTime,
            timerDurationData.duration,
            null,
            null,
            timerDurationData,
            false,
          );
        }),
      );
  }

  public syncTimerWithJira(timer: ITimer): Observable<void> {
    let occurredError;

    return forkJoin([
      this.jiraService.getIssue(timer.name),
      this.jiraService.getIssueWorklogs(timer.name),
    ])
      .pipe(
        map(([issueResponse, worklogResponse]: [IJiraIssue, IJiraWorklogResponse]) => {
          return [issueResponse, worklogResponse.worklogs.filter((worklog) => worklog.author.accountId === this.jiraAccountId)];
        }),
        switchMap(([issueResponse, jiraWorklogs]: [IJiraIssue, IJiraWorklog[]]) => {
          if (!!jiraWorklogs.length) {
            return concat(
              this.compareJiraWorklogsAndDurationData(jiraWorklogs, issueResponse, timer),
              this.addWorklogToJiraOrDeleteWorklogLocal(timer, jiraWorklogs, issueResponse),
            );
          } else {
            return this.addWorklogToJiraOrDeleteWorklogLocal(timer, jiraWorklogs, issueResponse);
          }
        }),
        catchError((error) => {
          occurredError = error;

          return throwError(error);
        }),
      );
  }

  public unpinTimer(timer: ITimer): void {
    timer.pinned = false;

    if (this.isActiveTimer(timer)) {
      this.localStorageService.setItem("activeTimer", timer);
      this.activeTimer.next(timer);
    }

    this.setTimer(timer);
  }
}
