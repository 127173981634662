import { Component, EventEmitter, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CustomValidators } from "../custom-validators";
import { IMMEDIATE_ERROR_STATE_MATCHER } from "../immediate-error-state-matcher";
import { TimerService } from "../services/timer.service";
import { catchError, takeUntil, tap } from "rxjs/operators";
import { throwError } from "rxjs";

interface ITimerNameForm {
  name: FormControl<string>;
}

@Component({
  selector: "nx-edit-timer-name-dialog",
  templateUrl: "./edit-timer-name-dialog.component.html",
  styleUrls: ["./edit-timer-name-dialog.component.scss"],
})
export class EditTimerNameDialogComponent implements OnInit, OnDestroy {
  public immediateMatcher = IMMEDIATE_ERROR_STATE_MATCHER;
  public newName: string;
  public timerNameForm: FormGroup<ITimerNameForm>;
  private _destroyEmitter = new EventEmitter<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public formBuilder: FormBuilder,
    public timerService: TimerService,
    public dialogRef: MatDialogRef<EditTimerNameDialogComponent>,
    private _snackBar: MatSnackBar,
  ) {
    this.timerNameForm = this.formBuilder.group(
      {
        "name": ["", [Validators.required, CustomValidators.validateName]],
      },
    );
  }

  ngOnInit() {
    this.timerNameForm.valueChanges
      .pipe(
        takeUntil(this._destroyEmitter),
        tap((changes) => this.newName = changes["name"]),
      ).subscribe();
  }

  public saveTimerName() {
    this.timerService.renameTimer(this.data["timer"], this.newName);

    const renamedTimer = this.timerService.getTimerByName(this.newName);

    this.timerService.syncTimerWithJira(renamedTimer)
      .pipe(
        takeUntil(this._destroyEmitter),
        catchError((error) => {
          if (error.status) {
            switch (error.status) {
              case 401:
                this._snackBar.open(
                  "Melden Sie sich in Jira an!",
                  "Okay",
                  {
                    duration: 5000,
                  },
                );
                renamedTimer.syncedWithJira = false;
                this.timerService.setTimer(renamedTimer);
                break;
              case 500:
                this._snackBar.open(
                  "In Jira ist ein Fehler aufgetreten!",
                  "Okay",
                  {
                    duration: 5000,
                  },
                );
                renamedTimer.syncedWithJira = false;
                this.timerService.setTimer(renamedTimer);
                break;
              case 404:
                this._snackBar.open(
                  "Der Timer ist lokal umbenannt!",
                  "Okay",
                  {
                    duration: 5000,
                  },
                );
                break;
              default:
                this._snackBar.open(
                  `Error: ${error.status}, Error Message: ${error.error.errorMessages[0]}`,
                  "Okay",
                );
                renamedTimer.syncedWithJira = false;
                this.timerService.setTimer(renamedTimer);
                break;
            }
          }

          return throwError(error);
        }),
      )
      .subscribe();

    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this._destroyEmitter.next();
  }
}
