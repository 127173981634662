import { Component, Inject } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { map } from "rxjs/operators";
import { IMMEDIATE_ERROR_STATE_MATCHER } from "../immediate-error-state-matcher";
import { SettingsService } from "../services/settings.service";
import { ITimerWithStandardType } from "../settings/timer-with-standard-type.interface";
import { TimerService } from "../services/timer.service";
import { ITimer } from "../timer/timer.interface";

interface IStandardTypeForm {
  name: FormControl<string>;
  type: FormControl<string>;
}

@Component({
  selector: "nx-add-standard-type-dialog",
  templateUrl: "./add-standard-type-dialog.component.html",
  styleUrls: ["./add-standard-type-dialog.component.scss"],
})
export class AddStandardTypeDialogComponent {
  public immediateMatcher = IMMEDIATE_ERROR_STATE_MATCHER;
  public standardTypeForm: FormGroup<IStandardTypeForm>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private _formBuilder: FormBuilder,
    public timerService: TimerService,
    private _dialogRef: MatDialogRef<AddStandardTypeDialogComponent>,
    private _settingsService: SettingsService,
  ) {
    this.standardTypeForm = _formBuilder.nonNullable.group({
      "name": ["", [Validators.required, this.validateTimerName]],
      "type": [timerService.activityTypes[0]],
    });

    this.timerService.filteredTimersForAutocomplete$ = this.standardTypeForm.controls.name.valueChanges
      .pipe(
        map((typedName) => this.timerService.filterTimers(typedName)),
      );
  }

  public saveStandardType() {
    const timersWithStandardType: ITimerWithStandardType[] = this._settingsService.getSetting("standardType", "timersWithStandardType");
    const index = timersWithStandardType.findIndex((timer) => timer.name === this.standardTypeForm.controls.name.value);

    if (index !== -1) {
      timersWithStandardType.splice(index, 1);
    }

    timersWithStandardType.push(this.standardTypeForm.getRawValue());
    this._settingsService.saveSetting(timersWithStandardType, "timersWithStandardType", "standardType");
    this._dialogRef.close();
  }

  public validateTimerName(control: FormControl<string>): ValidationErrors {
    let validationError = null;
    const allTimers: ITimer[] = TimerService.getAllTimers();

    if (!allTimers.find((timer) => timer.name === control.value)) {
      return validationError = { "notExistingTimer": true };
    }

    return validationError;
  }
}
