import * as moment from "moment";
import { Duration } from "moment";

export class DateAndTimeUtilities {
  public static getDuration(duration: string): Duration {
    let timerDuration: Duration;

    if (duration) {
      const durationMatch = duration.match(/(\d+\w)/g);

      if (durationMatch) {
        let hours: Duration;
        let minutes: Duration;

        if (durationMatch.length > 1) {
          hours = moment.duration(parseInt(durationMatch[0], 10), "hours");
          minutes = moment.duration(parseInt(durationMatch[1], 10), "minutes");
          timerDuration = hours.add(minutes);
        } else {
          if (duration.endsWith("m")) {
            timerDuration = moment.duration(parseInt(durationMatch[0], 10), "minutes");
          } else if (duration.endsWith("h")) {
            timerDuration = moment.duration(parseInt(durationMatch[0], 10), "hours");
          } else {
            return null;
          }
        }

        return timerDuration;
      } else {
        return null;
      }
    }
  }
}
