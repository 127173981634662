import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { SettingsService } from "../services/settings.service";
import { TimerService } from "../services/timer.service";
import { ITimer } from "../timer/timer.interface";

@Component({
  selector: "nx-timers-list",
  templateUrl: "./timers-list.component.html",
  styleUrls: ["./timers-list.component.scss"],
})
export class TimersListComponent implements OnChanges {
  @Input() public readonly = false;
  @Input() public sortTimers = false;
  public sortedTimers: ITimer[];
  @Input() public timers: ITimer[] = [];

  constructor(
    public timerService: TimerService,
    private _settingsService: SettingsService,
  ) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.sortTimers) {
      this.sortedTimers = this.timerService.sortTodayTimers(this._settingsService.getSetting("todayTimers", "sort"), this.timers);
    } else {
      this.sortedTimers = this.timers;
    }
  }

  public trackByTimerName(index, item: ITimer) {
    return item.name && index;
  }
}
