import { EventEmitter, Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from "@angular/material/snack-bar";
import * as moment from "moment";
import { EMPTY, timer } from "rxjs";
import { filter, switchMap, take, takeUntil, tap } from "rxjs/operators";
import { NotificationService } from "./notification.service";
import { ISettingsChanges } from "../settings-changes.interface";
import { SettingsService } from "./settings.service";
import { StartTimerDialogComponent } from "../start-timer-dialog/start-timer-dialog.component";
import { TimerService } from "./timer.service";

@Injectable({
  providedIn: "root",
})
export class IdleService {
  public idleSnackBar: MatSnackBarRef<SimpleSnackBar>;
  public _stopIdleTimer$ = new EventEmitter<void>();

  constructor(
    public timerService: TimerService,
    public snackBar: MatSnackBar,
    public settingsService: SettingsService,
    public notificationService: NotificationService,
    public dialog: MatDialog,
  ) {
    if (this.settingsService.settings$.value["idle"]["idleTimeout"]["active"]) {
      this.startIdleTimer(this.settingsService.settings$.value["idle"]["idleTimeout"]["timeoutValue"]);
    }

    this.settingsService.settingChange$.subscribe((settings: ISettingsChanges) => {
      if (
        settings["idle"]
        && settings["idle"]["idleTimeout"]
        && settings["idle"]["idleTimeout"]["active"]
      ) {
        if (settings["idle"]["idleTimeout"]["active"].current) {
          this.startIdleTimer(this.settingsService.settings$.value["idle"]["idleTimeout"]["timeoutValue"]);
        } else {
          this._stopIdleTimer$.next();
        }
      }

      if (
        (settings["idle"]
          && settings["idle"]["idleTimeout"]
          && settings["idle"]["idleTimeout"]["timeoutValue"])
        || (settings["idle"]
          && settings["idle"]["idleTimeout"]
          && settings["idle"]["idleTimeout"]["repeat"]?.current)
      ) {
        this.startIdleTimer(this.settingsService.settings$.value["idle"]["idleTimeout"]["timeoutValue"]);
      }
    });
  }

  public openIdleSnackBarAfterDialogClosed(skipNotification = false): void {
    this.idleSnackBar = this.snackBar.open("Oops! Vergessen einen Timer zu starten?", "Ja");

    if (!skipNotification) {
      this.notificationService.sendNotification(
        "Oops! Vergessen einen Timer zu starten?",
        {
          requireInteraction: true,
          data: {
            notificationName: "idleTimer",
          },
        },
      );
    }

    this.idleSnackBar.onAction()
      .pipe(
        takeUntil(this._stopIdleTimer$),
        take(1),
        switchMap(() => {
          return this.dialog.open(StartTimerDialogComponent, {
            data: {
              title: "Starten Sie einen Timer",
              startTime: moment(),
              idleTime: this.settingsService.settings$.value["idle"]["idleTimeout"]["timeoutValue"],
            },
          }).beforeClosed();
        }),
        filter((result) => (result !== "saved")),
        tap(() => {
          this.openIdleSnackBarAfterDialogClosed(true);
        }),
      )
      .subscribe();
  }

  public startIdleTimer(time: number = this.settingsService.settings$.value["idle"]["idleTimeout"]["timeoutValue"]): void {
    this._stopIdleTimer$.next();

    this.timerService.activeTimer
      .pipe(
        takeUntil(this._stopIdleTimer$),
        switchMap((activeTimer) => {
          if (!!activeTimer) {
            this.idleSnackBar?.dismiss();

            return EMPTY;
          } else {
            return timer(time * 60000).pipe(
              takeUntil(this._stopIdleTimer$),
              take(1),
              switchMap(() => {
                return this.timerService.activeTimer.pipe(
                  take(1),
                  switchMap((activeTimer) => {
                    if (!activeTimer) {
                      if (!this.idleSnackBar || this.settingsService.settings$.value["idle"]["idleTimeout"]["repeat"]) {
                        this.openIdleSnackBarAfterDialogClosed();
                      }

                      if (this.settingsService.settings$.value["idle"]["idleTimeout"]["repeat"]) {
                        this.timerService.activeTimer.next(this.timerService.activeTimer.value);
                      }

                      return EMPTY;
                    } else {
                      this._stopIdleTimer$.next();

                      return EMPTY;
                    }
                  }),
                );
              }),
            );
          }
        }),
      )
      .subscribe();
  }
}
