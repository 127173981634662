import { OverlayContainer } from "@angular/cdk/overlay";
import { AfterViewInit, Component, EventEmitter, OnDestroy, Renderer2, ViewChild } from "@angular/core";
import { MatButtonToggleChange } from "@angular/material/button-toggle";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { takeUntil, tap } from "rxjs/operators";
import { AddStandardTypeDialogComponent } from "../add-standard-type-dialog/add-standard-type-dialog.component";
import { LocalStorageService } from "../services/local-storage.service";
import { SettingsService } from "../services/settings.service";
import { TimerService } from "../services/timer.service";
import { ITimerWithStandardType } from "./timer-with-standard-type.interface";

@Component({
  selector: "nx-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent implements AfterViewInit, OnDestroy {
  public columns: string[] = ["name", "type", "deleteButton"];
  public dataSource = new MatTableDataSource<ITimerWithStandardType>(this.settingsService.getSetting("standardType", "timersWithStandardType"));
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  private _destroyEmitter = new EventEmitter<void>();

  constructor(
    public settingsService: SettingsService,
    public localStorageService: LocalStorageService,
    public timerService: TimerService,
    private _overlayContainer: OverlayContainer,
    private _renderer: Renderer2,
    private _dialog: MatDialog,
  ) {
    this.localStorageService.localStorageChange$
      .pipe(
        takeUntil(this._destroyEmitter),
        tap(() => this.dataSource.data = this.settingsService.getSetting("standardType", "timersWithStandardType")),
      )
      .subscribe();
  }

  public changeTheme(event: MatButtonToggleChange): void {
    const theme = LocalStorageService.getItem("settings")["theme"]["theme"];
    this._overlayContainer.getContainerElement().classList.remove(theme);
    this._renderer.removeClass(document.body, theme);

    this._renderer.addClass(document.body, event.value);
    this._overlayContainer.getContainerElement().classList.add(event.value);
    this.settingsService.saveSetting(event.value, "theme", "theme");
  }

  public ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  public openAddStandardTypeDialog(): void {
    this._dialog.open(AddStandardTypeDialogComponent, {
      data: {
        title: "Fügen Sie einen Timer mit standard Einstellung hinzu",
      },
    });
  }

  public removeStandardType(timer: ITimerWithStandardType): void {
    const timersWithStandardType: ITimerWithStandardType[] = this.settingsService.getSetting("standardType", "timersWithStandardType");
    const index = timersWithStandardType.findIndex((timerWithStandardType) => timerWithStandardType.name === timer.name);

    if (index !== -1) {
      timersWithStandardType.splice(index, 1);
    }

    this.settingsService.saveSetting(timersWithStandardType, "timersWithStandardType", "standardType");
  }

  public repeatNotification(event: MatCheckboxChange): void {
    this.settingsService.saveSetting(event.checked, "repeat", "idle", "idleTimeout");
  }

  ngOnDestroy(): void {
    this._destroyEmitter.next();
  }
}
