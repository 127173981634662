<div class="sprint-container">
  <div *ngFor="let sprint of sprintList$ | async">
    <ng-template *ngTemplateOutlet="sprintTemplateRef; context: { $implicit: sprint }"></ng-template>

    <ng-template #sprintTemplateRef let-issue>

      <mat-expansion-panel [hideToggle]="true" #expansionPanel [disabled]="expansionPanelDisabled">
        <mat-expansion-panel-header
          collapsedHeight="75px"
          class="timer-expansion-panel"
        >
          <mat-card appearance="outlined">
            <mat-card-header>
              <img
                mat-card-avatar
                [src]="issue.fields.issuetype.iconUrl" width="100%"
              />
              <mat-card-title>
                <a [attr.href]="environment.jiraDomain + '/browse/' + issue.key"
                  style="color: white; text-decoration: none;">
                  {{ issue.key }}
                </a>
              </mat-card-title>
              <mat-card-subtitle>
                <div>
                  {{ issue.fields.summary }}
                </div>
              </mat-card-subtitle>
            </mat-card-header>

            <mat-card-content>
              <p *ngIf="issue.fields.status" class="issue-status">
                {{ issue.fields.status.name }}
              </p>

              <div class="assignee-image-container">
                <img
                  *ngIf="issue.fields.assignee"
                  [matTooltip]="issue.fields.assignee.displayName"
                  [src]="issue.fields.assignee.avatarUrls['32x32']"
                  alt="avatar"
                >
              </div>

            </mat-card-content>

            <div class="timer-buttons">
              <button
                mat-mini-fab
                color="primary"
                class="play-button"
                (click)="startTimerFromSprintList(issue)"
                [routerLink]="'/today'"
              >
                <mat-icon>play_arrow</mat-icon>
              </button>
            </div>
          </mat-card>
        </mat-expansion-panel-header>
        <div class="arrow-down-panel" [style.visibility]="!expansionPanel.expanded ? 'visible' : 'hidden'">
          <mat-icon (click)="expansionPanel.open()">expand_more</mat-icon>
        </div>

        <ng-template matExpansionPanelContent>
          <div *ngIf="issue.fields.subtasks && issue.fields.subtasks.length > 0" class="subTask">
            <ng-template ngFor let-subtask [ngForOf]="issue.fields.subtasks">
              <ng-template *ngTemplateOutlet="sprintTemplateRef; context: { $implicit: subtask }"></ng-template>
            </ng-template>
          </div>

          <div>
            <mat-tab-group animationDuration="0ms" mat-stretch-tabs>
              <mat-tab label="Task">
                <ng-template mat-tab-label>
                  <mat-icon>task_alt</mat-icon>
                </ng-template>
                <nx-task [issueKey]="issue.key"></nx-task>
              </mat-tab>
            </mat-tab-group>
          </div>

          <div class="arrow-up-panel" *ngIf="expansionPanel.expanded">
            <mat-icon (click)="expansionPanel.close()">expand_less</mat-icon>
          </div>
        </ng-template>

      </mat-expansion-panel>

    </ng-template>
  </div>
</div>
