import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component } from "@angular/core";
import * as moment from "moment";
import { EditableDurationsListService } from "../editable-durations-list/editable-durations-list.service";
import { LocalStorageService } from "../services/local-storage.service";
import { SettingsService } from "../services/settings.service";
import { TimerService } from "../services/timer.service";
import { JiraService } from "../services/jira.service";

@Component({
  selector: "nx-bar-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.scss"],
  animations: [
    trigger("openCollapse", [
      state("opened", style({ width: "300px" })),
      state("collapsed", style({ width: "0px", display: "none" })),
      transition("opened => collapsed", [animate("500ms cubic-bezier(0.35, 0, 0.25, 1)")]),
      transition("collapsed => opened", [animate("500ms cubic-bezier(0.35, 0, 0.25, 1)")]),
    ]),
  ],
})
export class NavigationComponent {
  public openExtendableInformationPanel = LocalStorageService.getItem("settings")["informationPanel"]["panelExpanded"];

  constructor(
    public timerService: TimerService,
    public localStorageService: LocalStorageService,
    public settingsService: SettingsService,
    public editableDurationsListService: EditableDurationsListService,
    public jiraService: JiraService,
  ) {
  }

  public pauseTimer() {
    this.timerService.stopActiveTimer(moment());
  }

  public toggleExtendableInformationPanel() {
    this.openExtendableInformationPanel = !this.openExtendableInformationPanel;
    this.settingsService.saveSetting(this.openExtendableInformationPanel, "panelExpanded", "informationPanel");
  }
}

