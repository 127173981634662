<nx-dialog [dialogData]="data">
  <form [formGroup]="standardTypeForm" class="dialog-content">
    <mat-form-field appearance="outline">
      <input
        matInput
        placeholder="Name"
        [formControlName]="'name'"
        maxlength="100"
        [matAutocomplete]="auto"
        #name
        autocomplete="off"
        [errorStateMatcher]="immediateMatcher"
      >
      <mat-error *ngIf="standardTypeForm.controls.name.hasError('notExistingTimer')">
        Timer mit solchem Namen existieret nicht!
      </mat-error>
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let timer of timerService.filteredTimersForAutocomplete$ | async" [value]="timer.name">
          <span>{{ timer.name }} {{ timer.description }}</span>
        </mat-option>
      </mat-autocomplete>
      <mat-hint align="end">{{name.value.length}} / 100</mat-hint>
    </mat-form-field>
    <mat-form-field>
      <mat-select
        placeholder="Typ"
        [disableOptionCentering]="true"
        [required]="true"
        formControlName="type"
      >
        <mat-option *ngFor="let type of timerService.activityTypes" [value]="type">
          {{ type }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
  <button
    mat-raised-button
    color="primary"
    class="dialog-actions"
    (click)="saveStandardType()"
    [disabled]="standardTypeForm.invalid"
  >
    Speichern
  </button>
  <button
    mat-raised-button
    color="warn"
    class="dialog-actions"
    mat-dialog-close="true"
  >
    Abbrechen
  </button>
</nx-dialog>
