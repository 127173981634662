<nx-dialog [dialogData]="data">
  <form [formGroup]="timerNameForm" class="dialog-content">
    <mat-form-field appearance="outline">
      <input
        matInput
        formControlName="name"
        [value]="data.timer.name"
        [errorStateMatcher]="immediateMatcher"
        autocomplete="off"
        maxlength="100"
        #name
      >
      <mat-hint align="end">{{name.value.length}} / 100</mat-hint>
      <mat-error *ngIf="timerNameForm.controls.name.hasError('nameFormatError')">
        Der Timer mit solchem Namen exsistiert schon
      </mat-error>
    </mat-form-field>
  </form>
  <button mat-raised-button color="primary" class="dialog-actions" (click)="saveTimerName()">Speichern
  </button>
  <button mat-raised-button color="warn" class="dialog-actions" mat-dialog-close="true">Abbrechen</button>
</nx-dialog>
