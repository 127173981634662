<div class="settings-container">
  <div>
    <h3>Inaktivität:</h3>
    <mat-checkbox
      [checked]="settingsService.settings$.value['idle']['idleTimeout']['active']"
      (change)="settingsService.saveSetting($event.checked, 'active', 'idle', 'idleTimeout')"
      color="primary"
      #checkbox
    >
      Inaktivitäts Benachrichtigung
    </mat-checkbox>
    <br>
    <mat-checkbox
      color="primary"
      [disabled]="!checkbox.checked"
      [checked]="settingsService.settings$.value['idle']['idleTimeout']['repeat']"
      (change)="repeatNotification($event)"
    >
      Benachrichtigung wiederholen
    </mat-checkbox>
    <br>
    <mat-slider
      [disabled]="!checkbox.checked"
      color="primary"
      [max]="60"
      [min]="1"
      [step]="1"
      #slider
     #ngSlider>
      <input matSliderThumb [value]="settingsService.settings$.value['idle']['idleTimeout']['timeoutValue']" (change)="settingsService.saveSetting({source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value}.value, 'timeoutValue', 'idle', 'idleTimeout')" #ngSliderThumb="matSliderThumb" />
    </mat-slider>
    {{ slider.value }} Min.
  </div>
  <br>
  <mat-divider></mat-divider>
  <div>
    <h3>Themen:</h3>
    <mat-button-toggle-group
      (change)="changeTheme($event)"
      [value]="settingsService.settings$.value['theme']['theme']"
    >
      <mat-button-toggle value="krya-theme">Krya</mat-button-toggle>
      <mat-button-toggle value="leek-theme">Lauch</mat-button-toggle>
      <mat-button-toggle value="accessible-theme">Barrierefrei</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <br>
  <mat-divider></mat-divider>
  <div>
    <h3>Input Typ:</h3>
    Minus Zeit
    <mat-slide-toggle
      color="primary"
      (change)="settingsService.saveSetting($event.checked ? 'startTime' : 'minusTime', 'timeInput', 'todayTimers')"
      [checked]="settingsService.settings$.value['todayTimers']['timeInput'] === 'startTime'"
      [matTooltip]="'Ändert auf der \'Today\' Seite die Eingabe zwischen 1h20m (Dauer bis jetzt) und 12:00 (Beginn der gewünschten Zeit)'"
    >
      Start Uhrzeit
    </mat-slide-toggle>
  </div>
  <br>
  <mat-divider></mat-divider>
  <div>
    <h3>Standard Timer Typ:</h3>
    <button mat-mini-fab color="primary" (click)="openAddStandardTypeDialog()">
      <mat-icon>add</mat-icon>
    </button>
    Timer hinzufügen
    <div [hidden]="settingsService.settings$.value['standardType']['timersWithStandardType'].length < 1">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let timerWithStandardType">{{ timerWithStandardType.name }}</td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>Typ</th>
          <td mat-cell *matCellDef="let timerWithStandardType">{{ timerWithStandardType.type }}</td>
        </ng-container>

        <ng-container matColumnDef="deleteButton">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let timerWithStandardType">
            <button mat-icon-button (click)="removeStandardType(timerWithStandardType)">
              <mat-icon>clear</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
      </table>

      <mat-paginator
        [pageSizeOptions]="[1, 3, 5]"
        showFirstLastButtons
        (page)="settingsService.saveSetting($event.pageSize, 'pageSize', 'standardType')"
        [pageSize]="settingsService.settings$.value['standardType']['pageSize']"
      ></mat-paginator>
    </div>
  </div>
  <br>
  <mat-divider></mat-divider>
  <div>
    <h3>Timer von heute sortieren:</h3>
    <mat-slide-toggle
      color="primary"
      (change)="settingsService.saveSetting($event.checked ? 'descending' : 'ascending', 'sort', 'todayTimers')"
      [checked]="settingsService.settings$.value['todayTimers']['sort'] === 'descending'"
    >
      <div *ngIf="settingsService.settings$.value['todayTimers']['sort'] === 'descending'">Absteigend</div>
      <div *ngIf="settingsService.settings$.value['todayTimers']['sort'] === 'ascending'">Aufsteigend</div>
    </mat-slide-toggle>
  </div>
  <br>
  <mat-divider></mat-divider>
  <div>
    <h3>Navigation:</h3>
    <mat-checkbox
      [checked]="settingsService.settings$.value['navigation']['showChips']"
      (change)="settingsService.saveSetting($event.checked, 'showChips', 'navigation')"
      color="primary"
    >
      "Heute geloggt" und "Anfang des Tages" in der Navigation zeigen
    </mat-checkbox>
  </div>
  <br>
  <mat-divider></mat-divider>
</div>
