import { Injectable, NgZone } from "@angular/core";
import { interval, Subject } from "rxjs";
import { tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class IntervalService {
  public interval$: Subject<number> = new Subject<number>();

  constructor(
    private _zone: NgZone,
  ) {
    this._zone.runOutsideAngular(() => {
      interval(1000)
        .pipe(
          tap((intervalCount) => {
            this._zone.run(() => this.interval$.next(intervalCount));
          }),
        )
        .subscribe();
    });
  }
}
