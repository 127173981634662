import { Injectable } from "@angular/core";
import * as moment from "moment";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LocalStorageService {
  public localStorageChange$ = new Subject<string>();

  static getItem(key: string): any {
    return JSON.parse(localStorage.getItem(key), LocalStorageService.parseDates);
  }

  public static parseDates(parseKey: string, value: any) {
    if (
      parseKey === "startTime"
    ) {
      return moment(value);
    } else if (
      parseKey === "duration"
      || parseKey === "startAlarmDuration"
      || parseKey === "endAlarmDuration"
    ) {
      return moment.duration(value);
    } else {
      return value;
    }
  }

  public removeItem(key: string): void {
    localStorage.removeItem(key);
    this.localStorageChange$.next(key);
  }

  public setItem(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
    this.localStorageChange$.next(key);
  }
}
