<table [dataSource]="timerEditData" mat-table>
  <ng-container matColumnDef="position">
    <th mat-header-cell *matHeaderCellDef>No.</th>
    <td mat-cell *matCellDef="let timer">{{timer.position}}</td>
  </ng-container>

  <ng-container matColumnDef="startTime">
    <th mat-header-cell *matHeaderCellDef>Startzeit</th>
    <td mat-cell *matCellDef="let timer">
      <mat-form-field>
        <input
          [formControl]="durationsForm.controls[timer.index].controls['startTime']" matInput
          [errorStateMatcher]="durationsFormErrorStateMatcher"
        >
        <mat-error
          *ngIf="durationsForm.controls[timer.index].controls['startTime'].hasError('startTimeGreaterThanEndTime')">
        </mat-error>
        <mat-error *ngIf="durationsForm.controls[timer.index].controls['startTime'].hasError('controlFormatTimeError')">
          Zeitformat: HH:mm
        </mat-error>
        <mat-error
          *ngIf="durationsForm.controls[timer.index].controls['startTime'].hasError('controlFormatDateTimeError')">
          Format:DD.MM.YYYY HH:mm
        </mat-error>
      </mat-form-field>
    </td>
  </ng-container>

  <ng-container matColumnDef="endTime">
    <th mat-header-cell *matHeaderCellDef>Endzeit</th>
    <td mat-cell *matCellDef="let timer">
      <mat-form-field>
        <input
          [formControl]="durationsForm.controls[timer.index].controls['endTime']"
          matInput
          [errorStateMatcher]="durationsFormErrorStateMatcher"
        >
        <mat-error
          *ngIf="durationsForm.controls[timer.index].controls['endTime'].hasError('previousEndTimeGreaterThanStartTime')"
        >
        </mat-error>
        <mat-error
          *ngIf="durationsForm.controls[timer.index].controls['endTime'].hasError('startTimeGreaterThanEndTime')">
        </mat-error>
        <mat-error *ngIf="durationsForm.controls[timer.index].controls['endTime'].hasError('controlFormatTimeError')">
          Zeitformat: HH:mm
        </mat-error>
        <mat-error
          *ngIf="durationsForm.controls[timer.index].controls['endTime'].hasError('controlFormatDateTimeError')">
          Format:DD.MM.YYYY HH:mm
        </mat-error>
      </mat-form-field>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"
    [ngClass]="{ 'highlight': row.position === highlightPosition }"></tr>
</table>

<mat-error *ngIf="durationsForm.hasError('previousEndTimeGreaterThanStartTime')">
  Endzeit darf nicht größer als nachfolgende Startzeit sein
</mat-error>
<mat-error *ngIf="durationsForm.hasError('startTimeGreaterThanEndTime')">
  Startzeit darf nicht größer als Endzeit sein
</mat-error>
<mat-error *ngIf="durationsForm.hasError('dateOutOfRange')">
  Timer darf nur innerhalb eines Tages laufen!
</mat-error>
<mat-error *ngIf="durationsForm.hasError('dateIsInFuture')">
  Timer darf nicht in der Zukunft erstellt werden!
</mat-error>
