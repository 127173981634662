<nx-dialog [dialogData]="data">
  <form [formGroup]="startTimerForm" class="dialog-content">
    <mat-form-field class="selectedActivityType">
      <mat-select
        placeholder="Typ" [disableOptionCentering]="true"
        (selectionChange)="getTypeValue($event)"
        [(value)]="newActivityType"
      >
        <mat-option *ngFor="let type of timerService.activityTypes" [value]="type">
          {{type}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      Timer Name:<input matInput formControlName="timerName" #timerName [errorStateMatcher]="immediateMatcher"
      autocomplete="off">
    </mat-form-field>
    <mat-form-field appearance="outline">
      Inaktiv seit:<input
      matInput formControlName="timerStartTime" autocomplete="off" #timerStartTime
      [errorStateMatcher]="immediateMatcher"
      maxlength="5"
    >
      <mat-error *ngIf="startTimerForm.controls.timerStartTime.hasError('timeFormatError')">Zeitformat: HH:MM
      </mat-error>
      <mat-error *ngIf="startTimerForm.controls.timerStartTime.hasError('foo')">foo</mat-error>
    </mat-form-field>


    <mat-radio-group *ngIf="radioButtonsVisibility" formControlName="radioButtonGroup">
      <mat-radio-button color="primary" [value]="'method1'">
        <img
          src="assets/images/radio-button-method-1.svg"
          matTooltip="Die Anfangszeit von diesem Timer wird auf die Endzeit von vorherigem Timer gesetzt"
        >
      </mat-radio-button>
      <mat-radio-button color="primary" [value]="'method2'">
        <img
          src="assets/images/radio-button-method-2.svg"
          matTooltip="Die Endzeit von vorherigem Timer wird auf die Anfangszeit von deisem Timer gesetzt"
        >
      </mat-radio-button>
    </mat-radio-group>
  </form>
  <div class="dialog-actions">
    <button
      [disabled]="startTimerForm.invalid" mat-raised-button color="primary"
      (click)="startTimer(timerName.value, timerStartTime.value)"
    >Speichern
    </button>
    <button mat-raised-button color="warn" mat-dialog-close="true">Abbrechen</button>
  </div>
</nx-dialog>
