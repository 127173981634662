export const environment = {
    production: true,
    jiraOAuthSecret: "ATOANYnQrRBGhSZWSWvR-nGHzPvpZ4SYEaNQGM7tWmRmJuC1CakbhWi9Y7V8421YxW_v7664A546",
    jiraOAuthClientId: "quB5EVCUFTTHxSsyqRMN7UsiHUlM77Ot",
    jiraOAuthUrl: "https://auth.atlassian.com",
    jiraOAuthRedirectUrl: "https://timer-app.enthus.dev",
    jiraDomain: "https://enthus.atlassian.net/",
    jiraAPIDomain: "https://api.atlassian.com",
    jiraRESTAPIDomain: "https://api.atlassian.com/ex/jira",
    jiraIssueFilter: "filter=10268",
    jiraActiveSprint: "filter=10020",
    jiraIssueStatusPlanned: "Planned",
    jiraIssueStatusOpen: "Offen"
};
