<nx-dialog [dialogData]="data">
  <form [formGroup]="editAlarmForm" class="dialog-content">
    <mat-form-field appearance="outline">
      <input
        formControlName="alarmDuration"
        matInput
        #alarm
        [errorStateMatcher]="immediateMatcher"
        autocomplete="off"
        maxlength="6"
      >
      <mat-error *ngIf="editAlarmForm.controls.alarmDuration.hasError('durationFormatError')">
        Zeitformat: 1h30m/1h 30m/1h/1m
      </mat-error>
    </mat-form-field>
  </form>
  <button
    [disabled]="editAlarmForm.invalid" class="dialog-actions" mat-raised-button color="primary"
    (click)="setAlarm(alarm.value)"
  >
    Setzen
  </button>
  <button class="dialog-actions" mat-raised-button color="warn" mat-dialog-close="true">Abbrechen
  </button>
</nx-dialog>
