import { OverlayContainer } from "@angular/cdk/overlay";
import { Component, Renderer2 } from "@angular/core";
import * as moment from "moment";
import { IdleService } from "../services/idle.service";
import { NotificationService } from "../services/notification.service";
import { SettingsService } from "../services/settings.service";

@Component({
  selector: "nx-menu-and-navigation",
  templateUrl: "./menu-and-navigation.component.html",
  styleUrls: ["./menu-and-navigation.component.scss"],
})
export class MenuAndNavigationComponent {
  constructor(
    public notificationService: NotificationService, // Must stay here even if unused to be loaded early
    public settingsService: SettingsService,
    public idleService: IdleService, // Must stay here even if unused to be loaded early
    private _overlayContainer: OverlayContainer,
    private _renderer: Renderer2,
  ) {
    moment.locale("de");

    this._renderer.addClass(document.body, this.settingsService.theme);
    this._overlayContainer.getContainerElement().classList.add(this.settingsService.theme);
  }
}
