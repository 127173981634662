import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { LocalStorageService } from "./local-storage.service";
import { ISettingsChanges } from "../settings-changes.interface";
import { ISettings } from "../settings.interface";
import { ITimerWithStandardType } from "../settings/timer-with-standard-type.interface";

@Injectable({
  providedIn: "root",
})
export class SettingsService {
  public defaultSettings: ISettings;
  public settingChange$: BehaviorSubject<ISettingsChanges>;
  public settings$: BehaviorSubject<ISettings>;
  public theme: string;

  constructor(
    public localStorageService: LocalStorageService,
  ) {
    this.defaultSettings = {
      "idle": {
        "idleTimeout": {
          "timeoutValue": 5,
          "active": true,
          "repeat": true,
        },
      },
      "theme": {
        "theme": "krya-theme",
      },
      "pinnedTimers": {
        "panelExpanded": false,
      },
      "todayTimers": {
        "timeInput": "minusTime",
        "sort": "descending",
      },
      "standardType": {
        "timersWithStandardType": [],
        "pageSize": 5,
      },
      "navigation": {
        "showChips": false,
      },
      "informationPanel": {
        "panelExpanded": false,
      },
    };

    const localStorageSettings = LocalStorageService.getItem("settings");
    let currentSettings = localStorageSettings ? JSON.parse(JSON.stringify(localStorageSettings)) : null;

    if (currentSettings) {
      Object.keys(this.defaultSettings).forEach((firstLevelKey) => {
        if (!currentSettings.hasOwnProperty(firstLevelKey)) {
          currentSettings[firstLevelKey] = this.defaultSettings[firstLevelKey];
        } else {
          Object.keys(this.defaultSettings[firstLevelKey]).forEach((secondLevelKey) => {
            if (!currentSettings[firstLevelKey].hasOwnProperty(secondLevelKey)) {
              currentSettings[firstLevelKey][secondLevelKey] = this.defaultSettings[firstLevelKey][secondLevelKey];
            } else {
              for (const thirdLevelKey of Object.keys(this.defaultSettings[firstLevelKey][secondLevelKey])) {
                if (
                  (
                    typeof currentSettings[firstLevelKey][secondLevelKey] === "object"
                    && currentSettings[firstLevelKey][secondLevelKey][thirdLevelKey] === null
                  )
                  || !currentSettings[firstLevelKey][secondLevelKey].hasOwnProperty(thirdLevelKey)
                ) {
                  currentSettings[firstLevelKey][secondLevelKey][thirdLevelKey] = this.defaultSettings[firstLevelKey][secondLevelKey][thirdLevelKey];
                }
              }
            }
          });
        }
      });
    } else {
      currentSettings = this.defaultSettings;
    }

    this.localStorageService.setItem("settings", currentSettings);

    this.settingChange$ = new BehaviorSubject<ISettingsChanges>(this.getSettingChanges(currentSettings, currentSettings));
    this.settings$ = new BehaviorSubject<ISettings>(LocalStorageService.getItem("settings"));

    this.settings$.next(currentSettings);

    const theme = LocalStorageService.getItem("settings")["theme"]["theme"];
    this.theme = theme ? theme : "krya-theme";
  }

  public getSetting(group: string, name: string, subGroup?: string): any {
    const localStorageSettings: ISettings = LocalStorageService.getItem("settings");

    if (subGroup) {
      return localStorageSettings[group][subGroup][name];
    } else {
      return localStorageSettings[group][name];
    }
  }

  public getSettingChanges(currentSettings: ISettings, previousSettings: ISettings): ISettingsChanges {
    let settingChanges = {};
    const currentKeys = Object.keys(currentSettings);

    currentKeys.forEach((firstLevelKey) => {
      if (
        typeof currentSettings[firstLevelKey] === "object"
        && typeof previousSettings[firstLevelKey] === "object"
      ) {
        Object.keys(currentSettings[firstLevelKey])
          .forEach((secondLevelKey) => {
            if (
              typeof currentSettings[firstLevelKey][secondLevelKey] === "object"
              && typeof previousSettings[firstLevelKey][secondLevelKey] === "object"
            ) {
              Object.keys(currentSettings[firstLevelKey][secondLevelKey])
                .forEach((thirdLevelKey) => {
                  if (
                    currentSettings[firstLevelKey][secondLevelKey][thirdLevelKey]
                    !== previousSettings[firstLevelKey][secondLevelKey][thirdLevelKey]
                  ) {
                    settingChanges = {
                      [firstLevelKey]: {
                        [secondLevelKey]: {
                          [thirdLevelKey]: {
                            previous: previousSettings[firstLevelKey][secondLevelKey][thirdLevelKey],
                            current: currentSettings[firstLevelKey][secondLevelKey][thirdLevelKey],
                          },
                        },
                      },
                    };
                  }
                });
            } else {
              if (
                currentSettings[firstLevelKey][secondLevelKey]
                !== previousSettings[firstLevelKey][secondLevelKey]
              ) {
                settingChanges = {
                  [firstLevelKey]: {
                    [secondLevelKey]: {
                      previous: previousSettings[firstLevelKey][secondLevelKey],
                      current: currentSettings[firstLevelKey][secondLevelKey],
                    },
                  },
                };
              }
            }
          });
      }
    });

    return settingChanges;
  }

  public getTimerWithStandardType(timerName: string): ITimerWithStandardType {
    return this.getSetting("standardType", "timersWithStandardType").find(
      (timer) => timer.name === timerName,
    );
  }

  public saveSetting(value: any, name: string, group: string, subGroup?: string): void {
    const localStorageSettingsOld: ISettings = LocalStorageService.getItem("settings");

    let localStorageSettings: ISettings = JSON.parse(JSON.stringify(localStorageSettingsOld));

    if (localStorageSettings) {
      if (!localStorageSettings[group]) {

        localStorageSettings = { [group]: {} };
      }

      if (subGroup) {
        if (!localStorageSettings[group][subGroup]) {

          localStorageSettings[group] = { [subGroup]: {} };
        }

        localStorageSettings[group][subGroup][name] = value;
      } else {
        if (!localStorageSettings[group][name]) {

          localStorageSettings[group] = { [name]: value };
        }

        localStorageSettings[group][name] = value;
      }
    }

    this.localStorageService.setItem("settings", localStorageSettings);

    this.settings$.next(localStorageSettings);

    this.settingChange$.next(this.getSettingChanges(localStorageSettings, localStorageSettingsOld));
  }
}
