import { Component, Inject } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import * as moment from "moment";
import { Moment } from "moment";
import { map } from "rxjs/operators";
import { CustomValidators } from "../custom-validators";
import { EditableDurationsListService } from "../editable-durations-list/editable-durations-list.service";
import { IMMEDIATE_ERROR_STATE_MATCHER } from "../immediate-error-state-matcher";
import { SettingsService } from "../services/settings.service";
import { TimerService } from "../services/timer.service";

interface INewDurationForm {
  name: FormControl<string>;
  durationFrom: FormControl<string>;
  durationTo: FormControl<string>;
  datepicker: FormControl<string>;
  activityType: FormControl<string>;
}

@Component({
  selector: "nx-add-duration-dialog",
  templateUrl: "./add-duration-dialog.component.html",
  styleUrls: ["./add-duration-dialog.component.scss"],
})
export class AddDurationDialogComponent {
  public datePickerValue: Moment;
  public immediateMatcher = IMMEDIATE_ERROR_STATE_MATCHER;
  public newDurationForm: FormGroup<INewDurationForm>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddDurationDialogComponent>,
    public timerService: TimerService,
    public editableDurationsListService: EditableDurationsListService,
    private _settingsService: SettingsService,
  ) {
    this.newDurationForm = formBuilder.nonNullable.group({
      "name": ["", [this.data["showNameInput"] ? Validators.required : Validators.nullValidator]],
      "durationFrom": ["", [Validators.required, CustomValidators.validateTime]],
      "durationTo": ["", [Validators.required, CustomValidators.validateTime]],
      "datepicker": "",
      "activityType": this.timerService.activityTypes[0],
    });

    this.newDurationForm.setValidators(this.validateNewDurationForm(this.editableDurationsListService.dateFrom));
    this.newDurationForm.updateValueAndValidity();

    const timerWithStandardType = this._settingsService.getTimerWithStandardType(this.data["timer"] ? this.data["timer"].name : this.data["name"]);

    const activityType = timerWithStandardType ? timerWithStandardType.type : this.timerService.activityTypes[0];
    this.newDurationForm.controls.activityType.setValue(activityType);

    this.timerService.filteredTimersForAutocomplete$ = this.newDurationForm.controls.name.valueChanges
      .pipe(
        map((typedName) => this.timerService.filterTimers(typedName)),
      );
  }

  public addNewDuration(from: string, to: string, name?: string) {
    let newDatePickerFrom = moment(this.datePickerValue).add(moment.duration(from));
    let newDatePickerTo = moment(this.datePickerValue).add(moment.duration(to));
    let dateFrom = this.datePickerValue ? newDatePickerFrom : moment(from, "HH:mm");
    let dateTo = this.datePickerValue ? newDatePickerTo : moment(to, "HH:mm");

    if (this.editableDurationsListService.dateFrom) {
      const newDateFrom = moment(this.editableDurationsListService.dateFrom).startOf("day");
      newDatePickerFrom = moment(newDateFrom).add(moment.duration(from));
      newDatePickerTo = moment(newDateFrom).add(moment.duration(to));
      dateFrom = newDatePickerFrom;
      dateTo = newDatePickerTo;
    }

    this.datePickerValue = this.editableDurationsListService.dateFrom ? this.editableDurationsListService.dateFrom : this.datePickerValue;

    const existingTimer = TimerService.getAllTimers().find((timer) => timer.name === name);
    const timerToAdd = this.data["timer"] || existingTimer;

    if (timerToAdd) {
      this.timerService.addDurationData(
        timerToAdd,
        dateFrom,
        dateTo,
        this.newDurationForm.controls.activityType.value,
        this.datePickerValue,
      );
    } else {
      this.timerService.addTimer(name, dateFrom, this.newDurationForm.controls.activityType.value, moment.duration(dateTo.diff(dateFrom)));
    }

    this.dialogRef.close();
  }

  public setActivityType(event: MatAutocompleteSelectedEvent) {
    const timerWithStandardType = this._settingsService.getTimerWithStandardType(event.option.value);

    if (timerWithStandardType) {
      this.newDurationForm.controls.activityType.setValue(timerWithStandardType.type);
    }

  }

  public setDateValue(datePickerValue) {
    this.datePickerValue = datePickerValue;
  }

  public validateNewDurationForm(dateFrom: Moment): ValidatorFn {
    return (form: FormGroup<INewDurationForm>): null | ValidationErrors => {
      let validationError = null;
      const fromControl = form.controls.durationFrom;
      const toControl = form.controls.durationTo;
      const datePickerControl = form.controls.datepicker;
      let newDatePickerFrom = moment(datePickerControl.value).add(moment.duration(fromControl.value));
      let newDatePickerTo = moment(datePickerControl.value).add(moment.duration(toControl.value));
      let momentFrom = datePickerControl.value ? newDatePickerFrom : moment(fromControl.value, "HH:mm");
      let momentTo = datePickerControl.value ? newDatePickerTo : moment(toControl.value, "HH:mm");

      if (dateFrom) {
        const newDateFrom = moment(dateFrom).startOf("day");

        newDatePickerFrom = moment(newDateFrom).add(moment.duration(fromControl.value));
        newDatePickerTo = moment(newDateFrom).add(moment.duration(toControl.value));
        momentFrom = newDatePickerFrom;
        momentTo = newDatePickerTo;
      }

      if (momentFrom.valueOf() > moment().valueOf()) {
        fromControl.setErrors({ ...fromControl.errors, "dateInFuture": true });

        validationError = { "dateInFuture": true };
      } else {
        if (
          fromControl
          && fromControl.errors
          && fromControl.errors["dateInFuture"]
        ) {
          delete fromControl.errors["dateInFuture"];
          fromControl.updateValueAndValidity();
        }
      }

      if (momentTo.valueOf() > moment().valueOf()) {
        toControl.setErrors({ ...toControl.errors, "dateInFuture": true });

        validationError = { "dateInFuture": true };
      } else {
        if (
          toControl
          && toControl.errors
          && toControl.errors["dateInFuture"]
        ) {
          delete toControl.errors["dateInFuture"];
          fromControl.updateValueAndValidity();
        }
      }

      if (momentFrom.valueOf() > momentTo.valueOf()) {
        fromControl.setErrors({ ...fromControl.errors, "dateFromIsGreaterThanDateTo": true });

        validationError = { "dateToIsGreaterThanDateFrom": true };
      } else {
        if (
          fromControl
          && fromControl.errors
          && fromControl.errors["dateFromIsGreaterThanDateTo"]
        ) {
          delete fromControl.errors["dateFromIsGreaterThanDateTo"];
          fromControl.updateValueAndValidity();
        }
      }

      return validationError;
    };
  }
}
