<mat-card appearance="outlined">
  <mat-card-content>
    <mat-chip-listbox *ngIf="!settingsService.settings$.value['navigation']['showChips']">
      <mat-chip-option selected color="accent" [disableRipple]="true">
        Heute geloggt: {{ timerService.totalTodayDurations | async }}
      </mat-chip-option>
      <mat-chip-option *ngIf="timerService.beginningOfDay" selected color="accent" [disableRipple]="true">
        Anfang des Tages: {{ timerService.beginningOfDay }}
      </mat-chip-option>
    </mat-chip-listbox>
    <div class="pinned-timers">
      <mat-expansion-panel
        [hideToggle]="true"
        [expanded]="panelIsExpanded"
        (expandedChange)="settingsService.saveSetting($event, 'panelExpanded', 'pinnedTimers')"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>bookmarks</mat-icon>
            Gepinnte Timer
          </mat-panel-title>
        </mat-expansion-panel-header>

        <nx-pinned-timers></nx-pinned-timers>
        <div *ngIf="timerService.pinnedTimers.length === 0" class="placeholder-pinned-timers">
          Klicke auf
          <mat-icon>bookmark_border</mat-icon>
          um Timer zu pinnen!
        </div>
      </mat-expansion-panel>
    </div>
    <div class="auto-stopped-timer-container" *ngIf="autoStoppedTimer">
      <p>
        <mat-icon>timer_off</mat-icon>
        Automatisch gestoppter Timer
      </p>
      <nx-timer
        [timer]="autoStoppedTimer"
        [expansionPanelDisabled]="true"
        [autoStopped]="true"
        class="pinned-timer"
      >
      </nx-timer>
    </div>
  </mat-card-content>
</mat-card>
