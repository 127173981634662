import { Component, EventEmitter, Inject, OnDestroy } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import * as moment from "moment";
import { Moment } from "moment";
import { CustomValidators } from "../custom-validators";
import { IMMEDIATE_ERROR_STATE_MATCHER } from "../immediate-error-state-matcher";
import { TimerService } from "../services/timer.service";
import { ITimer } from "../timer/timer.interface";
import { takeUntil, tap } from "rxjs/operators";

interface ITimerForm {
  timerName: FormControl<string>;
  timerStartTime: FormControl<string>;
  radioButtonGroup: FormControl<string>;
}

@Component({
  selector: "nx-start-timer-dialog",
  templateUrl: "./start-timer-dialog.component.html",
  styleUrls: ["./start-timer-dialog.component.scss"],
})
export class StartTimerDialogComponent implements OnDestroy {
  public allTimers: ITimer[];
  public immediateMatcher = IMMEDIATE_ERROR_STATE_MATCHER;
  public radioButtonsVisibility: boolean = false;
  public startTimerForm: FormGroup<ITimerForm>;
  public newActivityType: string = this.timerService.activityTypes[0];
  private _destroyEmitter = new EventEmitter<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public formBuilder: FormBuilder,
    public timerService: TimerService,
    public dialogRef: MatDialogRef<StartTimerDialogComponent>,
  ) {
    this.startTimerForm = this.formBuilder.group(
      {
        "timerName": ["", Validators.required],
        "timerStartTime": [
          moment(this.data["startTime"]).subtract(this.data["idleTime"], "minutes").format("HH:mm"),
          [Validators.required, CustomValidators.validateTime],
        ],
        "radioButtonGroup": ["method1"],
      },
    );

    this.startTimerForm.valueChanges
      .pipe(
        takeUntil(this._destroyEmitter),
        tap((changes) => {
          if (!this.allTimers) {
            this.allTimers = TimerService.getAllTimers();
          }
          const timer = this.allTimers.find((foundTimer) => {
            const allTimerDurations = this.timerService.getAllTimerDurationData(foundTimer);
            const lastDuration = allTimerDurations[allTimerDurations.length - 1];
            const lastEndTime = moment(lastDuration.startTime).add(lastDuration.duration);

            if (moment(changes["timerStartTime"], "HH:mm", true).isValid()) {
              return lastEndTime.valueOf() > moment(changes["timerStartTime"], "HH:mm").valueOf();
            }
          });

          this.radioButtonsVisibility = !!timer; // simplified if statement
        }),
      )
      .subscribe();
  }

  public getTypeValue(event): void {
    this.newActivityType = event.value;
  }

  public startTimer(name: string, startTime: string): void {
    const startTimeMoment: Moment = moment(startTime, "HH:mm");
    const allTimers: ITimer[] = TimerService.getAllTimers();
    const existingTimer: ITimer = allTimers.find((timer) => {
      return timer.name === name;
    });

    if (existingTimer) {
      if (this.radioButtonsVisibility) {
        const allTimerDurations = this.timerService.getAllTimerDurationData(existingTimer);
        const lastDuration = allTimerDurations[allTimerDurations.length - 1];
        const lastEndTime = moment(lastDuration.startTime).add(lastDuration.duration);

        if (this.startTimerForm.controls.radioButtonGroup.value === "method1") {
          this.timerService.addTimer(name, lastEndTime, this.newActivityType);
        } else {
          if (
            lastDuration.startTime.hours() === lastEndTime.hours()
            && lastDuration.startTime.minutes() === lastEndTime.minutes()
          ) {
            existingTimer.durations[startTimeMoment.year()][startTimeMoment.month()][startTimeMoment.date()].splice(
              existingTimer.durations[startTimeMoment.year()][startTimeMoment.month()][startTimeMoment.date()].length - 1,
              1,
            );
            this.timerService.setTimer(existingTimer);

            this.timerService.addTimer(existingTimer.name, startTimeMoment, this.newActivityType);
          }

          const existingTimerDurations = existingTimer.durations[moment().year()][moment().month()][moment().date()];
          existingTimerDurations[existingTimerDurations.length - 1].duration = moment.duration(moment(
            this.startTimerForm.controls.timerStartTime.value,
            "HH:mm",
          ).diff(moment(existingTimerDurations[existingTimerDurations.length - 1].startTime)));

          this.timerService.setTimer(existingTimer);
          this.timerService.addTimer(
            this.startTimerForm.controls.timerName.value,
            moment(this.startTimerForm.controls.timerStartTime.value, "HH:mm"),
            this.newActivityType,
          );
          delete this.startTimerForm.controls.timerStartTime.errors["foo"];
          this.startTimerForm.controls.timerStartTime.updateValueAndValidity();
        }
      } else {
        this.timerService.addTimer(name, startTimeMoment, this.newActivityType);
      }
    } else {
      const timer = this.allTimers.find((foundTimer) => {
        const allTimerDurations = this.timerService.getAllTimerDurationData(foundTimer);
        const lastDuration = allTimerDurations[allTimerDurations.length - 1];
        const lastEndTime = moment(lastDuration.startTime).add(lastDuration.duration);

        if (moment(this.startTimerForm.controls.timerStartTime.value, "HH:mm", true).isValid()) {
          return lastEndTime.valueOf() > moment(this.startTimerForm.controls.timerStartTime.value, "HH:mm").valueOf();
        }
      });

      if (timer && this.startTimerForm.controls.radioButtonGroup.value === "method1") {
        const timerDurations = timer.durations[moment().year()][moment().month()][moment().date()];
        timerDurations[timerDurations.length - 1].duration = moment.duration(moment(
          this.startTimerForm.controls.timerStartTime.value,
          "HH:mm",
        ).diff(moment(timerDurations[timerDurations.length - 1].startTime)));

        this.timerService.setTimer(timer);
        this.timerService.addTimer(
          this.startTimerForm.controls.timerName.value,
          moment(this.startTimerForm.controls.timerStartTime.value, "HH:mm"),
          this.newActivityType,
        );
      } else {
        this.timerService.addTimer(
          this.startTimerForm.controls.timerName.value,
          moment(this.startTimerForm.controls.timerStartTime.value, "HH:mm"),
          this.newActivityType,
        );
        this.radioButtonsVisibility = false;
      }
    }
    this.dialogRef.close("saved");
  }

  ngOnDestroy(): void {
    this._destroyEmitter.next();
  }
}
