<nx-dialog [dialogData]="data">
  <form [formGroup]="newDurationForm" class="dialog-content">
    <mat-form-field *ngIf="data['showNameInput']" appearance="outline">
      <input
        matInput
        formControlName="name"
        placeholder="Name"
        autocomplete="off"
        [matAutocomplete]="auto"
        [errorStateMatcher]="immediateMatcher"
        maxlength="100"
        #name
      >
      <mat-error *ngIf="newDurationForm.controls.name.hasError('required')">
        Name ist benötigt!
      </mat-error>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="setActivityType($event)">
        <mat-option *ngFor="let timer of timerService.filteredTimersForAutocomplete$ | async" [value]="timer.name">
          <span>{{ timer.name }} {{ timer.description }}</span>
        </mat-option>
      </mat-autocomplete>
      <mat-hint align="end">{{ name.value.length }} / 100</mat-hint>
    </mat-form-field>
    <mat-form-field *ngIf="!editableDurationsListService.dateFrom" appearance="outline">
      <input
        matInput
        [matDatepicker]="picker"
        placeholder="Datum auswählen"
        #pickerInput
        (dateChange)="setDateValue($event.value)"
        formControlName="datepicker"
        [errorStateMatcher]="immediateMatcher"
      >
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    Von:
    <mat-form-field appearance="outline" class="durationFrom">
      <input matInput formControlName="durationFrom" autocomplete="off" [errorStateMatcher]="immediateMatcher"
        maxlength="5">
      <mat-error *ngIf="newDurationForm.controls.durationFrom.hasError('timeFormatError')">Zeitformat: HH:MM
      </mat-error>
      <mat-error *ngIf="newDurationForm.controls.durationFrom.hasError('dateInFuture')">
        Startzeit des Timers darf nicht in der Zukunft liegen
      </mat-error>
      <mat-error *ngIf="newDurationForm.controls.durationFrom.hasError('dateFromIsGreaterThanDateTo')">
        Startzeit darf nicht größer als Endzeit sein
      </mat-error>
    </mat-form-field>
    Bis:
    <mat-form-field appearance="outline" class="durationTo">
      <input matInput formControlName="durationTo" autocomplete="off" [errorStateMatcher]="immediateMatcher"
        maxlength="5">
      <mat-error *ngIf="newDurationForm.controls.durationTo.hasError('timeFormatError')">Zeitformat: HH:MM
      </mat-error>
      <mat-error *ngIf="newDurationForm.controls.durationTo.hasError('dateInFuture')">
        Endzeit des Timers darf nicht in der Zukunft liegen
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-select
        placeholder="type"
        [disableOptionCentering]="true"
        [required]="true"
        formControlName="activityType"
      >
        <mat-option *ngFor="let type of timerService.activityTypes" [value]="type">
          {{ type }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
  <button
    [disabled]="newDurationForm.invalid"
    mat-raised-button
    color="primary"
    class="dialog-actions"
    (click)="addNewDuration(newDurationForm.controls.durationFrom.value, newDurationForm.controls.durationTo.value, newDurationForm.controls.name.value)"
  >Speichern
  </button>
  <button mat-raised-button color="warn" class="dialog-actions" mat-dialog-close="true">Abbrechen</button>
</nx-dialog>
