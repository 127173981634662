import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatMenuTrigger } from "@angular/material/menu";
import * as moment from "moment";
import { Moment } from "moment";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import {
  EditTimerSingleDurationDialogComponent,
} from "../edit-timer-single-duration-dialog/edit-timer-single-duration-dialog.component";
import { EditableDurationsListService } from "../editable-durations-list/editable-durations-list.service";
import { LocalStorageService } from "../services/local-storage.service";
import { TimerService } from "../services/timer.service";
import { ITimer } from "../timer/timer.interface";
import { ITimerTable } from "./timer-table";
import { catchError, filter, switchMap, takeUntil } from "rxjs/operators";
import { Subscription, throwError } from "rxjs";

@Component({
  selector: "nx-durations-list",
  templateUrl: "./durations-list.component.html",
  styleUrls: ["./durations-list.component.scss"],
})
export class DurationsListComponent implements OnInit, OnChanges, OnDestroy {
  public contextMenuPosition = { x: "0px", y: "0px" };
  public displayedColumns: string[] = ["jiraSync", "position", "startTime", "endTime", "duration", "type", "menu"];
  @ViewChild(MatMenuTrigger) matMenuTrigger: MatMenuTrigger;
  @Input() public readonly = false;
  @Input() public timer: ITimer;
  public timerData: ITimerTable[] = [];
  private _destroyEmitter = new EventEmitter<void>();

  constructor(
    public timerService: TimerService,
    public dialog: MatDialog,
    public editableDurationsListService: EditableDurationsListService,
    public localStorageService: LocalStorageService,
  ) {
  }

  public getTypeValue(event, timer): void {
    const year = timer.startTime.year();
    const month = timer.startTime.month();
    const date = timer.startTime.date();
    const index = this.timer.durations[year][month][date].findIndex(
      (durationData) => durationData.startTime.valueOf() === timer.startTime.valueOf(),
    );

    if (index !== -1) {
      this.timer.durations[year][month][date][index].activityType = event.value;

      this.timerService.setTimer(this.timer);

      if (!this.timerService.isActiveTimer(this.timer)) {
        if (this.timer.syncedWithJira !== undefined) {
          this.timerService.syncDurationWithJira(this.timer, timer.startTime, moment.duration(timer.duration), index)
            .pipe(
              takeUntil(this._destroyEmitter),
            )
            .subscribe();
        }
      } else {
        this.localStorageService.setItem("activeTimer", this.timer);

        this.timerService.setTimer(this.timer);

        this.timerService.activeTimer.next(this.timer);
      }
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.hasOwnProperty("timer")
    ) {
      this._setTimerData();
    }
  }

  ngOnInit(): void {
    this._setTimerData();
  }

  public openContextMenu(event: MouseEvent, rowPosition: number): void {
    if (!this.readonly) {
      event.preventDefault();
      this.contextMenuPosition.x = event.clientX + "px";
      this.contextMenuPosition.y = event.clientY + "px";
      this.matMenuTrigger.menuData = { rowPosition: rowPosition };
      this.matMenuTrigger.openMenu();
    }
  }

  public openDeleteTimerConfirmDialog(position: number): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      id: "deleteTimerConfirmDialog",
      data: {
        title: "Sind Sie sicher?",
        timer: this.timer,
      },
    });

    const durationToBeDeleted = this.timerData.find((durationData) => {
      return durationData.position === position;
    });

    const startTime = durationToBeDeleted.startTime;
    const duration = moment.duration(moment(durationToBeDeleted.endTime).diff(moment(durationToBeDeleted.startTime)));

    dialogRef.afterClosed()
      .pipe(
        takeUntil(this._destroyEmitter),
        filter((result) => !!result),
        switchMap(() => this.timerService.removeTimerDuration(startTime, duration, this.timer)),
      )
      .subscribe();
  }

  public openEditDialog(position: number): void {
    this.dialog.open(EditTimerSingleDurationDialogComponent, {
      data: {
        title: "",
        timer: this.timer,
        position: position,
        timerData: this.timerData,
      },
    });
  }

  public syncDurationWithJira(startTime: Moment, duration: string, position: number, activityType: string): Subscription {
    const durationIndex: number = position - 1;

    return this.timerService.syncDurationWithJira(this.timer, startTime, moment.duration(duration), durationIndex)
      .pipe(
        takeUntil(this._destroyEmitter),
        catchError((error) => {
          console.error("Failed to sync duration with Jira", error);

          return throwError(error);
        }),
      )
      .subscribe();
  }

  private _setTimerData(): void {
    let timerDurationData;
    const preparedData: ITimerTable[] = [];

    if (this.editableDurationsListService.dateFrom) {
      timerDurationData = this.timerService.getTimerDurationDataByDateFromTo(
        this.timer,
        this.editableDurationsListService.dateFrom,
        this.editableDurationsListService.dateTo,
      );
    } else {
      timerDurationData = this.timerService.getAllTimerDurationData(this.timer);
    }

    for (const [index, durationData] of timerDurationData.entries()) {
      let endTime = null;
      let duration = null;

      if (durationData.duration) {
        endTime = moment(durationData.startTime).add(durationData.duration);
        duration = moment.utc(durationData.duration.asMilliseconds()).format("HH:mm");
      }

      preparedData.unshift(
        {
          position: index + 1,
          startTime: durationData.startTime,
          endTime: endTime,
          duration: duration,
          syncedWithJira: durationData.syncedWithJira,
          savedLocal: durationData.savedLocal,
          activityType: durationData.activityType ? durationData.activityType : null,
        },
      );
    }
    this.timerData = preparedData;
  }

  ngOnDestroy(): void {
    this._destroyEmitter.next();
  }
}
