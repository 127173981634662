<nx-dialog [dialogData]="data">
  <form [formGroup]="createTimerForm" class="dialog-content">
    <mat-form-field class="name" appearance="outline">
      <input
        type="text"
        matInput="name"
        placeholder="Name"
        #name
        formControlName="name"
        [errorStateMatcher]="immediateMatcher"
        autocomplete="off"
        maxlength="100"
      >
      <mat-hint align="end">{{name.value.length}} / 100</mat-hint>
      <mat-error *ngIf="createTimerForm.controls.name.hasError('nameFormatError')">
        Der Timer mit solchem Namen exsistiert schon
      </mat-error>
    </mat-form-field>
    <div class="dateAndStartTime">
      <mat-form-field appearance="outline">
        <input
          matInput
          [matDatepicker]="picker"
          placeholder="Datum auswählen"
          #pickerInput
          (dateChange)="changeDateValue($event.value)"
          [max]="maxDate"
          formControlName="datepicker"
          [errorStateMatcher]="immediateMatcher"
        >
        <mat-error *ngIf="createTimerForm.controls.datepicker.invalid"></mat-error>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <input
          type="text"
          formControlName="time"
          matInput="time"
          placeholder="Startzeit"
          #time
          [errorStateMatcher]="immediateMatcher"
          autocomplete="off"
          maxlength="5"
        >
        <mat-error *ngIf="createTimerForm.controls.time.hasError('timeFormatError')">Zeitformat: HH:MM</mat-error>
      </mat-form-field>
    </div>
    <div class="durationAndActivityType">
      <mat-form-field appearance="outline">
        <input
          matInput
          type="text"
          placeholder="Zeitdauer"
          formControlName="duration"
          #duration
          (keydown.enter)="saveTimer()"
          [errorStateMatcher]="immediateMatcher"
          autocomplete="off"
          maxlength="6"
        >
        <mat-hint *ngIf="dateNotToday" align="start"><strong>Timer in der Vergangenheit muss Zeitdauer haben!</strong>
        </mat-hint>
        <mat-error *ngIf="createTimerForm.controls.duration.hasError('dateInFuture')">
          Endzeit des Timers darf nicht in der Zukunft liegen
        </mat-error>
        <mat-error *ngIf="createTimerForm.controls.duration.hasError('durationFormatError')">
          Zeitformat: 1h30m/1h 30m/1h/1m
        </mat-error>
        <mat-error *ngIf="createTimerForm.controls.duration.hasError('dateInPastWithoutDuration')">
          Der Timer darf nicht in der Vergangenheit ohne Zeitdauer erstellt werden!
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-select
          placeholder="Typ" [disableOptionCentering]="true"
          (selectionChange)="getTypeValue($event)"
          formControlName="activityType"
        >
          <mat-option *ngFor="let type of timerService.activityTypes" [value]="type">
            {{type}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>

  <button [disabled]="createTimerForm.invalid" mat-raised-button color="primary" (click)="saveTimer()"
    class="dialog-actions">Speichern
  </button>
  <button mat-raised-button color="warn" class="dialog-actions" mat-dialog-close="true">Abbrechen
  </button>
</nx-dialog>

