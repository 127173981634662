import { Component, EventEmitter, Input, OnChanges, OnDestroy, SimpleChanges } from "@angular/core";
import { JiraService } from "../services/jira.service";
import { filter, switchMap, take, takeUntil, tap } from "rxjs/operators";
import { BehaviorSubject, interval, Observable } from "rxjs";
import { TimerService } from "../services/timer.service";
import { ITimer } from "../timer/timer.interface";
import { MatDialog } from "@angular/material/dialog";
import { IJiraIssue } from "../jira-issue.interface";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import * as moment from "moment";

export interface ITaskDescription {
  descriptionLine: ITaskDescriptionLine[];
  isTask: boolean;
  devTeam?: IDevTeam;
}

export interface ITaskDescriptionLine {
  descriptionCell: string;
  isIcon: boolean;
}

export interface IDevTeam {
  frontend: string;
  backend: string;
}

export enum ICONS {
  "(/)" = "check",
  "(x)" = "error",
  "(?)" = "help",
  "(*b)" = "star_blue",
  "(*r)" = "star_red",
  "(*g)" = "star_green",
  "(*y)" = "star_yellow",
  "👎" = "thumbsdown",
  "👍" = "thumbsup",
}

export enum ICONSREV {
  "check" = "(/)",
  "error" = "(x)",
  "help" = "(?)",
  "star_blue" = "(*b)",
  "star_red" = "(*r)",
  "star_green" = "(*g)",
  "star_yellow" = "(*y)",
  "thumbsdown" = "👎",
  "thumbsup" = "👍",
}

@Component({
  selector: "nx-task",
  templateUrl: "./task.component.html",
  styleUrls: ["./task.component.scss"],
})
export class TaskComponent implements OnDestroy, OnChanges {
  private _destroyEmitter: EventEmitter<void> = new EventEmitter<void>();
  @Input() public shouldShowTask: boolean = false;
  @Input() public timer: ITimer;
  @Input() public issueKey: string;
  public taskDescription$: BehaviorSubject<ITaskDescription[]> = new BehaviorSubject<ITaskDescription[]>([]);
  public taskOrderFrontend: string[] = ["checkbox", "check", "thumbsup", "thumbsdown", "help", "error"];
  public taskOrderBackend: string[] = ["checkbox", "star_green", "star_yellow", "star_red", "star_blue", "error"];
  public fetchDate$: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);

  constructor(private _jiraService: JiraService, public timerService: TimerService, private _dialog: MatDialog, private _snackBar: MatSnackBar) {
    this.timerService.descriptionOverwriteEmitter
      .pipe(
        takeUntil(this._destroyEmitter),
        filter((timerName) => this.timer && timerName === this.timer.name),
        switchMap(() => {
          return this.openOverwriteConfirmationDialog()
            .pipe(
              filter((shouldSend) => shouldSend),
              tap(() => this.resetChanges()),
            );
        }),
      )
      .subscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ((this.timer || this.issueKey) && this.taskDescription$.value.length === 0) {
      this._jiraService.getIssueDescription(this.timer ? this.timer.name : this.issueKey)
        .pipe(
          take(1),
          filter((issue) => !!(issue && issue.fields && issue.fields.description)),
          tap((issue) => {
            if (this.timer && this.timer.issueTask && this.timer.issueTask.length > 0) {
              this.taskDescription$.next(this.timer.issueTask);
            } else {
              this.taskDescription$.next(this.buildTaskPattern(this.separateDescriptionString(issue.fields.description)));
              if (this.timer) {
                this.timer.issueTaskFetchDate = moment();
              }
            }

            if (this.timer) {
              this.fetchDate$.next(moment(this.timer.issueTaskFetchDate).fromNow());
            }
          }),
        )
        .subscribe();
    }

    if (this.timer) {
      interval(1000).pipe(
        takeUntil(this._destroyEmitter),
        filter(() => !!this.timer.issueTaskFetchDate),
        tap(() => this.fetchDate$.next(moment(this.timer.issueTaskFetchDate).fromNow())),
      ).subscribe();
    }
  }

  public separateDescriptionString(text: string): string[][] {
    const separatedByWhitespace: string[][] = [];

    const separatedByNewLine = text.match(new RegExp("(.+|^\\n)", "gm"));
    separatedByNewLine.forEach((line, index) => separatedByWhitespace[index] = line.split(/\s+|(?=\()/g));

    return separatedByWhitespace;
  }

  public buildTaskPattern(text: string[][]): ITaskDescription[] {
    const regExpSearchCondition: RegExp = new RegExp("(\\(.+?\\)|👎|👍)");
    const description: ITaskDescription[] = [];
    let descriptionCell: ITaskDescriptionLine[] = [];

    text.forEach((textLine) => {
      descriptionCell = [];

      textLine.forEach((textCell) => {
        if (regExpSearchCondition.test(textCell)) {
          descriptionCell.push({ descriptionCell: ICONS[textCell], isIcon: true });
        } else {
          descriptionCell.push({ descriptionCell: textCell, isIcon: false });
        }
      });

      description.push({
        descriptionLine: descriptionCell,
        isTask: this.isTask(textLine),
        devTeam: this.findDevTeamIcon(textLine),
      });
    });

    return description;
  }

  public isTask(textLine: string[]): boolean {
    const regExpSearchCondition: RegExp = new RegExp("A\\d.*:|\\*");

    return regExpSearchCondition.test(textLine[0]);
  }

  public findDevTeamIcon(textLine: string[]): IDevTeam {
    const devTeam: IDevTeam = { frontend: "checkbox", backend: "checkbox" };

    if (textLine[1] in ICONS && this.taskOrderFrontend.includes(ICONS[textLine[1]])) {
      devTeam.frontend = ICONS[textLine[1]];
    } else if (textLine[1] in ICONS && this.taskOrderBackend.includes(ICONS[textLine[1]])) {
      devTeam.backend = ICONS[textLine[1]];
    }

    if (textLine[2] in ICONS && this.taskOrderBackend.includes(ICONS[textLine[2]])) {
      devTeam.backend = ICONS[textLine[2]];
    }

    return devTeam;
  }

  public updateTaskStatus(status: string, taskLine: ITaskDescription, devTeam: string): void {
    let taskLength: number = 0;

    if (devTeam === "frontend") {
      taskLength = this.taskOrderFrontend.findIndex((taskStatus) => taskStatus === status) + 1;
      this.replaceTaskStatus(taskLine, status, taskLength, this.taskOrderFrontend, devTeam);
    } else {
      taskLength = this.taskOrderBackend.findIndex((taskStatus) => taskStatus === status) + 1;
      this.replaceTaskStatus(taskLine, status, taskLength, this.taskOrderBackend, devTeam);
    }
  }

  public replaceTaskStatus(taskLine: ITaskDescription, status: string, taskLength: number, devTeamIcons: string[], devTeam: string): void {
    let task: ITaskDescription;
    let taskIndex: number = 0;

    task = this.taskDescription$.value.find((task) => task === taskLine);
    if (task) {
      taskIndex = task.descriptionLine.findIndex((description) => description.descriptionCell === status);

      if (taskIndex > 0) {
        task.descriptionLine[taskIndex] = {
          descriptionCell: devTeamIcons[taskLength === devTeamIcons.length ? 0 : taskLength],
          isIcon: true,
        };
      } else if (taskIndex < 0 && devTeamIcons !== this.taskOrderFrontend && task.devTeam.frontend !== "checkbox") {
        task.descriptionLine.splice(2, 0, {
          descriptionCell: devTeamIcons[taskLength === devTeamIcons.length ? 0 : taskLength],
          isIcon: true,
        });
      } else {
        task.descriptionLine.splice(1, 0, {
          descriptionCell: devTeamIcons[taskLength === devTeamIcons.length ? 0 : taskLength],
          isIcon: true,
        });
      }

      if (devTeam === "frontend") {
        task.devTeam.frontend = this.taskOrderFrontend[taskLength === this.taskOrderFrontend.length ? 0 : taskLength];
      } else {
        task.devTeam.backend = this.taskOrderBackend[taskLength === this.taskOrderBackend.length ? 0 : taskLength];
      }


      this.taskDescription$.value.splice(this.taskDescription$.value.findIndex((oldTask) => oldTask === taskLine), 1, task);

      this.taskDescription$.next(this.taskDescription$.value);
    }

    this.writeTaskStatusChangeToLocalStorage();
  }

  public writeTaskStatusChangeToLocalStorage(shouldClearLocalStorageData?: boolean): void {
    if (this.timer) {
      if (this.timerService.isActiveTimer(this.timer)) {
        const activeTimer = this.timerService.activeTimer.value;
        activeTimer.issueTask = this.taskDescription$.value;
        activeTimer.issueTaskFetchDate = this.timer.issueTaskFetchDate;
        this.timerService.activeTimer.next(activeTimer);
      }

      this.timer.issueTask = shouldClearLocalStorageData ? [] : this.taskDescription$.value;
      this.timer.issueTaskFetchDate = shouldClearLocalStorageData ? undefined : this.timer.issueTaskFetchDate;
      this.timerService.addTimerToLocalStorage(this.timer);
    }
  }

  public buildTaskDescriptionForJira(): string {
    let changedDescription: string = "";
    let joinedCell: string = "";

    this.taskDescription$.value.forEach((description) => {
      joinedCell = "";

      description.descriptionLine.forEach((descriptionLine) => {
        let text: string = "";
        if (descriptionLine.isIcon) {
          text = ICONSREV[descriptionLine.descriptionCell];
          text = text ? text : "";
        } else {
          text = descriptionLine.descriptionCell;
        }

        joinedCell += " " + text;
      });

      changedDescription += joinedCell.trim() + "\n";
    });

    return changedDescription;
  }

  public saveChanges(): void {
    this.openConfirmationDialog()
      .pipe(
        filter((shouldSend) => shouldSend),
        switchMap(() => this.pushUpdatedDescriptionToJira()),
        tap(() => this.timer.issueTask = []),
        tap(() => this._snackBar.open("Daten erfolgreich an Jira übermittelt", "Okay", { duration: 2000 })),
      )
      .subscribe();
  }

  public pushUpdatedDescriptionToJira(): Observable<IJiraIssue> {
    return this._jiraService.updateIssueDescription(this.timer.name, this.buildTaskDescriptionForJira())
      .pipe(
        take(1),
        switchMap(() => this._jiraService.getIssueDescription(this.timer.name)),
        filter((issue) => !!(issue && issue.fields && issue.fields.description)),
        tap((issue) => {
          this.taskDescription$.next(this.buildTaskPattern(this.separateDescriptionString(issue.fields.description)));
          this.timer.issueTaskFetchDate = moment();
        }),
      );
  }

  public resetChanges(): void {
    this._jiraService.getIssueDescription(this.timer ? this.timer.name : this.issueKey)
      .pipe(
        take(1),
        filter((issue) => !!(issue && issue.fields && issue.fields.description)),
        tap((issue) => {
          this.taskDescription$.next(this.buildTaskPattern(this.separateDescriptionString(issue.fields.description)));
          this.timer.issueTaskFetchDate = moment();
        }),
      )
      .subscribe();
    this.writeTaskStatusChangeToLocalStorage(true);
  }

  public ngOnDestroy(): void {
    this._destroyEmitter.next();
  }

  public openConfirmationDialog(): Observable<boolean> {
    return this._dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Es werden Daten überschrieben",
        content: `Alle Beschreibungstexte in Jira werden überschrieben. Möchtest du diese wirklich überschreiben?`,
      },
    }).afterClosed();
  }

  public openOverwriteConfirmationDialog(): Observable<boolean> {
    return this._dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Alle Änderungen werden überschrieben",
        content: `Alle Änderung an der Beschreibung werden mit den neusten Jira-Daten überschrieben. Möchtest du diese wirklich überschreiben?`,
      },
    }).afterClosed();
  }
}
