import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TimerService } from "../services/timer.service";

@Component({
  selector: "nx-delete-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"],
})
export class ConfirmDialogComponent {

  constructor(
    public timerService: TimerService,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
  ) {
  }

  public confirmAction() {
    this.dialogRef.close(true);
  }

  public denyAction() {
    this.dialogRef.close(false);
  }
}
