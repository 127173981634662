<ng-container *ngIf="(jiraService.isLoading$ | async) === false; else loadingTemplate">
  <nx-dialog [dialogData]="data">
    <nx-editable-durations-list
      class="dialog-content"
      [dialogData]="data"
      (durationsFormValid)="isDurationFormValid($event)"
    ></nx-editable-durations-list>
    <button [disabled]="!durationsFormValid" class="dialog-actions" mat-raised-button color="primary"
      (click)="saveChanges()">Speichern
    </button>
    <button class="dialog-actions" mat-raised-button color="warn" mat-dialog-close="true">Abbrechen</button>
  </nx-dialog>
</ng-container>

<ng-template #loadingTemplate>
  <mat-spinner></mat-spinner>
</ng-template>
