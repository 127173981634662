import { Component } from "@angular/core";
import { JiraService } from "../services/jira.service";

@Component({
  selector: "nx-critical-time",
  templateUrl: "./critical-time.component.html",
  styleUrls: ["./critical-time.component.scss"],
})
export class CriticalTimeComponent {
  constructor(public jiraService: JiraService) {
  }
}
