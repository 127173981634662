<div class="component-container">
  <mat-card appearance="outlined" *ngFor="let issue of jiraService.issues$ | async">
    <div class="progress-container">
      <mat-progress-bar class="in-time-bar" mode="determinate" value="{{ issue.fields.workratio }}"></mat-progress-bar>
      <div *ngIf="issue.fields.workratio > 100" class="progress-spacer"></div>
      <mat-progress-bar *ngIf="issue.fields.workratio > 100" class="over-time-bar" mode="determinate" value="100"
        color="warn"></mat-progress-bar>
    </div>
    <div class="timer">
      <div class="information-container">
        <div mat-card-avatar>
          <img [src]="issue.fields.issuetype.iconUrl" width="40" height="40"/>
        </div>
        <div class="issue">
          <div class="issue-keys">
            <div>
              <a href="{{ jiraService.jiraDomain }}browse/{{ issue.key }}" target="_blank">{{ issue.key }}</a>
            </div>
            <div class="parent-issue" *ngIf="issue.fields.parent">
              (<a href="{{ jiraService.jiraDomain }}browse/{{ issue.fields.parent.key }}"
              target="_blank">{{ issue.fields.parent.key }}</a>)
            </div>
          </div>
          <div>{{ issue.fields.summary }}</div>
        </div>
      </div>
      <div class="labels-container">
        <div *ngFor="let label of issue.fields.labels" class="label">{{ label }}&nbsp;</div>
      </div>
      <img *ngIf="issue.fields.assignee" mat-list-avatar [src]="issue.fields.assignee.avatarUrls['48x48']" alt="avatar"
        width="40" height="40"/>
      <div class="issue-time-container">
        <mat-icon class="timer-icon">timer</mat-icon>
        <div class="issue-time">
          <div>Geplant: {{ issue.fields.timeOriginalEstimateString }}</div>
          <div *ngIf="issue.fields.workratio <= 100">Verbleibend: {{ issue.fields.timeLeftString }}</div>
          <div *ngIf="issue.fields.workratio > 100">geschätzte Zeit überschritten!</div>
        </div>
      </div>
    </div>
  </mat-card>
</div>
