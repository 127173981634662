<form [formGroup]="addTimerForm" (window:keydown)="shortCutOpenCreateDialog($event)">
  <mat-form-field class="name">
    <input formControlName="name" type="text" matInput placeholder="Name" #name [matAutocomplete]="auto" tabindex="1">
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="setActivityType($event)">
      <mat-option *ngFor="let timer of timerService.filteredTimersForAutocomplete$ | async" [value]="timer.name">
        <span>{{ timer.name }} {{ timer.description }}</span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <div class="slide-toggle">
    <mat-slide-toggle
      color="primary"
      (change)="settingsService.saveSetting($event.checked ? 'startTime' : 'minusTime', 'timeInput', 'todayTimers'); clearTimeInputs()"
      [checked]="settingsService.settings$.value['todayTimers']['timeInput'] === 'startTime'"
    >
    </mat-slide-toggle>
  </div>

  <mat-form-field
    *ngIf="settingsService.settings$.value['todayTimers']['timeInput'] === 'startTime'"
    class="start-time"
    [floatLabel]="'never'"
  >
    <input
      formControlName="startTime"
      matInput
      placeholder="12:00"
      autocomplete="off"
      maxlength="5"
      [errorStateMatcher]="immediateMatcher"
      tabindex="2"
    >
    <mat-hint align="end">Optional: Timer in der Vergangenheit starten.</mat-hint>
    <mat-error *ngIf="addTimerForm.controls.startTime.hasError('timeFormatError')">Zeitformat: HH:MM</mat-error>
    <mat-error *ngIf="addTimerForm.controls.startTime.hasError('dateInFuture')">
      Startzeit des Timers darf nicht in der Zukunft liegen
    </mat-error>
  </mat-form-field>

  <mat-form-field
    class="minus-time"
    [floatLabel]="'never'"
    *ngIf="settingsService.settings$.value['todayTimers']['timeInput'] === 'minusTime'"
  >
    <input
      formControlName="minusTime"
      matInput
      placeholder="15m"
      [errorStateMatcher]="immediateMatcher"
      autocomplete="off"
      maxlength="6"
      tabindex="2"
    >
    <span matPrefix>-</span>
    <mat-hint align="end">Optional: Timer in der Vergangenheit starten.</mat-hint>
    <mat-error
      *ngIf="addTimerForm.controls.minusTime.hasError('durationFormatError') && addTimerForm.controls.minusTime.value"
    >
      Zeitformat: 1h30m/1h 30m/1h/1m
    </mat-error>
    <mat-error
      *ngIf="addTimerForm.controls.minusTime.hasError('durationInPast')"
    >
      Timer darf nicht in der Vergangenheit gestartet werden!
    </mat-error>
  </mat-form-field>

  <mat-form-field class="selectedActivityType">
    <mat-select
      placeholder="Typ"
      [disableOptionCentering]="true"
      formControlName="activityType"
      tabindex="3"
    >
      <mat-option *ngFor="let type of timerService.activityTypes" [value]="type">
        {{ type }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="play-button">
    <button
      [disabled]="!name.value || addTimerForm.invalid"
      mat-fab
      color="primary"
      (click)="createTimer(name)"
      tabindex="4"
    >
      <mat-icon>play_arrow</mat-icon>
    </button>
  </div>
</form>

<div class="timers-outer-container">
  <div class="timers-inner-container">
    <nx-timers-list [sortTimers]="true" [timers]="timerService.timers"></nx-timers-list>
  </div>
  <button mat-fab class="create-button" color="primary" (click)="openCreateDialog()">
    <mat-icon>add</mat-icon>
  </button>
</div>
