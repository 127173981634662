import { AbstractControl, ValidationErrors } from "@angular/forms";
import * as moment from "moment";
import { TimerService } from "./services/timer.service";

export class CustomValidators {
  public static durationFormatValidationPattern = /((\d+h)\ ?([0-5]?[0-9]m))|(^([1-9]\d+h|[1-9]h)$|^([1-9]\d+m|[1-9]m)$)/;
  public static startTimeFormatValidationPattern = /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/;

  public static durationsFormControlDateTimeValidator(control: AbstractControl): null | ValidationErrors {
    if (moment(control.value, "DD.MM.YYYY HH:mm", true).isValid() && control.value !== "24:00") {
      return null;
    }

    return { "controlFormatDateTimeError": true };
  }

  public static durationsFormControlTimeValidator(control: AbstractControl): null | ValidationErrors {
    if (moment(control.value, "HH:mm", true).isValid() && control.value !== "24:00") {
      return null;
    }

    return { "controlFormatTimeError": true };
  }

  public static validateDuration(control: AbstractControl): null | ValidationErrors {
    const durationFormatValidationPattern: RegExp = /((\d+h)\ ?([0-5]?[0-9]m))|(^([1-9]\d+h|[1-9]h)$|^([1-9]\d+m|[1-9]m)$)/;

    if (
      !control.value
      || durationFormatValidationPattern.test(control.value)
    ) {
      return null;
    }

    return { "durationFormatError": true };
  }

  public static validatePercent(control: AbstractControl): null | ValidationErrors {
    const percentFormatValidationPattern: RegExp = /(^\d{1,3}){1}$/;

    if (
      !control.value
      || percentFormatValidationPattern.test(control.value)
    ) {
      return null;
    }

    return { "durationFormatError": true };
  }

  public static validateName(control: AbstractControl): null | ValidationErrors {
    const allTimers = TimerService.getAllTimers();

    for (const timer of allTimers) {
      if (timer.name === control.value) {
        return { "nameFormatError": true };
      }
    }

    return null;
  }

  public static validateTime(control: AbstractControl): null | ValidationErrors {
    if (moment(control.value, "HH:mm", true).isValid() && control.value !== "24:00") {
      return null;
    }

    return { "timeFormatError": true };
  }
}
