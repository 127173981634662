import { FormControl, FormGroupDirective, NgForm } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";

export const IMMEDIATE_ERROR_STATE_MATCHER: ErrorStateMatcher = {
  isErrorState: (control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean => {
    const isSubmitted = form && form.submitted;

    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  },
};

export const PRISTINE_ERROR_STATE_MATCHER: ErrorStateMatcher = {
  isErrorState: (control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean => {

    return !!(control && control.invalid);
  },
};
