<table [dataSource]="timerData" mat-table>
  <ng-container matColumnDef="jiraSync">
    <th mat-header-cell *matHeaderCellDef>Sync mit Jira</th>
    <td mat-cell *matCellDef="let currentTimerData">
      <button
        mat-mini-fab
        color="warn"
        *ngIf="!currentTimerData.syncedWithJira && currentTimerData.syncedWithJira !== undefined && currentTimerData.syncedWithJira !== null"
        (click)="syncDurationWithJira(currentTimerData.startTime, currentTimerData.duration, currentTimerData.position, currentTimerData.activityType)"
      >
        <mat-icon
          matTooltip="Fehler bei Synchronisierung mit Jira"
        >
          sync_problem
        </mat-icon>
      </button>
      <mat-icon
        class="syncedWithJiraSucces"
        *ngIf="currentTimerData.syncedWithJira && currentTimerData.syncedWithJira !== undefined"
        matTooltip="Mit Jira synchronisiert"
      >
        sync
      </mat-icon>
      <mat-icon
        *ngIf="currentTimerData.savedLocal && currentTimerData.savedLocal !== undefined"
        matTooltip="Worklog ist lokal gespeichert"
      >
        computer
      </mat-icon>
    </td>
  </ng-container>

  <ng-container matColumnDef="position">
    <th mat-header-cell *matHeaderCellDef>No.</th>
    <td mat-cell *matCellDef="let currentTimerData">{{ currentTimerData.position }}</td>
  </ng-container>

  <ng-container matColumnDef="startTime">
    <th mat-header-cell *matHeaderCellDef>Startzeit</th>
    <td
      mat-cell
      *matCellDef="let currentTimerData"
    >
      {{ currentTimerData.startTime | date:'dd.MM.yy H:mm' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="endTime">
    <th mat-header-cell *matHeaderCellDef>Endzeit</th>
    <td mat-cell *matCellDef="let currentTimerData">{{ currentTimerData.endTime | date:'dd.MM.yy H:mm' }}</td>
  </ng-container>

  <ng-container matColumnDef="duration">
    <th mat-header-cell *matHeaderCellDef>Zeitdauer</th>
    <td mat-cell *matCellDef="let currentTimerData">{{ currentTimerData.duration }}</td>
  </ng-container>

  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef>Typ</th>
    <td mat-cell *matCellDef="let currentTimerData">
      <mat-select
        placeholder="Typ" [disableOptionCentering]="true"
        (selectionChange)="getTypeValue($event, currentTimerData)"
        [(value)]="currentTimerData.activityType"
      >
        <mat-option *ngFor="let type of timerService.activityTypes" [value]="type">
          {{ type }}
        </mat-option>
      </mat-select>
    </td>
  </ng-container>

  <ng-container matColumnDef="menu">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let currentTimerData">
      <div
        class="context-menu"
        [style.left]="contextMenuPosition.x"
        [style.top]="contextMenuPosition.y"
        [matMenuTriggerFor]="rootmenu"
        [matMenuTriggerData]="{ rowPosition: currentTimerData.position }"
      >
      </div>
      <button
        *ngIf="!readonly" mat-icon-button
        [matMenuTriggerFor]="rootmenu"
        (click)="$event.stopPropagation();"
        [matMenuTriggerData]="{ rowPosition: currentTimerData.position }"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" (contextmenu)="openContextMenu($event, row.position)">
  </tr>
</table>

<mat-menu #rootmenu="matMenu" [overlapTrigger]="false">
  <ng-template matMenuContent let-rowPosition="rowPosition">
    <button mat-menu-item (click)="openEditDialog(rowPosition)">
      <mat-icon>edit</mat-icon>
      Zeiten ändern
    </button>
    <button mat-menu-item (click)="openDeleteTimerConfirmDialog(rowPosition)">
      <mat-icon>delete_forever</mat-icon>
      Zeit löschen
    </button>
  </ng-template>
</mat-menu>
