import { EventEmitter, Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  public currentNotification: Notification;
  public notificationClicked: EventEmitter<string> = new EventEmitter<string>();
  public permissionGranted: boolean;

  constructor(public dialog: MatDialog) {
    if (Notification.permission === "granted") {
      this.permissionGranted = true;
    } else {
      (Notification as any).requestPermission().then((permission) => {
        this.permissionGranted = permission === "granted";
      });
    }
  }

  public sendNotification(notificationText: string, options?: NotificationOptions): void {
    if (this.currentNotification) {
      this.currentNotification.close();
    }

    if (this.permissionGranted) {
      this.currentNotification = new Notification(notificationText, options);

      this.currentNotification.onclick = () => {
        parent.focus();
        this.notificationClicked.emit(options.data["notificationName"]);
      };
    }
  }
}
