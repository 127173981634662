import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ConvertTimeService {
  public convertSecondsToString(estimatedTimeInSeconds: number, createAlarm: boolean): string {
    const workday: number = 6;  // Workday is 6 hours
    const workweek: number = 5;
    let convertedString: string = "";
    let min: number;
    let hour: number;
    let day: number;
    let week: number;

    if (estimatedTimeInSeconds < 0) {
      estimatedTimeInSeconds *= -1;
    }

    if (!createAlarm) {
      min = (estimatedTimeInSeconds / 60) % 60;
      hour = ((estimatedTimeInSeconds / 60) - min) / 60 % workday;
      day = (((((estimatedTimeInSeconds / 60) - min) / 60) - hour) / workday) % workweek;
      week = ((((((estimatedTimeInSeconds / 60) - min) / 60) - hour) / workday) - day) / workweek;
    } else {
      min = (estimatedTimeInSeconds / 60) % 60;
      hour = ((estimatedTimeInSeconds / 60) - min) / 60;
    }

    if (week > 0) {
      convertedString += week + "w ";
    }

    if (day > 0) {
      convertedString += day + "d ";
    }

    if (hour > 0) {
      convertedString += hour + "h ";
    }

    if (min > 0) {
      convertedString += min + "m ";
    }

    if (convertedString === "") {
      convertedString = "Nicht geplant!";
    }

    return convertedString.trim();
  }

  public calculateTimeLeft(estimatedTimeInSeconds: number, timeLeftInSeconds: number, spentTimeInSeconds: number): string {
    return this.convertSecondsToString(estimatedTimeInSeconds - (timeLeftInSeconds > 0 ? estimatedTimeInSeconds - timeLeftInSeconds : spentTimeInSeconds), false);
  }
}
