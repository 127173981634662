import { Component, EventEmitter, OnDestroy, OnInit } from "@angular/core";
import * as moment from "moment";
import { unitOfTime } from "moment";
import { EditableDurationsListService } from "../editable-durations-list/editable-durations-list.service";
import { TimerService } from "../services/timer.service";
import { ITimer } from "../timer/timer.interface";
import { LocalStorageService } from "../services/local-storage.service";
import { takeUntil, tap } from "rxjs/operators";

@Component({
  selector: "nx-reports",
  templateUrl: "./reports.component.html",
  styleUrls: ["./reports.component.scss"],
})
export class ReportsComponent implements OnInit, OnDestroy {
  public currentDate = moment();
  public datePickerValueFrom = null;
  public datePickerValueTo = null;
  public timers: ITimer[] = [];
  public unitOfTime;
  private _destroyEmitter: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private _timerService: TimerService,
    private _editableDurationsListService: EditableDurationsListService,
    private _localStorageService: LocalStorageService,
  ) {
    this._localStorageService.localStorageChange$
      .pipe(
        takeUntil(this._destroyEmitter),
        tap(() => this.search(this.unitOfTime)),
      )
      .subscribe();
  }

  public setDateFrom(value): void {
    this._editableDurationsListService.dateFrom = value;
    this.datePickerValueFrom = value;
  }

  public setDateTo(value): void {
    this._editableDurationsListService.dateTo = value;
    this.datePickerValueTo = value;
  }

  ngOnInit(): void {
    this._timerService.getTimersByDateFromTo(moment());
  }

  public search(unit?: unitOfTime.StartOf): void {
    this.unitOfTime = unit;

    if (this.unitOfTime) {
      this.currentDate = moment();
      this.datePickerValueFrom = moment(this.currentDate.startOf(this.unitOfTime));
      this.datePickerValueTo = moment(this.currentDate.endOf(this.unitOfTime));
      this._editableDurationsListService.dateFrom = this.datePickerValueFrom;
      this._editableDurationsListService.dateTo = this.datePickerValueTo;
    }
    this.timers = this._timerService.getTimersByDateFromTo(this.datePickerValueFrom, this.datePickerValueTo);
  }

  ngOnDestroy(): void {
    this._destroyEmitter.next();
  }
}
