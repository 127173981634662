import {inject} from "@angular/core";
import {CanActivateFn, RouterStateSnapshot, UrlTree} from "@angular/router";
import { Observable } from "rxjs";
import {map} from "rxjs/operators";
import { OAuthService } from "../services/oauth.service";

export function jiraAuthorizationGuard(): CanActivateFn {
  return (route, state: RouterStateSnapshot): Observable<boolean | UrlTree> => {
    const oAuthService = inject(OAuthService);
    return oAuthService.exchangeAccessCode(route.queryParams.code).pipe(map(() => false));
  }
}
