<mat-expansion-panel [hideToggle]="true" #expansionPanel [disabled]="expansionPanelDisabled">
  <mat-expansion-panel-header
    collapsedHeight="75px"
    class="timer-expansion-panel"
    (contextmenu)="openContextMenu($event)"
  >
    <div
      class="context-menu"
      [style.left]="contextMenuPosition.x"
      [style.top]="contextMenuPosition.y"
      [matMenuTriggerFor]="rootmenu"
    >
    </div>
    <mat-card appearance="outlined">
      <mat-card-header>
        <div
          mat-card-avatar
          *ngIf="timer.syncedWithJira && !pinned && !autoStopped"
          class="synced-button-container"
        >
          <button
            mat-mini-fab
            color="primary"
            [disableRipple]="true"
          >
            <mat-icon matTooltip="Synchronisiert mit Jira">sync</mat-icon>
          </button>
        </div>
        <div
          mat-card-avatar
          *ngIf="!timer.syncedWithJira && timer.syncedWithJira !== undefined && timer.syncedWithJira !== null && !pinned && !autoStopped"
          class="synced-error-button-container"
        >
          <button
            mat-mini-fab
            color="warn"
            (click)="syncTimerWithJira(); $event.stopPropagation();"
          >
            <mat-icon matTooltip="Erneut synchronisieren">sync_problem</mat-icon>
          </button>
        </div>
        <mat-card-title [matTooltip]="!timer.description ? timer.name : null">
          <a
            [attr.href]="environment.jiraDomain + '/browse/' + timer.name"
            (click)="$event.stopPropagation()"
            style="color: white; text-decoration: none;"
          >
            {{ timer.name }}
          </a>
        </mat-card-title>
        <mat-card-subtitle *ngIf="timer.description" [matTooltip]="timer.description">
          {{ timer.description }}
        </mat-card-subtitle>
      </mat-card-header>

      <mat-card-content *ngIf="!pinned && !autoStopped">
        <div *ngIf="timer.alarm" class="alarm-duration">
          <mat-icon matTooltip="Alarm">alarm</mat-icon>
          {{ alarmDuration$ | async }}
        </div>
        <div class="timer-duration">
          <mat-icon matTooltip="Timer Laufzeit">timer</mat-icon>
          {{ timerDuration | async }}
        </div>
      </mat-card-content>

      <div class="timer-buttons">
        <button
          *ngIf="!readonly && !autoStopped && !showLoadingSpinner"
          mat-mini-fab
          [color]="timerService.isActiveTimer(timer) ? 'accent' : 'primary'"
          class="play-button"
          (click)="playPauseTimer(); $event.stopPropagation();"
        >
          <mat-icon *ngIf="timerService.isActiveTimer(timer)">pause</mat-icon>
          <mat-icon *ngIf="!timerService.isActiveTimer(timer)">play_arrow</mat-icon>
        </button>

        <mat-progress-spinner
          color="primary"
          *ngIf="showLoadingSpinner"
          diameter="50"
          mode="determinate"
          [value]="syncProgress"
        ></mat-progress-spinner>

        <button *ngIf="!readonly && !pinned" mat-icon-button [matMenuTriggerFor]="rootmenu"
          (click)="$event.stopPropagation();">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #rootmenu="matMenu" [overlapTrigger]="false">
          <button *ngIf="!autoStopped" mat-menu-item [matMenuTriggerFor]="alarmMenu">
            <mat-icon>alarm</mat-icon>
            Alarm
          </button>
          <button *ngIf="!autoStopped" mat-menu-item [matMenuTriggerFor]="syncMenu">
            <mat-icon>sync</mat-icon>
            Mit Jira synchronisieren
          </button>
          <button *ngIf="!timerService.isActiveTimer(timer)" mat-menu-item (click)="openEditDialog()">
            <mat-icon>edit</mat-icon>
            Zeiten ändern
          </button>
          <button
            *ngIf="(timer.syncedWithJira === undefined || timer.syncedWithJira === null) && !autoStopped"
            mat-menu-item
            (click)="openEditNameDialog()"
          >
            <mat-icon>title</mat-icon>
            Umbenennen
          </button>
          <button *ngIf="!autoStopped" mat-menu-item (click)="openAddDurationDialog()">
            <mat-icon>add_circle_outline</mat-icon>
            Zeit hinzufügen
          </button>
          <button
            *ngIf="(timer.syncedWithJira === undefined || timer.syncedWithJira === null) && !autoStopped"
            mat-menu-item
            (click)="openDeleteTimerConfirmDialog()"
          >
            <mat-icon>delete_forever</mat-icon>
            Löschen
          </button>
        </mat-menu>
        <mat-menu #alarmMenu="matMenu">
          <button mat-menu-item (click)="openAlarmDialog()" *ngIf="!timer.alarm">
            <mat-icon>alarm_add</mat-icon>
            Alarm hinzufügen
          </button>
          <button mat-menu-item *ngIf="timer.alarm" (click)="openEditAlarmDialog()">
            <mat-icon>edit</mat-icon>
            Alarm bearbeiten
          </button>
          <button mat-menu-item (click)="openDeleteAlarmConfirmDialog()" *ngIf="timer.alarm">
            <mat-icon>alarm_off</mat-icon>
            Alarm löschen
          </button>
        </mat-menu>
        <mat-menu #syncMenu="matMenu">
          <button mat-menu-item (click)="syncTimerWithJira()">
            <mat-icon>timer</mat-icon>
            Timer
          </button>
          <button mat-menu-item (click)="syncFailedDurationsWithJira()">
            <mat-icon>sync_problem</mat-icon>
            Fehlgeschlagene Zeiten
          </button>
          <button mat-menu-item (click)="syncTimerDurationsFromDate()">
            <mat-icon>update</mat-icon>
            Sichtbare Zeiten
          </button>
          <button mat-menu-item (click)="timerService.descriptionOverwriteEmitter.next(timer.name)">
            <mat-icon>description</mat-icon>
            Aufgabenbeschreibung
          </button>
        </mat-menu>
        <button *ngIf="!autoStopped" mat-icon-button>
          <mat-icon *ngIf="!timer.pinned" (click)="timerService.pinTimer(timer); $event.stopPropagation()">
            bookmark_border
          </mat-icon>
          <mat-icon *ngIf="timer.pinned" (click)="timerService.unpinTimer(timer); $event.stopPropagation()">bookmark
          </mat-icon>
        </button>
      </div>
    </mat-card>
    <div *ngIf="!pinned && !autoStopped" class="arrow-down-panel"
      [style.visibility]="!expansionPanel.expanded ? 'visible' : 'hidden'">
      <mat-icon>expand_more</mat-icon>
    </div>
  </mat-expansion-panel-header>
  <ng-template matExpansionPanelContent>
    <div>
      <mat-tab-group animationDuration="0ms" mat-stretch-tabs>
        <mat-tab label="Durations">
          <ng-template mat-tab-label>
            <mat-icon>class</mat-icon>
          </ng-template>
          <nx-durations-list
            *ngIf="!pinned"
            [readonly]="readonly"
            [timer]="timer"
          ></nx-durations-list>
        </mat-tab>
        <mat-tab label="Task">
          <ng-template mat-tab-label>
            <mat-icon>task_alt</mat-icon>
          </ng-template>
          <nx-task [timer]="timer" [shouldShowTask]="true"></nx-task>
        </mat-tab>
      </mat-tab-group>
    </div>

  </ng-template>
  <div class="arrow-up-panel" *ngIf="expansionPanel.expanded && !pinned && !autoStopped">
    <mat-icon (click)="expansionPanel.close()">expand_less</mat-icon>
  </div>
</mat-expansion-panel>
