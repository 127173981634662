import { Component, Inject } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CustomValidators } from "../custom-validators";
import { IMMEDIATE_ERROR_STATE_MATCHER } from "../immediate-error-state-matcher";
import { TimerService } from "../services/timer.service";
import { ITimer } from "../timer/timer.interface";
import { JiraService } from "../services/jira.service";
import { take, tap } from "rxjs/operators";
import { ConvertTimeService } from "../services/convert-time.service";
import { IJiraIssue } from "../jira-issue.interface";

interface IAlarmForm {
  alarmDuration: FormControl<string>;
}

@Component({
  selector: "nx-create-alarm-dialog",
  templateUrl: "./create-alarm-dialog.component.html",
  styleUrls: ["./create-alarm-dialog.component.scss"],
})
export class CreateAlarmDialogComponent {
  public alarmForm: FormGroup<IAlarmForm>;
  public immediateMatcher = IMMEDIATE_ERROR_STATE_MATCHER;
  public timer: ITimer;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<CreateAlarmDialogComponent>,
    public formBuilder: FormBuilder,
    public timerService: TimerService,
    private _jiraService: JiraService,
    private _convertTimerService: ConvertTimeService,
  ) {
    this.timer = this.data["timer"];

    this.alarmForm = this.formBuilder.nonNullable.group({
      "alarmDuration": [
        "",
        [Validators.required, CustomValidators.validateDuration],
      ],
    });
  }

  public setAlarm(duration: string, style: string) {
    if (style === "Zeit") {
      this.timerService.setAlarm(duration, this.timer);
    } else {
      this._jiraService.getIssue(this.timer.name).pipe(
        take(1),
        tap((issue: IJiraIssue) => {
          this.timerService.setAlarm(this._convertTimerService.convertSecondsToString(issue.fields.aggregatetimeestimate / 100 * parseInt(duration, 10), true), this.timer);
        }),
      ).subscribe();
    }

    this.dialogRef.close();
  }

  public changeValidator(type: string) {
    if (type === "Zeit") {
      this.alarmForm = this.formBuilder.group({
        "alarmDuration": [
          "",
          [Validators.required, CustomValidators.validateDuration],
        ],
      });
    } else {
      this.alarmForm = this.formBuilder.group({
        "alarmDuration": [
          "",
          [Validators.required, CustomValidators.validatePercent, Validators.max(100)],
        ],
      });
    }
  }
}
