import { Component, EventEmitter, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { EditDurationsService } from "../services/edit-durations.service";
import { TimerService } from "../services/timer.service";
import { finalize, takeUntil } from "rxjs/operators";
import { JiraService } from "../services/jira.service";

@Component({
  selector: "nx-edit-timer-single-duration-dialog",
  templateUrl: "./edit-timer-single-duration-dialog.component.html",
  styleUrls: ["./edit-timer-single-duration-dialog.component.scss"],
})
export class EditTimerSingleDurationDialogComponent implements OnInit, OnDestroy {
  public durationsFormValid: boolean;
  public position: number;
  private _destroyEmitter = new EventEmitter<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public timerService: TimerService,
    public dialogRef: MatDialogRef<EditTimerSingleDurationDialogComponent>,
    public editDurationsService: EditDurationsService,
    public jiraService: JiraService,
  ) {
  }

  public isDurationFormValid(event): void {
    this.durationsFormValid = event;
  }

  ngOnInit(): void {
    this.position = this.data["position"];
  }

  public saveChanges(): void {
    this.jiraService.isLoading$.next(true);
    this.editDurationsService.saveChanges()
      .pipe(
        takeUntil(this._destroyEmitter),
        finalize(() => {
          this.dialogRef.close();
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.jiraService.isLoading$.next(false);
    this._destroyEmitter.next();
  }
}
