import { Component, Input } from "@angular/core";

@Component({
  selector: "nx-message",
  templateUrl: "./message.component.html",
  styleUrls: ["./message.component.scss"],
})
export class MessageComponent {
  @Input() public type: string = null;

  constructor() {
  }

}
