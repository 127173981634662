import { Injectable } from "@angular/core";
import * as moment from "moment";
import { Moment } from "moment";
import { Observable } from "rxjs";
import { finalize, takeWhile } from "rxjs/operators";
import { IEditableDurationsListChange } from "../editable-durations-list/editable-durations-list.component";
import { EditableDurationsListService } from "../editable-durations-list/editable-durations-list.service";
import { LocalStorageService } from "./local-storage.service";
import { TimerService } from "./timer.service";
import { ITimer } from "../timer/timer.interface";

@Injectable({
  providedIn: "root",
})
export class EditDurationsService {
  public dateFrom: Moment;
  public durationsChanges = [];
  public format: string;
  public timer: ITimer;

  constructor(
    public timerService: TimerService,
    public editableDurationsListService: EditableDurationsListService,
    private _localStorageService: LocalStorageService,
  ) {
  }

  public prepareDurationsChanges(changes: IEditableDurationsListChange[], format: string) {
    this.format = format;

    if (this.dateFrom) {
      Object.keys(changes).forEach((index) => {
        const starTimeValue = moment(changes[index]["startTime"], format);
        const startTime = {
          hour: starTimeValue.get("hour"),
          minute: starTimeValue.get("minute"),
          second: starTimeValue.get("second"),
        };

        const endTimeValue = moment(changes[index]["endTime"], format);
        const endTime = {
          hour: endTimeValue.get("hour"),
          minute: endTimeValue.get("minute"),
          second: endTimeValue.get("second"),
        };

        this.durationsChanges[index] = {
          startTime: moment(this.dateFrom).set(startTime),
          endTime: changes[index]["endTime"] ? moment(this.dateFrom).set(endTime) : undefined,
          activityType: changes[index].activityType,
        };
      });
    } else {
      this.durationsChanges = Object.values(changes);
    }
  }

  public saveChanges(): Observable<void> {
    const autoStoppedTimer: ITimer = LocalStorageService.getItem("autoStoppedTimer");

    if (autoStoppedTimer && this.timer.name === autoStoppedTimer.name) {
      this._localStorageService.removeItem("autoStoppedTimer");
    }

    return this.timerService.saveTimerDurations(
      this.timer,
      this.durationsChanges,
      this.editableDurationsListService.dateFrom,
      this.format,
    )
      .pipe(
        takeWhile(() => this.durationsChanges.length > 0),
        finalize(() => this.durationsChanges = []),
      );
  }
}
