<div *ngFor="let descriptionLine of taskDescription$ | async" class="task-container">

  <div class="task-checkbox-container">
    <div *ngIf="descriptionLine.isTask && shouldShowTask">
      <mat-icon class="task-checkbox" [svgIcon]="descriptionLine.devTeam.frontend"
        (click)="updateTaskStatus(descriptionLine.devTeam.frontend, descriptionLine, 'frontend')"></mat-icon>
      <mat-icon class="task-checkbox" [svgIcon]="descriptionLine.devTeam.backend"
        (click)="updateTaskStatus(descriptionLine.devTeam.backend, descriptionLine, 'backend')"></mat-icon>
    </div>
  </div>

  <div *ngFor="let descriptionCell of descriptionLine.descriptionLine" class="description-block">
    {{!descriptionCell.isIcon ? descriptionCell.descriptionCell : ''}}
    <mat-icon *ngIf="descriptionCell.isIcon" class="customIconSize"
      svgIcon="{{descriptionCell.descriptionCell}}"></mat-icon>
  </div>

</div>
<div *ngIf="shouldShowTask && taskDescription$.value.length !== 0" class="button-container">
  <div class="date-container">
    <div *ngIf="timer.issueTaskFetchDate && fetchDate$.value">Letzte Datenaktualisierung {{ fetchDate$ | async }}</div>
  </div>
  <div>
    <button mat-raised-button color="primary" (click)="saveChanges()">Speichern</button>
    <button mat-raised-button (click)="timerService.descriptionOverwriteEmitter.next(timer.name)">Abbrechen</button>
  </div>
</div>
