import { Component, EventEmitter, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { Moment } from "moment";
import { filter, takeUntil, tap } from "rxjs/operators";
import { AddDurationDialogComponent } from "../add-duration-dialog/add-duration-dialog.component";
import { Format } from "../editable-durations-list/editable-durations-list.component";
import { EditableDurationsListService } from "../editable-durations-list/editable-durations-list.service";
import { LocalStorageService } from "../services/local-storage.service";
import { TimerService } from "../services/timer.service";
import { ITimer } from "../timer/timer.interface";

interface INameForm {
  name: FormControl<string>;
}

@Component({
  selector: "nx-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit, OnDestroy {
  public datepickerValue: Moment;
  public maxDate: Moment;
  public nameForm: FormGroup<INameForm>;
  public previousTimers: ITimer[] = [];
  public tabIndex: number = 0;
  public timersByName: ITimer[] = [];
  private _destroyEmitter = new EventEmitter<void>();

  constructor(
    public localStorageService: LocalStorageService,
    public timerService: TimerService,
    public formBuilder: FormBuilder,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    public editableDurationsListService: EditableDurationsListService,
    private _dialog: MatDialog,
  ) {
    this.nameForm = formBuilder.group(
      {
        "name": [""],
      },
    );

    this.maxDate = moment.utc().subtract(1, "days");

    this.localStorageService.localStorageChange$
      .pipe(
        takeUntil(this._destroyEmitter),
        tap(() => {
          if (this.tabIndex === 1) {
            this.setPreviousTimers(this.datepickerValue);
          } else if (this.tabIndex === 0) {
            this.searchTimer(this.nameForm.controls.name.value);
          }
        }),
      )
      .subscribe();
  }

  public changeTabIndex(tabIndex): void {
    this.tabIndex = tabIndex;
    this.editableDurationsListService.dateTo = undefined;

    if (tabIndex === 1) {
      this.editableDurationsListService.format = Format.DATETIME;
    } else if (tabIndex === 0) {
      this.editableDurationsListService.format = Format.DATETIME;
      this.editableDurationsListService.dateFrom = undefined;
    }
  }

  public datepickerChange(date: Moment): void {
    this.datepickerValue = date;

    this.editableDurationsListService.dateFrom = this.datepickerValue;

    this.setPreviousTimers(date);
  }

  ngOnInit(): void {
    this.editableDurationsListService.format = Format.DATETIME;
    this.editableDurationsListService.dateFrom = undefined;
    this.editableDurationsListService.dateTo = undefined;

    this._activatedRoute.paramMap
      .pipe(
        takeUntil(this._destroyEmitter),
        filter((params) => params.has("query")),
        tap((params) => {
          this.searchTimer(params.get("query"));
          this.nameForm.controls.name.setValue(params.get("query"));
        }),
      )
      .subscribe();
  }

  public openAddDurationDialog(): void {
    this._dialog.open(AddDurationDialogComponent, {
      data: {
        title: "Arbeitszeit buchen",
        showNameInput: true,
      },
    });
  }

  public searchNavigation(name: string): void {
    this._router.navigate(["search", name]);
  }

  public searchTimer(name: string): void {
    if (name.length > 0) {
      this.timersByName = this.timerService.searchTimersByName(name);
    }
  }

  public setPreviousTimers(date: Moment): void {
    this.previousTimers = this.timerService.getTimersByDateFromTo(date);
  }

  ngOnDestroy(): void {
    this._destroyEmitter.next();
  }
}
